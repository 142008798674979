import styled from "styled-components"
import introImage from '../../../assets/images/about/intro.webp'
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const IntroImage = styled.div `
    
    width: 486px;
    height: 397px;
    display: grid;
    background-image: url(${introImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        width: 85vw;
        height: 397px;
        margin-top: 30px;
        
    }

`