import styled from "styled-components"

export const LabelField = styled.div `

    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;

`