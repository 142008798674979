import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const MethodTitle = styled.div `
    
    width: 100%;
    margin: 40px 0 0 0;
    color: ${({theme}) => theme.colors.DARK_TEXT};
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.HOME_TITLE1_SIZE};
    font-weight: bolder;
    text-align: justify;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        height: fit-content;
        width: 100%;
        font-size: ${fontValues.HOME_TITLE2_SIZE};
    }

`