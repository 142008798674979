import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const Container = styled.div `

    width: 80%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-self: center;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        width: 100%;
        grid-template-columns: none;
        grid-template-rows: repeat(2, minmax(calc(100vh - 80px), 1fr));
        overflow: auto;
        
    }

`