import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const Container = styled.section`

    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: ${({theme}) => theme.colors.LIGHT_BACKGROUND};

    @media(max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
      overflow-y: scroll;
    }

`