import styled from "styled-components"

export const TextContainer = styled.div`

    width: 80%;
    max-width: 800px;
    height: 80%;
    margin: 50px 0 70px 0;
    justify-self: center;
    display: flex;
    flex-flow: column;

`