import styled from "styled-components"

export const Icon = styled.img `
    
    width: 35px;
    height: 35px;
    border: none;
    justify-self: center;
    align-self: center;

`