import {Fragment} from "react"
import pricingStrings from "../../assets/strings/pricingStrings.json"
import socialLinks from "../../utils/constants/shared/socialLinks.json"
import usePricing from "./usePricing"

import {PricingStyles} from "../../styles/pages/pricing"

const Pricing = () => {

    const {
        isOpen,
        handleOfferClick
    } = usePricing()

    return(

        <Fragment>

            <PricingStyles.Container>

                <PricingStyles.Title>{pricingStrings.TITLE}</PricingStyles.Title>

                <PricingStyles.Body>

                    <PricingStyles.Option>

                        <PricingStyles.OptionText>{pricingStrings.O1_TEXT}</PricingStyles.OptionText>

                        <PricingStyles.OptionPrice>{pricingStrings.O1_PRICE}</PricingStyles.OptionPrice>

                    </PricingStyles.Option>

                    <PricingStyles.Option onClick={handleOfferClick}>

                        <PricingStyles.Discount>{pricingStrings.OFF}</PricingStyles.Discount>

                        <PricingStyles.OptionText>{pricingStrings.O2_TEXT}</PricingStyles.OptionText>

                        <PricingStyles.OptionPrice>{pricingStrings.O2_PRICE}</PricingStyles.OptionPrice>

                    </PricingStyles.Option>

                    <PricingStyles.PricesTable $isOpen={isOpen}>

                        <PricingStyles.PricesTableText>
                            {pricingStrings.PRICES_TABLE_TEXT1}
                        </PricingStyles.PricesTableText>

                        <PricingStyles.PricesTableText>
                            {pricingStrings.PRICES_TABLE_TEXT2}
                        </PricingStyles.PricesTableText>

                        <PricingStyles.PricesTableText>
                            {pricingStrings.PRICES_TABLE_TEXT3}
                        </PricingStyles.PricesTableText>

                        <PricingStyles.PricesTableText>
                            {pricingStrings.PRICES_TABLE_TEXT4}
                        </PricingStyles.PricesTableText>

                        <PricingStyles.PricesTableText>
                            {pricingStrings.PRICES_TABLE_TEXT5}
                        </PricingStyles.PricesTableText>

                        <PricingStyles.PricesTableText>
                            {pricingStrings.PRICES_TABLE_TEXT6}
                        </PricingStyles.PricesTableText>

                        <PricingStyles.PricesTableTotal>
                            {pricingStrings.PRICES_TABLE_TEXT7}
                        </PricingStyles.PricesTableTotal>

                        <PricingStyles.PricesTableTotal>
                            {pricingStrings.PRICES_TABLE_TEXT8}
                        </PricingStyles.PricesTableTotal>

                        <PricingStyles.PricesTableTotal>
                            {pricingStrings.PRICES_TABLE_TEXT9}
                        </PricingStyles.PricesTableTotal>

                    </PricingStyles.PricesTable>

                    <a href={socialLinks.WHATS_APP_SALES} target="_blank">

                        <PricingStyles.Button>
                            {pricingStrings.BUTTON_TEXT}
                        </PricingStyles.Button>

                    </a>

                </PricingStyles.Body>

            </PricingStyles.Container>

        </Fragment>

    )

}

export default Pricing