import {Fragment} from "react"
import {PrivacyStyles} from "../../styles/pages/privacy"
import cookiesStrings from "../../assets/strings/cookiesStrings.json"

const Cookies = () => {

    return (

        <Fragment>

            <PrivacyStyles.Container>

                <PrivacyStyles.TextContainer>

                    <PrivacyStyles.Title>
                        {cookiesStrings.TITLE}
                    </PrivacyStyles.Title>

                    <PrivacyStyles.Text>
                        {cookiesStrings.P1}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>
                        {cookiesStrings.SUBTITLE1}
                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {cookiesStrings.P2}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>
                        {cookiesStrings.SUBTITLE2}
                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {cookiesStrings.P3}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Text>
                        {cookiesStrings.P4}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Text>
                        {cookiesStrings.P5}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Text>
                        {cookiesStrings.P6}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>
                        {cookiesStrings.SUBTITLE3}
                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {cookiesStrings.P7}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>
                        {cookiesStrings.SUBTITLE4}
                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {cookiesStrings.P8}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>
                        {cookiesStrings.SUBTITLE5}
                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {cookiesStrings.P9}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>

                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {cookiesStrings.P10}
                    </PrivacyStyles.Text>

                </PrivacyStyles.TextContainer>

            </PrivacyStyles.Container>

        </Fragment>

    )

}

export default Cookies