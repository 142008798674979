import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const ValuesContainer = styled.div `

    width: 100%;
    max-width: 1100px;
    height: max-content;
    display: grid;
    justify-self: center;
    justify-items: center;
    margin-top: 50px;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        
        
    }

`