import {Container} from "./Container"
import {Card} from "./Card"
import {FieldsContainer} from "./FieldsContainer"
import {Button} from "./Button"
import {CloseButton} from "./CloseButton"
import {FieldLabel} from "./FieldLabel"
import {FieldContainer} from "./FieldContainer"
import {FieldData} from "./FieldData"
import {Text} from "./Text"
import {ConfirmButtonsContainer} from "./ConfirmButtonsContainer"

export const UserDetailsStyles = {
    Container,
    Card,
    FieldsContainer,
    Button,
    CloseButton,
    FieldLabel,
    FieldContainer,
    FieldData,
    Text,
    ConfirmButtonsContainer
}