import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const MindsetContainer = styled.div `

    width: 100%;
    max-width: 1100px;
    height: max-content;
    margin: 40px 0;
    display: grid;
    justify-self: center;
    justify-items: center;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        max-width: 100%;
        
    }

`