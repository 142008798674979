import styled from "styled-components"
import fontValues from '../../../assets/values/fontValues/fontValues.json'

export const Text = styled.p `

  margin: 0;
  width: 90%;
  text-align: center;
  font-family: ${fontValues.TEXT_FONT_FAMILY};
  font-size: ${fontValues.TEXT_SIZE};
  color: ${({theme}) => theme.colors.DARK_TEXT};

`