import { useState, useEffect } from 'react'

const useVisibility = (ref, options) => {

    const [hasBeenVisible, setHasBeenVisible] = useState(false);

    useEffect(() => {

        const observer = new IntersectionObserver(([entry]) => {

            if (entry.isIntersecting && !hasBeenVisible) {
                setHasBeenVisible(true)
                observer.unobserve(ref.current)
            }

        }, options)

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current)
            }
        }

    }, [ref, options, hasBeenVisible])

    return hasBeenVisible

}

export default useVisibility