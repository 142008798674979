import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const MethodContentContainer = styled.div `

    width: 90%;
    max-width: 1300px;
    height: calc(100vh - 260px);
    display: grid;
    grid-template-columns: calc(50% - 40px) 80px calc(50% - 40px);
    justify-self: center;
    align-self: center;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        max-width: 100%;
        height: max-content;
        display: flex;
        flex-flow: column;
        
    }

`