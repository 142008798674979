import {useContext, useState} from "react"
import jsSHA from "jssha"
import jwt_decode from "jwt-decode"
import fieldNames from "../../utils/constants/components/loginForm/fieldNames.json"
import errorStates from "../../utils/constants/shared/errorStates.json"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import loginFormStrings from "../../assets/strings/loginFormStrings.json"
import cookieNames from "../../utils/constants/shared/cookieNames.json"
import routes from "../../utils/constants/shared/routes.json"
import {ModalProviderContext} from "../../providers/router/ModalProvider"

const useLoginForm = () => {

    const initialValues = {
        [fieldNames.EMAIL]: '',
        [fieldNames.PASSWORD]: ''
    }

    const initialErrors = {
        [fieldNames.EMAIL]: errorStates.INITIALIZED,
        [fieldNames.PASSWORD]: errorStates.INITIALIZED
    }

    const[values, setValues] = useState(initialValues)
    const[errors, setErrors] = useState(initialErrors)
    const { buildModal, buildLoadingModal, setModal } = useContext(ModalProviderContext)

    const handleLogin = () => {

        if(validate()){

            buildLoadingModal()

            const sha = new jsSHA("SHA-512", "TEXT", { encoding: "UTF8" })
            sha.update(values[fieldNames.PASSWORD])

            const cpyValues = values
            cpyValues[fieldNames.PASSWORD] = sha.getHash("HEX")

            const controller = new AbortController()
            setTimeout(() => controller.abort(), 5000)

            fetch(process.env.REACT_APP_API_URL + apiUrls.LOGIN_ROUTE, {

                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(cpyValues),
                signal: controller.signal

            }).then(response => {

                switch (response.status) {

                    case 500:
                        buildModal(loginFormStrings.MODAL_SERVER_ERROR, loginFormStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 404:
                        buildModal(loginFormStrings.MODAL_NOT_FOUND, loginFormStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 401:
                        buildModal(loginFormStrings.MODAL_UNAUTHORIZED, loginFormStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 200:

                        logged(response)
                        break

                    default:
                        buildModal(loginFormStrings.MODAL_UNKNOWN_ERROR, loginFormStrings.MODAL_CLOSE_BUTTON, [])
                        break

                }

            }).catch(() => {

                buildModal(loginFormStrings.MODAL_FETCH_ERROR, loginFormStrings.MODAL_CLOSE_BUTTON, [])

            })

        }else{

            buildModal(loginFormStrings.MODAL_FIELD_ERROR, loginFormStrings.MODAL_CLOSE_BUTTON, [])

        }

    }

    const logged = (response) => {

        response.json()
        .then(data => {

            const token = data.token
            localStorage.setItem(cookieNames.JWT_COOKIE, token)
            const jwtDecoded = jwt_decode(token)
            const host = window.location.protocol + "//" + window.location.host

            setModal(false)

            if(jwtDecoded.manager){

                window.location.replace(host + '/#' + routes.MANAGER)

            }else if(jwtDecoded.sales){

                window.location.replace(host + '/#' + routes.SALES_PANEL)

            }else{

                window.location.replace(host + '/#' + routes.MY_LEARNING_CENTER)

            }

        })
        .catch(() => {

            buildModal(loginFormStrings.MODAL_UNKNOWN_ERROR, loginFormStrings.MODAL_CLOSE_BUTTON, [])

        })

    }

    const validate = () => {

        const errorsCopy = {}
        let validated = true

        for (const element in errors) {

            if(errors[element] !== errorStates.NO_ERROR){

                errorsCopy[element] = errorStates.WITH_ERROR
                validated = false

            }

        }

        setErrors(errorsCopy)
        return validated

    }

    const handleBlur = (e) => {

        const {name, value} = e.target

        if(value.trim()){

            setErrors({
                ...errors,
                [name]: errorStates.NO_ERROR
            })

        }else{

            setErrors({
                ...errors,
                [name]: errorStates.WITH_ERROR
            })

        }

    }

    const handleChange = (e) => {

        const {name, value} = e.target

        setValues({
            ...values,
            [name]: value.trim()
        })

        handleBlur(e)

    }

    return{
        errors,
        handleChange,
        handleLogin
    }

}

export default useLoginForm