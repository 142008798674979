import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const Container = styled.div `

    width: 100%;
    height: 300px;
    display: grid;
    grid-template-rows: 70% 30%;
    justify-items: center;
    align-items: center;
    background-color: ${({theme}) => theme.colors.HOME_HOVER};

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    
        height: 600px;
        grid-template: none;
        padding-top: 40px;
        grid-row-gap: 10px;
    
    }

`