import {Fragment} from "react"
import useManageNotifications from "./useManageNotifications"
import manageNotificationsStrings from "../../assets/strings/manageNotificationsStrings.json"

import {ManageNotificationsStyles} from "../../styles/components/manageNotifications"

const ManageNotifications = () => {

    const {
        notificationCards,
        handleChange,
        createNotifications
    } = useManageNotifications()

    return(

        <Fragment>

            <ManageNotificationsStyles.Container>

                <ManageNotificationsStyles.HeaderContainer>

                    <ManageNotificationsStyles.TextArea
                    placeholder={manageNotificationsStrings.CREATE_PLACE_HOLDER} onChange={handleChange}/>

                    <ManageNotificationsStyles.CreateButton onClick={createNotifications}>
                        {manageNotificationsStrings.CREATE_BUTTON}
                    </ManageNotificationsStyles.CreateButton>

                </ManageNotificationsStyles.HeaderContainer>

                <ManageNotificationsStyles.NotificationsContainer>

                    {notificationCards}

                </ManageNotificationsStyles.NotificationsContainer>

            </ManageNotificationsStyles.Container>

        </Fragment>

    )

}

export default ManageNotifications