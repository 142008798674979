import {useContext, useEffect, useState} from "react"
import {ModalProviderContext} from "../../providers/router/ModalProvider"
import fieldNames from '../../utils/constants/components/registrationForm/fieldNames.json'
import fieldStates from '../../utils/constants/components/profileViewer/fieldStates.json'
import errorStates from '../../utils/constants/shared/errorStates.json'
import cookieNames from "../../utils/constants/shared/cookieNames.json"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import profileViewerStrings from '../../assets/strings/profileViewerStrings.json'

const useProfileViewer = () => {

    const initialData = {

        [fieldNames.EMAIL]: 'Loading',
        [fieldNames.NAME]: 'Loading',
        [fieldNames.COUNTRY_CODE]: 'Loading',
        [fieldNames.CELL_PHONE]: 'Loading',
        [fieldNames.COUNTRY]: 'Loading',
        [fieldNames.BIRTH_DATE]: 'Loading'

    }

    const initialErrors = {

        [fieldNames.EMAIL]: errorStates.NO_ERROR,
        [fieldNames.NAME]: errorStates.NO_ERROR,
        [fieldNames.COUNTRY_CODE]: errorStates.NO_ERROR,
        [fieldNames.CELL_PHONE]: errorStates.NO_ERROR,
        [fieldNames.COUNTRY]: errorStates.NO_ERROR,
        [fieldNames.BIRTH_DATE]: errorStates.NO_ERROR

    }

    const initialFieldStates = {

        [fieldNames.EMAIL]: fieldStates.UNMODIFIED,
        [fieldNames.NAME]: fieldStates.UNMODIFIED,
        [fieldNames.COUNTRY_CODE]: fieldStates.UNMODIFIED,
        [fieldNames.CELL_PHONE]: fieldStates.UNMODIFIED,
        [fieldNames.COUNTRY]: fieldStates.UNMODIFIED,
        [fieldNames.BIRTH_DATE]: fieldStates.UNMODIFIED

    }

    const [hasChanges, setHasChanges] = useState(false)
    const [data, setData] = useState(initialData)
    const [fieldState, setFieldStates] = useState(initialFieldStates)
    const [errors, setErrors] = useState(initialErrors)
    const { buildModal, buildLoadingModal, setModal } = useContext(ModalProviderContext)

    useEffect(() => {

        getUserData()

    //eslint-disable-next-line
    }, [])

    const getUserData = () => {

        buildLoadingModal()
        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 15000)

        fetch(process.env.REACT_APP_API_URL + apiUrls.GET_USER_DATA, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            signal: controller.signal

        }).then(response => {

            switch (response.status) {

                case 400:
                    buildModal(profileViewerStrings.MODAL_BAD_REQUEST, profileViewerStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 401:
                    buildModal(profileViewerStrings.MODAL_UNAUTHORIZED, profileViewerStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 500:
                    buildModal(profileViewerStrings.MODAL_SERVER_ERROR, profileViewerStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 200:
                    response.json().then(data => {

                        const temporalData = {
                            [fieldNames.EMAIL]: data.email,
                            [fieldNames.NAME]: data.name,
                            [fieldNames.COUNTRY]: data.country,
                            [fieldNames.CELL_PHONE]: data.cellPhone,
                            [fieldNames.COUNTRY_CODE]: data.countryCode,
                            [fieldNames.BIRTH_DATE]: data.birthDate.split('T')[0]
                        }

                        setData(temporalData)
                        setModal(false)

                    }).catch(() => {

                        buildModal(profileViewerStrings.MODAL_DATA_ERROR, profileViewerStrings.MODAL_CLOSE_BUTTON, [])

                    })

                    break

                default:
                    buildModal(profileViewerStrings.MODAL_UNKNOWN_ERROR, profileViewerStrings.MODAL_CLOSE_BUTTON, [])
                    break

            }

        }).catch(() => {

            buildModal(profileViewerStrings.MODAL_FETCH_ERROR, profileViewerStrings.MODAL_CLOSE_BUTTON, [])

        })

    }

    const handleChange = (e) => {

        const {name, value} = e.target

        setData({
            ...data,
            [name]: value
        })

        setFieldStates({
            ...fieldState,
            [name]: fieldStates.CHANGED
        })

        setHasChanges(true)

        handleBlur(e)

    }

    const handleBlur = (e) => {

        const {name, value} = e.target

        switch (name) {

            case fieldNames.CELL_PHONE:
                if(value.trim().length === 10){

                    setErrors({
                        ...errors,
                        [name]: errorStates.NO_ERROR
                    })

                }else{

                    setErrors({
                        ...errors,
                        [name]: errorStates.WITH_ERROR
                    })

                }

                break

            default:
                if(value.trim()){

                    setErrors({
                        ...errors,
                        [name]: errorStates.NO_ERROR
                    })

                }else{

                    setErrors({
                        ...errors,
                        [name]: errorStates.WITH_ERROR
                    })

                }

                break

        }

    }

    const validate = () => {

        const changedElements = {}

        for (const element in fieldState) {

            if(fieldState[element] !== fieldStates.UNMODIFIED){

                changedElements[element] = errors[element]

            }

        }

        for (const changed in changedElements) {

            if(errors[changed] !== errorStates.NO_ERROR){

                return false

            }
            
        }

        return true

    }

    const sendChanges = () => {

        buildLoadingModal()
        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 15000)

        fetch(process.env.REACT_APP_API_URL + apiUrls.SAVE_USER_PROFILE, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            body: JSON.stringify(data),
            signal: controller.signal

        }).then(response => {

            switch (response.status) {

                case 400:
                    buildModal(profileViewerStrings.MODAL_BAD_REQUEST, profileViewerStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 401:
                    buildModal(profileViewerStrings.MODAL_UNAUTHORIZED, profileViewerStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 500:
                    buildModal(profileViewerStrings.MODAL_SERVER_ERROR, profileViewerStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 200:

                    setHasChanges(false)
                    setFieldStates(initialFieldStates)

                    setTimeout(() => {

                        buildModal(profileViewerStrings.MODAL_OK, profileViewerStrings.MODAL_CLOSE_BUTTON, [])

                    }, 5000);

                    break

                default:
                    buildModal(profileViewerStrings.MODAL_UNKNOWN_ERROR, profileViewerStrings.MODAL_CLOSE_BUTTON, [])
                    break

            }

        }).catch(() => {

            buildModal(profileViewerStrings.MODAL_FETCH_ERROR, profileViewerStrings.MODAL_CLOSE_BUTTON, [])

        })

    }

    const handleSaveChanges = () => {

        if(validate()){

            sendChanges()

        }else{

            buildModal(profileViewerStrings.MODAL_FIELD_ERROR, profileViewerStrings.MODAL_CLOSE_BUTTON, [])

        }

    }

    return{
        data,
        errors,
        hasChanges,
        handleChange,
        handleBlur,
        handleSaveChanges
    }

}

export default useProfileViewer