import {Fragment} from "react"
import {LoadingModalStyles} from "../../styles/modals/loadingModal"
import {Text} from "../../styles/shared/Text"

const LoadingModal = () => {

    return(

        <Fragment>

            <LoadingModalStyles.Container>

                <LoadingModalStyles.Card>

                    <LoadingModalStyles.Spinner>
                        <div/><div/><div/>
                    </LoadingModalStyles.Spinner>

                    <Text>LOADING...</Text>

                </LoadingModalStyles.Card>

            </LoadingModalStyles.Container>

        </Fragment>

    )

}

export default LoadingModal