import {Fragment} from "react"
import {SignUpStyles} from "../../styles/pages/signUp"
import SignUpForm from "../../components/signUpForm/SignUpForm"

const SignUp = () => {

    return(

        <Fragment>

            <SignUpStyles.Container>

                <SignUpForm/>

            </SignUpStyles.Container>

        </Fragment>

    )

}

export default SignUp