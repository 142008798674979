import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const ModuleTitle = styled.button `

  padding: 0;
  margin: 20px 0 10px 15px;
  border: none;
  font-family: ${fontValues.TEXT_FONT_FAMILY};
  font-size: ${fontValues.TEXT_SIZE};
  color: ${({theme}) => theme.colors.DARK_TEXT};

`