import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const QuizField = styled.div `

    width: 100%;
    min-height: 80px;
    display: grid;
    row-gap: 10px;
    grid-template-rows: 40% 60%;
    margin-bottom: 20px;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
      margin: 0;
      height: 150px;
      max-width: 100%;
    }

`