import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const Audio = styled.audio `

    height: 40px;
    width: 300px;
    background-color: ${({theme}) => theme.colors.SECONDARY};
    border: none;
    border-radius: 2px;
    padding: 9px;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
      width: 100%;
    }
    
`