import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const ColumnContainer = styled.div `

    width: 90%;
    height: 90%;
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 4%;
    align-items: center;
    justify-items: center;
    justify-self: center;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
      height: fit-content;
      grid-template: none;
      row-gap: 30px;
      padding-bottom: 30px;
    }

`