import {useContext, useEffect, useState} from "react"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import cookieNames from "../../utils/constants/shared/cookieNames.json"
import myLearningCenterStrings from "../../assets/strings/myLearningCenterStrings.json"
import routes from "../../utils/constants/shared/routes.json"
import viewStates from "../../utils/constants/pages/myLearningCenter/viewStates.json"
import {ModalProviderContext} from "../../providers/router/ModalProvider"

const useMyLearningCenter = () => {

    const[view, setView] =  useState(viewStates.PROGRESS_VIEW)
    const[studentStatus, setStudentState] = useState(viewStates.NO_CODE_STUDENT)
    const { buildModal, buildLoadingModal, setModal } = useContext(ModalProviderContext)

    useEffect(() => {

        validateToken()

    //eslint-disable-next-line
    }, [])

    const validateToken = () => {

        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)

        if(!jwt){

            setStudentState(viewStates.NO_LOGGED)

        } else{

            validateStudent(jwt)

        }

    }

    const validateStudent = (jwt) => {

        buildLoadingModal()
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 5000)

        fetch(process.env.REACT_APP_API_URL + apiUrls.VALIDATE_STUDENT, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            signal: controller.signal

        }).then(response => {

            switch (response.status) {

                case 400:
                    buildModal(myLearningCenterStrings.MODAL_BAD_REQUEST, myLearningCenterStrings.MODAL_CLOSE_BUTTON,
                        [])
                    break

                case 401:
                    buildModal(myLearningCenterStrings.MODAL_UNAUTHORIZED, myLearningCenterStrings.MODAL_CLOSE_BUTTON,
                        [routes.LOGIN])
                    break

                case 500:
                    buildModal(myLearningCenterStrings.MODAL_SERVER_ERROR, myLearningCenterStrings.MODAL_CLOSE_BUTTON,
                        [])
                    break

                case 200:
                    handleValidated(response)
                    break

                default:
                    buildModal(myLearningCenterStrings.MODAL_UNKNOWN_ERROR, myLearningCenterStrings.MODAL_CLOSE_BUTTON,
                        [])
                    break

            }

        }).catch(() => {

            buildModal(myLearningCenterStrings.MODAL_FETCH_ERROR, myLearningCenterStrings.MODAL_CLOSE_BUTTON, [])

        })

    }

    const handleValidated = (response) => {

        response.json()
            .then(data => {

                const studentState = {

                    [viewStates.INACTIVE_STUDENT]: () => {
                        setStudentState(viewStates.INACTIVE_STUDENT)
                        setModal(false)
                    },

                    [viewStates.NO_CODE_STUDENT]: () => {
                        setStudentState(viewStates.NO_CODE_STUDENT)
                        setModal(false)
                    },

                    [viewStates.ACTIVE_STUDENT]: () => {
                        setStudentState(viewStates.ACTIVE_STUDENT)
                        setModal(false)
                    }

                }

                studentState[data.studentState]()

            }).catch(() => {

            buildModal(myLearningCenterStrings.MODAL_UNKNOWN_ERROR, myLearningCenterStrings.MODAL_CLOSE_BUTTON, [])

        })

    }

    const setStudentActive = () => {

        setStudentState(viewStates.ACTIVE_STUDENT)

    }

    const setCourseView = () => {

        setView(viewStates.COURSE_VIEW)

    }

    return{
        view,
        setCourseView,
        studentStatus,
        setStudentActive
    }

}

export default useMyLearningCenter