import {Fragment} from "react"
import aboutStrings from '../../assets/strings/aboutStrings.json'
import missionImage from '../../assets/images/about/mission.jpg'
import visionImage from '../../assets/images/about/vision.jpg'

import Footer from "../../components/footer/Footer"
import AnimateObserver from "../../components/animateObserver/AimateObserver"
import {AboutStyles} from "../../styles/pages/about"
import {IntroImage} from "../../styles/pages/about/IntroImage"

const About = () => {

    return (

        <Fragment>

            <AboutStyles.Container>

                <AboutStyles.ViewContainer>

                    <AboutStyles.IntroContainer>

                        <AnimateObserver>

                            <AboutStyles.IntroTextContainer>

                                <AboutStyles.IntroTitle>
                                    {aboutStrings.INTRO_TITLE}
                                </AboutStyles.IntroTitle>

                                <AboutStyles.IntroText>
                                    {aboutStrings.INTRO_TEXT}
                                </AboutStyles.IntroText>

                            </AboutStyles.IntroTextContainer>

                        </AnimateObserver>

                        <AnimateObserver>

                            <IntroImage/>

                        </AnimateObserver>

                    </AboutStyles.IntroContainer>

                </AboutStyles.ViewContainer>

                <AboutStyles.ViewContainer>

                    <AboutStyles.TopicsContainer>

                        <AboutStyles.TopicsMargin $isTop={true}/>

                        <AboutStyles.MissionContainer>

                            <AboutStyles.MissionColumn>

                                <AnimateObserver>

                                    <AboutStyles.MissionImage src={visionImage}/>

                                </AnimateObserver>

                                <AnimateObserver>

                                    <AboutStyles.MissionTitle>
                                        {aboutStrings.MISSION_TITLE}
                                    </AboutStyles.MissionTitle>

                                </AnimateObserver>

                                <AnimateObserver>

                                    <AboutStyles.MissionText>
                                        {aboutStrings.MISSION_TEXT}
                                    </AboutStyles.MissionText>

                                </AnimateObserver>

                            </AboutStyles.MissionColumn>

                            <AboutStyles.MissionColumn>

                                <AnimateObserver>

                                    <AboutStyles.MissionImage src={missionImage}/>

                                </AnimateObserver>

                                <AnimateObserver>

                                    <AboutStyles.MissionTitle>
                                        {aboutStrings.VISION_TITLE}
                                    </AboutStyles.MissionTitle>

                                </AnimateObserver>

                                <AnimateObserver>

                                    <AboutStyles.MissionText>
                                        {aboutStrings.VISION_TEXT}
                                    </AboutStyles.MissionText>

                                </AnimateObserver>

                            </AboutStyles.MissionColumn>

                        </AboutStyles.MissionContainer>

                        <AboutStyles.ValuesContainer>

                            <AboutStyles.MissionTitle>
                                {aboutStrings.VALUES_TITLE}
                            </AboutStyles.MissionTitle>

                            <AboutStyles.ValuesColumnContainer>

                                <AnimateObserver>

                                    <AboutStyles.ValuesColumn>

                                        <AboutStyles.ValuesSubtitle>
                                            {aboutStrings.VALUES_SUBTITLE1}
                                        </AboutStyles.ValuesSubtitle>

                                        <AboutStyles.ValuesText>
                                            {aboutStrings.VALUES_TEXT1}
                                        </AboutStyles.ValuesText>

                                    </AboutStyles.ValuesColumn>

                                </AnimateObserver>

                                <AnimateObserver>

                                    <AboutStyles.ValuesColumn>

                                        <AboutStyles.ValuesSubtitle>
                                            {aboutStrings.VALUES_SUBTITLE2}
                                        </AboutStyles.ValuesSubtitle>

                                        <AboutStyles.ValuesText>
                                            {aboutStrings.VALUES_TEXT2}
                                        </AboutStyles.ValuesText>

                                    </AboutStyles.ValuesColumn>

                                </AnimateObserver>

                                <AnimateObserver>

                                    <AboutStyles.ValuesColumn>

                                        <AboutStyles.ValuesSubtitle>
                                            {aboutStrings.VALUES_SUBTITLE3}
                                        </AboutStyles.ValuesSubtitle>

                                        <AboutStyles.ValuesText>
                                            {aboutStrings.VALUES_TEXT3}
                                        </AboutStyles.ValuesText>

                                    </AboutStyles.ValuesColumn>

                                </AnimateObserver>

                                <AnimateObserver>

                                    <AboutStyles.ValuesColumn>

                                        <AboutStyles.ValuesSubtitle>
                                            {aboutStrings.VALUES_SUBTITLE4}
                                        </AboutStyles.ValuesSubtitle>

                                        <AboutStyles.ValuesText>
                                            {aboutStrings.VALUES_TEXT4}
                                        </AboutStyles.ValuesText>

                                    </AboutStyles.ValuesColumn>

                                </AnimateObserver>

                            </AboutStyles.ValuesColumnContainer>

                        </AboutStyles.ValuesContainer>

                        <AboutStyles.TopicsMargin $isTop={false}/>

                    </AboutStyles.TopicsContainer>

                </AboutStyles.ViewContainer>

                <AboutStyles.ViewContainer>

                    <AboutStyles.MindsetContainer>

                        <AboutStyles.MindsetTitle>
                            {aboutStrings.MINDSET_TITLE}
                        </AboutStyles.MindsetTitle>

                        <AboutStyles.IntroText>
                            {aboutStrings.MINDSET_SUBTITLE1}
                        </AboutStyles.IntroText>

                        <AboutStyles.MindsetColumns>

                            <AnimateObserver>

                                <AboutStyles.MindsetFrame>

                                    <AboutStyles.MindsetSubtitle>
                                        {aboutStrings.MINDSET_FACT_TITLE1}
                                    </AboutStyles.MindsetSubtitle>

                                    <AboutStyles.ValuesText>
                                        {aboutStrings.MINDSET_FACT_TEXT1}
                                    </AboutStyles.ValuesText>

                                </AboutStyles.MindsetFrame>

                            </AnimateObserver>

                            <AnimateObserver>

                                <AboutStyles.MindsetFrame>

                                    <AboutStyles.MindsetSubtitle>
                                        {aboutStrings.MINDSET_FACT_TITLE2}
                                    </AboutStyles.MindsetSubtitle>

                                    <AboutStyles.ValuesText>
                                        {aboutStrings.MINDSET_FACT_TEXT2}
                                    </AboutStyles.ValuesText>

                                </AboutStyles.MindsetFrame>

                            </AnimateObserver>

                            <AnimateObserver>

                                <AboutStyles.MindsetFrame>

                                    <AboutStyles.MindsetSubtitle>
                                        {aboutStrings.MINDSET_FACT_TITLE3}
                                    </AboutStyles.MindsetSubtitle>

                                    <AboutStyles.ValuesText>
                                        {aboutStrings.MINDSET_FACT_TEXT3}
                                    </AboutStyles.ValuesText>

                                </AboutStyles.MindsetFrame>

                            </AnimateObserver>

                        </AboutStyles.MindsetColumns>

                        <AboutStyles.IntroText>
                            {aboutStrings.MINDSET_SUBTITLE2}
                        </AboutStyles.IntroText>

                    </AboutStyles.MindsetContainer>

                </AboutStyles.ViewContainer>

                <Footer/>

            </AboutStyles.Container>

        </Fragment>

    )

}

export default About