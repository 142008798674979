import {Fragment} from "react"
import useUserDetails from "./useUserDetails"
import userDetailsStrings from "../../assets/strings/userDetailsStrings.json"
import buttonNames from '../../utils/constants/components/userDetails/buttonNames.json'

import {UserDetailsStyles} from "../../styles/components/userDetails"
import {Title} from "../../styles/pages/privacy/Title"

const UserDetails = ({ userId, closeDetails }) => {

    const {
        details,
        confirmation,
        confirmationText,
        handleManageAccount,
        closeConfirmation,
        acceptConfirmation
    } = useUserDetails(userId)

    const confirmationView = (

        <UserDetailsStyles.Card>

            <UserDetailsStyles.Text>
                {confirmationText}
            </UserDetailsStyles.Text>

            <UserDetailsStyles.ConfirmButtonsContainer>

                <UserDetailsStyles.CloseButton onClick={closeConfirmation}>
                    {userDetailsStrings.CANCEL_BUTTON}
                </UserDetailsStyles.CloseButton>

                <UserDetailsStyles.CloseButton onClick={acceptConfirmation}>
                    {userDetailsStrings.CONFIRM_BUTTON}
                </UserDetailsStyles.CloseButton>

            </UserDetailsStyles.ConfirmButtonsContainer>

        </UserDetailsStyles.Card>

    )

    const detailsView = (

        <UserDetailsStyles.Card>

            <UserDetailsStyles.FieldsContainer>

                <Title>{userDetailsStrings.ACCOUNT_DETAILS}</Title>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.NAME}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>
                        {details.name}
                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.EMAIL}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>
                        {details.email}
                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.COUNTRY_CODE}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>
                        {details.countryCode}
                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.CELLPHONE}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>
                        {details.cellPhone}
                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.BIRTH_DATE}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>
                        {details.birthDate}
                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.COUNTRY}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>
                        {details.country}
                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.REGISTRATION_DATE}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>
                        {details.registrationDate}
                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.MANAGER}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>

                        <UserDetailsStyles.Button name={buttonNames.MANAGER}
                                                  onClick={handleManageAccount}>
                            {details.manager ? 'Deactivate' : 'Activate'}
                        </UserDetailsStyles.Button>

                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.SALES}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>

                        <UserDetailsStyles.Button name={buttonNames.SALES}
                                                  onClick={handleManageAccount}>
                            {details.sales ? 'Deactivate' : 'Activate'}
                        </UserDetailsStyles.Button>

                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <Title></Title>

                <Title>{userDetailsStrings.STUDENT_TRACKING}</Title>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.COURSE_PROGRESS}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>
                        {details.courseProgress} %
                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.AVERAGE_QUIZ_RESULTS}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>
                        {details.averageQuizResults} / 100
                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.COMPLETED_CLASSES}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>
                        {details.completedClasses}
                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.LAST_CLASS}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>
                        {details.lastClass}
                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.LATEST_ACTIVITY}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>
                        {details.latestActivity}
                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.SALES_CODE}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>
                        {details.salesCode}
                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.CLAIMED_DATE}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>
                        {details.claimedDate}
                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <UserDetailsStyles.FieldContainer style={{display: details.activeStudent ? 'grid' : 'none'}}>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.ACTIVE_STUDENT}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>

                        <UserDetailsStyles.Button name={buttonNames.DEACTIVATE}
                                                  onClick={handleManageAccount} >

                            {userDetailsStrings.DEACTIVATE_BUTTON}

                        </UserDetailsStyles.Button>

                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <Title></Title>

                <Title>{userDetailsStrings.WARNING}</Title>

                <UserDetailsStyles.FieldContainer>

                    <UserDetailsStyles.FieldLabel>
                        {userDetailsStrings.DELETE_ACCOUNT}
                    </UserDetailsStyles.FieldLabel>

                    <UserDetailsStyles.FieldData>

                        <UserDetailsStyles.Button style={{backgroundColor: "red"}}
                                                  name={buttonNames.DELETE_ACCOUNT} onClick={handleManageAccount}>

                            {userDetailsStrings.DELETE_BUTTON}

                        </UserDetailsStyles.Button>

                    </UserDetailsStyles.FieldData>

                </UserDetailsStyles.FieldContainer>

                <Title></Title>

            </UserDetailsStyles.FieldsContainer>

            <UserDetailsStyles.CloseButton onClick={closeDetails}>
                {userDetailsStrings.CLOSE_BUTTON}
            </UserDetailsStyles.CloseButton>

        </UserDetailsStyles.Card>

    )

    return(

        <Fragment>

            <UserDetailsStyles.Container>

                {confirmation ? confirmationView : detailsView}

            </UserDetailsStyles.Container>

        </Fragment>

    )

}

export default UserDetails