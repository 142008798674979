import { useState, useEffect, useRef } from "react"
import {useNavigate} from "react-router-dom"
import routes from '../../utils/constants/shared/routes.json'
import learning1 from '../../assets/images/home/learning1.webp'
import learning2 from '../../assets/images/home/learning2.webp'
import learning3 from '../../assets/images/home/learning3.webp'
import learning4 from '../../assets/images/home/learning4.webp'

const useHome = () => {

    const navigate = useNavigate()
    const [index, setIndex] = useState(0)
    const [fade, setFade] = useState(false)
    const images = [learning1, learning2, learning3, learning4]
    const intervalRef = useRef(null)

    useEffect(() => {

        startInterval()

        return () => {
            clearInterval(intervalRef.current)
        }

    }, [])

    const startInterval = () => {

        intervalRef.current = setInterval(() => {
            goForward()
        }, 5000)

    }

    const stopInterval = () => {

        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }

    }

    const goForward = () => {

        stopInterval()
        setFade(true)

        setTimeout(() => {

            setIndex((prevIndex) => (prevIndex + 1) % images.length)
            setFade(false)
            startInterval()

        }, 400)

    }

    const goBackward = () => {

        stopInterval()
        setFade(true)

        setTimeout(() => {

            setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length)
            setFade(false)
            startInterval()

        }, 400)

    }

    const handleClick = () => {

        navigate(routes.PRICING)

    }

    return {
        index,
        image: images[index],
        fade,
        goForward,
        goBackward,
        handleClick
    }

}

export default useHome