import styled from "styled-components"

export const CarrouselIndicator = styled.div `

    width: 5px;
    height: 5px;
    background-color: ${(props) => props.$isActive ? 'none' : 'white'};
    border-radius: 50%;
    border: ${(props) => props.$isActive ? '2px solid white' : 'none'};

`