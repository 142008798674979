import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const MindsetColumns = styled.div `

    width: 950px;
    height: max-content;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    justify-items: center;
    margin: 30px 0;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        width: 85%;
        grid-template-columns: unset;
        row-gap: 30px;
        
    }

`