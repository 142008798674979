import styled from "styled-components"

export const Container = styled.section`

    width: 100%;
    height: 100%;
    display: grid;
    overflow-y: scroll;
    background-color: ${({theme}) => theme.colors.LIGHT_BACKGROUND};

`