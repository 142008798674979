import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const Button = styled.button `

    width: 150px;
    height: 50px;
    justify-self: center;
    background-color: ${({theme}) => theme.colors.PRIMARY};
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    color: ${({theme}) => theme.colors.LIGHT_TEXT};
    border: none;
    border-radius: 2px;
  
    &:hover{
      background-color: ${({theme}) => theme.colors.BUTTON_HOVER};
    }

`