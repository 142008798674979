import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const OptionTitle = styled.p `

    width: 280px;
    margin: 0 0 10px 0;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.HOME_TEXT3_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};
    font-weight: bolder;
    border: none;
    justify-self: center;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    
        margin: 0;
        width: max-content;
    
    }
    
`