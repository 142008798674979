import {useContext, useState} from "react"
import cookieNames from "../../utils/constants/shared/cookieNames.json"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import generateCodeStrings from "../../assets/strings/generateCodeStrings.json"
import routes from "../../utils/constants/shared/routes.json"
import {ModalProviderContext} from "../../providers/router/ModalProvider"

const useGenerateCode = () => {

    const[code, setCode] = useState(generateCodeStrings.INITIAL_STATE)
    const { buildModal, buildLoadingModal, setModal } = useContext(ModalProviderContext)

    const handleGenerateCode = () => {

        buildLoadingModal()
        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 5000)

        fetch(process.env.REACT_APP_API_URL + apiUrls.GENERATE_CODE, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            signal: controller.signal

        }).then(response => {

            switch (response.status) {

                case 400:
                    buildModal(generateCodeStrings.MODAL_BAD_REQUEST, generateCodeStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 401:
                    buildModal(generateCodeStrings.MODAL_UNAUTHORIZED, generateCodeStrings.MODAL_CLOSE_BUTTON,
                    [routes.LOGIN])
                    break

                case 500:
                    buildModal(generateCodeStrings.MODAL_SERVER_ERROR, generateCodeStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 200:
                    handleGenerated(response)
                    break

                default:
                    buildModal(generateCodeStrings.MODAL_UNKNOWN_ERROR, generateCodeStrings.MODAL_CLOSE_BUTTON, [])
                    break

            }

        }).catch(() => {

            buildModal(generateCodeStrings.MODAL_FETCH_ERROR, generateCodeStrings.MODAL_CLOSE_BUTTON, [])

        })

    }

    const handleGenerated = (response) => {

        response.json()
            .then(data => {

                setTimeout(() => {

                    setCode(data.salesCode)
                    setModal(false)

                }, 5000)

            }).catch(() => {

                buildModal(generateCodeStrings.MODAL_UNKNOWN_ERROR, generateCodeStrings.MODAL_CLOSE_BUTTON, [])

            })

    }

    return{
        code,
        handleGenerateCode
    }

}

export default useGenerateCode