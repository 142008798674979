import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const IntroTitle = styled.pre `

    margin: 0;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.HOME_TITLE_SIZE};
    color: ${({theme}) => theme.colors.HOME_PRIMARY};
    font-weight: bolder;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){

        font-size: ${fontValues.HOME_TITLE1_SIZE};
        
    }

`