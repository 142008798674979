import {useContext, useEffect, useRef, useState} from "react"
import cookieNames from "../../utils/constants/shared/cookieNames.json"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import courseViewerStrings from "../../assets/strings/activityViewerStrings.json"
import studentActivitiesStates from "../../utils/constants/components/activityViewer/studentActivitiesStates.json"
import elementNames from "../../utils/constants/components/activityViewer/elementNames.json"
import jwt_decode from "jwt-decode"
import { useTheme } from 'styled-components'
import {ModalProviderContext} from "../../providers/router/ModalProvider"
import {SelectorProviderContext} from "../../providers/CourseView/SelectorProvider"

import {Text} from "../../styles/shared/Text"
import {ActivityViewerStyles} from "../../styles/components/activityViewer"

const useActivityViewer = () => {

    const initialContent = {
        title: '',
        instructions: '',
        question: ''
    }

    const initialText = {
        [elementNames.FIELD1]: '',
        [elementNames.FIELD2]: ''
    }

    const[data, setData] = useState('')
    const[loadingData, setLoadingData] = useState(true)
    const[quizResult, setQuizResult] = useState(null)
    const[quizQuestions, setQuizQuestions] = useState(null)
    const[lectureAudio, setLectureAudio] = useState(null)
    const[lessonAudio, setLessonAudio] = useState(null)
    const[transcription, setTranscription] = useState(null)
    const[title, setTitle] = useState('')
    const[content, setContent] = useState(initialContent)
    const[text, setText] = useState(initialText)
    const[answered, setAnswered] = useState(false)
    const results = useRef(null)
    const quizOptions = useRef({})
    const activity = useRef(1)
    const { buildModal, buildLoadingModal } = useContext(ModalProviderContext)
    const { position, setPosition, isOpen, setOpen, setCompleted} = useContext(SelectorProviderContext)
    const theme = useTheme()

    useEffect(() => {

        sayHello()

    //eslint-disable-next-line
    }, [])

     useEffect(() => {

         if(position.level !== 0) getClassData()

     //eslint-disable-next-line
     }, [position.level, position.module, position.class])

    useEffect(() => {

        if(data !== '') getContent()

    //eslint-disable-next-line
    }, [data, position.activity])

    useEffect(() => {

        if(quizQuestions !== null && answered) validateStudentActivities()

    //eslint-disable-next-line
    }, [quizQuestions])

    useEffect(() => {

        if(quizQuestions === null && position.activity === 5) generateQuiz()

    //eslint-disable-next-line
    }, [quizQuestions])

    const getClassData = () => {

        setLoadingData(true)
        setQuizResult(null)
        quizOptions.current = {}
        setQuizQuestions(null)
        setText(initialText)
        setAnswered(false)
        activity.current = 1

        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 15000)

        fetch(process.env.REACT_APP_API_URL + apiUrls.GET_CLASS_DATA, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            body: JSON.stringify({level: position.level, module: position.module, class: position.class}),
            signal: controller.signal

        }).then(response => {

            if(handleResponseStatus(response)){

                response.json().then(data => {

                    setClassData(data)
                    getLessonAudio()

                }).catch(() => {

                    buildModal(courseViewerStrings.MODAL_LOADING_DATA_ERROR,
                        courseViewerStrings.MODAL_CLOSE_BUTTON, [])

                })

            }

        }).catch(() => {

            buildModal(courseViewerStrings.MODAL_FETCH_ERROR, courseViewerStrings.MODAL_CLOSE_BUTTON, [])

        })

    }

    const setClassData = (data) => {

        setData(data)

        if(Object.keys(data.answers).length !== 0){

            const {field1, field2, quizAnswers} = data.answers

            setAnswered(true)

            setText({
                [elementNames.FIELD1]: field1,
                [elementNames.FIELD2]: field2
            })

            setPosition({
                ...position,
                activity: 5
            })

            activity.current = 5
            quizOptions.current = quizAnswers

        }

    }

    const getLessonAudio = () => {

        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 60000)

        fetch(process.env.REACT_APP_API_URL + apiUrls.GET_CLASS_AUDIOS, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            body: JSON.stringify({
                level: position.level,
                module: position.module,
                class: position.class,
                lectureAudio: false
            }),
            signal: controller.signal

        }).then(response => {

            if(handleResponseStatus(response)){

                //TODO handle loading and responses
                response.blob().then(lessonBlob => {

                    const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
                    const controller = new AbortController()
                    setTimeout(() => controller.abort(), 60000)

                    fetch(process.env.REACT_APP_API_URL + apiUrls.GET_CLASS_AUDIOS, {

                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': jwt
                        },
                        body: JSON.stringify({
                            level: position.level,
                            module: position.module,
                            class: position.class,
                            lectureAudio: true
                        }),
                        signal: controller.signal

                    }).then(response => {

                        if(handleResponseStatus(response)){

                            response.blob().then(lectureBlob => {

                                const lessonAudioURL = URL.createObjectURL(lessonBlob)
                                const lessonAudioElement = document.createElement('audio')
                                lessonAudioElement.src = lessonAudioURL
                                lessonAudioElement.controls = true

                                setLessonAudio(
                                    <ActivityViewerStyles.Audio $isActive={position.activity !== 4} controls>
                                        <source src={lessonAudioURL} />
                                    </ActivityViewerStyles.Audio>
                                )

                                const lectureAudioURL = URL.createObjectURL(lectureBlob)
                                const lectureAudioElement = document.createElement('audio')
                                lectureAudioElement.src = lectureAudioURL
                                lectureAudioElement.controls = true

                                setLectureAudio(
                                    <ActivityViewerStyles.Audio $isActive={position.activity === 4} controls>
                                        <source src={lectureAudioURL} />
                                    </ActivityViewerStyles.Audio>
                                )

                                setLoadingData(false)

                            }).catch(() => {

                                buildModal(courseViewerStrings.MODAL_LOADING_DATA_ERROR,
                                    courseViewerStrings.MODAL_CLOSE_BUTTON, [])

                            })

                        }

                    }).catch(() => {

                        buildModal(courseViewerStrings.MODAL_FETCH_ERROR, courseViewerStrings.MODAL_CLOSE_BUTTON, [])

                    })

                }).catch(() => {

                    buildModal(courseViewerStrings.MODAL_LOADING_DATA_ERROR,
                        courseViewerStrings.MODAL_CLOSE_BUTTON, [])

                })

            }

        }).catch(() => {

            buildModal(courseViewerStrings.MODAL_FETCH_ERROR, courseViewerStrings.MODAL_CLOSE_BUTTON, [])

        })

    }

    const changeActivity = (e) => {

        const {name} = e.currentTarget

        if(name === elementNames.NEXT && activity.current < 5) activity.current = activity.current + 1

        else if(name === elementNames.PREVIOUS && activity.current > 1) activity.current = activity.current - 1

        setPosition({
            ...position,
            activity: activity.current
        })

    }

    const getContent = () => {

        switch (position.activity){

            case 5:
                setTitle(data.quiz.TITLE)
                generateQuiz()
                break

            case 4:
                setTranscription(lineSeparator(data.lecture.TRANSCRIPTION, 'transcription'))
                setTitle(data.lecture.TITLE)
                break

            case 3:
                setTranscription(lineSeparator(data.lesson.TRANSLATION, 'transcription'))
                setTitle(data.lesson.TITLE)
                break

            default:
                setTranscription(lineSeparator(data.lesson.TRANSCRIPTION, 'transcription'))
                setTitle(data.lesson.TITLE)
                break

        }

        const contentTitle = `ACTIVITY${position.activity}_TITLE`
        const contentQuestion = `ACTIVITY${position.activity}_QUESTION`

        const contentInstructions = `ACTIVITY${position.activity}_INSTRUCTIONS`
        const instructions =
            lineSeparator(courseViewerStrings[contentInstructions], 'instruction')

        setContent({
            ...content,
            title: courseViewerStrings[contentTitle],
            instructions: instructions,
            question: courseViewerStrings[contentQuestion]
        })

    }

    const generateQuiz = () => {

        const questions = data.quiz.QUESTIONS
        const questionFields = []
        let index = 1

        for (const question in questions) {

            const options = data.quiz.OPTIONS[`O${index}`]
            const optionsFields = []

            for (const option in options) {

                if(option !== 'Z') {

                    optionsFields.push(
                        <ActivityViewerStyles.QuizOption key={question + option} id={question + option}
                        onClick={handleQuizOption}>
                            {options[option]}
                        </ActivityViewerStyles.QuizOption>
                    )

                }

            }

            questionFields.push(
                <ActivityViewerStyles.QuizField key={'container' + question}>

                    <Text key={question}>{questions[question]}</Text>

                    <ActivityViewerStyles.QuizOptionsContainer>
                        {optionsFields}
                    </ActivityViewerStyles.QuizOptionsContainer>

                </ActivityViewerStyles.QuizField>
            )

            index++

        }

        setQuizQuestions(questionFields)

    }

    const handleQuizOption = (e) => {

        const {id} = e.target
        const question = id.slice(0, -1)
        const option = id.charAt(id.length - 1)

        if(question in quizOptions.current){

            const element = document.getElementById(question + quizOptions.current[question])
            element.style.backgroundColor = theme.colors.DARK_BACKGROUND
            element.style.color = theme.colors.DARK_TEXT

        }

        const element = document.getElementById(e.target.id)
        element.style.backgroundColor = theme.colors.BUTTON_SECONDARY_HOVER
        element.style.color = theme.colors.LIGHT_TEXT
        quizOptions.current[question] = option

    }

    const lineSeparator = (string, lineNames) => {

        if(string !== undefined){

            //eslint-disable-next-line
            return string.split('\n').map((line, index) => {

                if(index !== string.split('\n').length - 1){

                    return (
                        <Text key={lineNames + index}>
                            {line}
                            <br/>
                        </Text>
                    )

                }

            })

        }else{

            return []

        }

    }

    const handleTextChange = (e) => {

        const {id, value} = e.target

        setText({
            ...text,
            [id]: value
        })

    }

    const handleSubmit = () => {

        const sendAnswers = () => {

            buildLoadingModal()
            const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
            const controller = new AbortController()
            setTimeout(() => controller.abort(), 5000)

            fetch(process.env.REACT_APP_API_URL + apiUrls.SAVE_STUDENT_RESPONSES, {

                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwt
                },
                body: JSON.stringify({
                    level: position.level,
                    module: position.module,
                    class: position.class,
                    text: text,
                    quiz: results.current
                }),
                signal: controller.signal

            }).then(response => {

                if(handleResponseStatus(response)){

                    setAnswered(true)
                    setCompleted(true)

                    setTimeout(() => {

                        buildModal(courseViewerStrings.MODAL_ANSWERS_SAVED,
                            courseViewerStrings.MODAL_CLOSE_BUTTON, [])

                    }, 3000)

                }

            }).catch(() => {

                buildModal(courseViewerStrings.MODAL_FETCH_ERROR, courseViewerStrings.MODAL_CLOSE_BUTTON, [])

            })

        }

        const studentActivityState = validateStudentActivities()
        switch (studentActivityState) {

            case studentActivitiesStates.LESSON_ACTIVITY_MISSING:
                buildModal(courseViewerStrings.MODAL_LESSON_MISSING, courseViewerStrings.MODAL_CLOSE_BUTTON, [])
                break

            case studentActivitiesStates.QUIZ_ANSWER_MISSING:
                buildModal(courseViewerStrings.MODAL_ANSWER_MISSING, courseViewerStrings.MODAL_CLOSE_BUTTON, [])
                break

            case studentActivitiesStates.ALL_ACTIVITIES_COMPLETED:
                sendAnswers()
                break

            default:
                buildModal(courseViewerStrings.MODAL_UNKNOWN_ERROR, courseViewerStrings.MODAL_CLOSE_BUTTON, [])
                break

        }

    }

    const validateStudentActivities = () => {

        const questionsNumber = Object.keys(data.quiz.QUESTIONS).length
        const answersNumber = Object.keys(quizOptions.current).length

        if(!text[elementNames.FIELD1].trim() || !text[elementNames.FIELD2].trim()){

            return studentActivitiesStates.LESSON_ACTIVITY_MISSING

        }else{

            if(answersNumber !== questionsNumber){

                return studentActivitiesStates.QUIZ_ANSWER_MISSING

            }else{

                const answers = quizOptions.current
                const questions = data.quiz.OPTIONS
                let answerIndex = 1
                let correctAnswers = 0

                for (const answer in answers) {

                    const elementId = `Q${answerIndex}${answers[answer]}`
                    const element = document.getElementById(elementId)

                    if(answers[answer] === questions['O' + answerIndex].Z) {

                        element.style.backgroundColor = theme.colors.DARK_BACKGROUND
                        element.style.border = `2px solid ${theme.colors.QUIZ_CORRECT_OPTION}`
                        element.style.color = theme.colors.DARK_TEXT

                        correctAnswers++

                    }else{

                        element.style.backgroundColor = theme.colors.DARK_BACKGROUND
                        element.style.border = `2px solid ${theme.colors.QUIZ_INCORRECT_OPTION}`
                        element.style.color = theme.colors.DARK_TEXT

                    }

                    answerIndex++

                }

                const percentagePerAnswer = 100 / questionsNumber
                const quizResult = correctAnswers * percentagePerAnswer

                answers.quizResult = quizResult.toFixed(1)
                results.current = answers
                setQuizResult(quizResult.toFixed(1))

                return studentActivitiesStates.ALL_ACTIVITIES_COMPLETED

            }

        }

    }

    const handleOpenMenu = () => {

        setOpen(true)

    }

    const handleCloseMenu = () => {

        if(isOpen) setOpen(false)

    }

    /**
     * Handle a response from api and handle all response status
     * @param response is a response from a fetch api request
     * @returns {boolean} if response is "ok" returns true
     */
    const handleResponseStatus = (response) => {

        switch (response.status){

            case 400:
                buildModal(courseViewerStrings.MODAL_CLIENT_ERROR, courseViewerStrings.MODAL_CLOSE_BUTTON, [])
                break

            case 401:
                buildModal(courseViewerStrings.MODAL_UNAUTHORIZED, courseViewerStrings.MODAL_CLOSE_BUTTON, [])
                break

            case 500:
                buildModal(courseViewerStrings.MODAL_SERVER_ERROR, courseViewerStrings.MODAL_CLOSE_BUTTON, [])
                break

            case 200:
                return true

            default:
                buildModal(courseViewerStrings.MODAL_UNKNOWN_ERROR, courseViewerStrings.MODAL_CLOSE_BUTTON, [])
                break


        }

    }

    const handleRetryQuiz = () => {

        setAnswered(false)
        setQuizResult(null)
        quizOptions.current = {}
        setQuizQuestions(null)

    }

    const sayHello = () => {

        const token = localStorage.getItem(cookieNames.JWT_COOKIE)
        const jwtDecoded = jwt_decode(token)
        const hiString = jwtDecoded.name + courseViewerStrings.MODAL_HI

        buildModal(hiString, courseViewerStrings.MODAL_CLOSE_BUTTON, [])

    }

    return{
        loadingData,
        quizQuestions,
        quizResult,
        position,
        content,
        title,
        transcription,
        lessonAudio,
        lectureAudio,
        answered,
        text,
        isOpen,
        handleOpenMenu,
        handleCloseMenu,
        changeActivity,
        handleTextChange,
        handleSubmit,
        handleRetryQuiz
    }

 }

 export default useActivityViewer