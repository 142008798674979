import {Container} from "./Container"
import {Title} from "./Title"
import {DownloadButton} from "./DownloadButton"

export const StudentsReportStyles = {

    Container,
    Title,
    DownloadButton

}