import {Fragment} from "react"
import logo from '../../assets/images/shared/logo.svg'
import navBarStrings from "../../assets/strings/navBarStrings.json"
import routes from "../../utils/constants/shared/routes.json"
import {NavBarStyles} from "../../styles/components/navBar"
import {createPortal} from "react-dom"

import useNavBar from "./useNavBar"

const NavBar = () => {

    const {
        profileName,
        isLogged,
        currentPage,
        isOpen,
        notificationsViewer,
        notificationAlert,
        handleLogOut,
        handleClick,
        handleOpen,
        openNotificationsViewer
    } = useNavBar()

    return(

        <Fragment>

            {createPortal(notificationsViewer, document.body)}

            <NavBarStyles.Container>

                <NavBarStyles.PrincipalLink to={routes.HOME}>
                    <NavBarStyles.Logo src={logo}/>
                </NavBarStyles.PrincipalLink>

                <NavBarStyles.AppName to={routes.HOME}>{/*navBarStrings.APP_NAME*/}</NavBarStyles.AppName>

                <NavBarStyles.Menu $isOpen={isOpen} onClick={handleOpen}/>

                <NavBarStyles.Links $isOpen={isOpen}>

                    <NavBarStyles.SecondaryLink to={isLogged ? routes.PROFILE : routes.LOGIN} id={routes.LOGIN}
                    onClick={handleClick} $isActive={currentPage === routes.LOGIN || currentPage === routes.PROFILE}>

                        {isLogged ? profileName : navBarStrings.LOGIN}

                    </NavBarStyles.SecondaryLink>

                    <NavBarStyles.SecondaryLink to={isLogged ? routes.LOGIN : routes.SIGN_UP} id={routes.SIGN_UP}
                    onClick={isLogged ? handleLogOut : handleClick} $isActive={currentPage === routes.SIGN_UP}>

                        {isLogged ? navBarStrings.LOG_OUT : navBarStrings.SIGN_UP}

                    </NavBarStyles.SecondaryLink>

                    <NavBarStyles.NotificationContainer onClick={openNotificationsViewer}>

                        <NavBarStyles.Notification/>

                        <NavBarStyles.NotificationAlert
                        $isActive={notificationAlert}>

                            !

                        </NavBarStyles.NotificationAlert>

                    </NavBarStyles.NotificationContainer>

                    <NavBarStyles.PrincipalLink to={routes.MY_LEARNING_CENTER} id={routes.MY_LEARNING_CENTER}
                    onClick={handleClick} $isActive={currentPage === routes.MY_LEARNING_CENTER}>

                        {navBarStrings.MY_LEARNING_CENTER}

                    </NavBarStyles.PrincipalLink>

                    <NavBarStyles.PrincipalLink to={routes.COURSES_LIST} id={routes.COURSES_LIST}
                    onClick={handleClick} $isActive={currentPage === routes.COURSES_LIST}>

                        {navBarStrings.COURSES}

                    </NavBarStyles.PrincipalLink>

                    <NavBarStyles.PrincipalLink to={routes.PRICING} id={routes.PRICING}
                    onClick={handleClick} $isActive={currentPage === routes.PRICING}>

                        {navBarStrings.PRICING}

                    </NavBarStyles.PrincipalLink>

                </NavBarStyles.Links>

            </NavBarStyles.Container>

        </Fragment>

    )

}

export default NavBar