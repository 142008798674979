import styled from "styled-components"
import {CSVLink} from 'react-csv'
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const DownloadButton = styled(CSVLink) `

    width: 150px;
    height: 50px;
    justify-self: center;
    margin-top: 30px;
    background-color: ${({theme}) => theme.colors.PRIMARY};
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.SMALL_TEXT_SIZE};
    color: ${({theme}) => theme.colors.LIGHT_TEXT};
    font-weight: normal;
    border: none;
    border-radius: 2px;
    text-decoration: none;
    display: grid;
    justify-content: center;
    align-content: center;
  
    &:hover{
      background-color: ${({theme}) => theme.colors.BUTTON_HOVER};
    }

`