import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const ValuesColumn = styled.div `

    width: 100%;
    max-width: 213px;
    height: max-content;
    display: grid;
    grid-template-rows: 70px calc(100% - 70px);

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        max-width: 85%;
        grid-template-rows: unset;
        row-gap: 10px;
        
    }

`