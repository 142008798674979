import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const OptionContainer = styled.div `
    
    width: 90%;
    height: 90%;
    display: flex;
    flex-flow: column;
    justify-items: center;
    justify-content: center;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        width: max-content;
        height: max-content;
        align-items: center;
        gap: 10px;
        
    }
    
`