import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const MindsetFrame = styled.div `

    width: 210px;
    height: calc(100% - 60px);
    display: grid;
    row-gap: 20px;
    padding: 30px 20px;
    background-color: ${({theme}) => theme.colors.MINDSET_FRAME_COLOR};
    border-radius: 16px;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        width: calc(100% - 40px);
        
    }
    
`