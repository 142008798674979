import {useContext, useEffect, useState} from "react"
import {ModalProviderContext} from "../../providers/router/ModalProvider"
import filtersName from "../../utils/constants/components/userCardsFrame/filtersName.json"
import cookieNames from "../../utils/constants/shared/cookieNames.json"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import userCardsFrameStrings from "../../assets/strings/userCardsFrameStrings.json"

import UserCard from "../userCard/UserCard"

const useUserCardsFrame = () => {

    const initialFilters = {
        [filtersName.STUDENT]: false,
        [filtersName.SALES]: false,
        [filtersName.MANAGER]: false
    }

    const[data, setData] = useState(null)
    const[filters, setFilters] = useState(initialFilters)
    const[search, setSearch] =  useState('')
    const[cards, setCards] = useState(null)
    const { buildModal, buildLoadingModal, setModal } = useContext(ModalProviderContext)

    useEffect(() => {

        getUserCards()

    //eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(data) applyFilter()

    //eslint-disable-next-line
    }, [filters, search])

    const getUserCards = () => {

        buildLoadingModal()
        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 20000)

        fetch(process.env.REACT_APP_API_URL + apiUrls.GET_USER_CARDS, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            signal: controller.signal

        }).then((response) => {

            switch (response.status){

                case 400:
                    buildModal(userCardsFrameStrings.MODAL_BAD_REQUEST, userCardsFrameStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 401:
                    buildModal(userCardsFrameStrings.MODAL_UNAUTHORIZED, userCardsFrameStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 500:
                    buildModal(userCardsFrameStrings.MODAL_SERVER_ERROR, userCardsFrameStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 200:
                    response.json().then(data => {

                        setData(data)
                        generateUserCards(data)
                        setModal(false)

                    }).catch(e => {

                        console.log(e)
                        buildModal(userCardsFrameStrings.MODAL_DATA_ERROR,
                        userCardsFrameStrings.MODAL_CLOSE_BUTTON, [])

                    })

                    break

                default:
                    buildModal(userCardsFrameStrings.MODAL_UNKNOWN_ERROR, userCardsFrameStrings.MODAL_CLOSE_BUTTON, [])
                    break

            }

        }).catch(e => {

            console.log(e)
            buildModal(userCardsFrameStrings.MODAL_FETCH_ERROR, userCardsFrameStrings.MODAL_CLOSE_BUTTON, [])

        })

    }

    const handleSearch = (e) => {

        const {value} = e.target
        setSearch(value.trim())

    }

    const handleFilter = (e) => {

        const {name} = e.target

        setFilters({
            ...filters,
            [name]: !filters[name]
        })

    }

    const applyFilter = () => {

        let filteredArray = data.filter(obj => {

            if (filters.manager && filters.sales && filters.student) {

                return obj.manager === 1 && obj.sales === 1 && obj.student === 1

            } else if (filters.manager && filters.sales) {

                return obj.manager === 1 && obj.sales === 1

            } else if (filters.manager && filters.student) {

                return obj.manager === 1 && obj.student === 1

            } else if (filters.sales && filters.student) {

                return obj.sales === 1 && obj.student === 1

            } else if (filters.manager) {

                return obj.manager === 1

            } else if (filters.sales) {

                return obj.sales === 1

            } else if (filters.student) {

                return obj.student === 1

            }

            return true

        })

        filteredArray = filteredArray.filter(obj => {

            if(search !== ''){

                return obj.name.toLowerCase().includes(search.toLowerCase())
                    || obj.email.toLowerCase().includes(search.toLowerCase())
                    || obj.cellPhone.toLowerCase().includes(search.toLowerCase())
                    || obj.registrationDate.split('T')[0].includes(search.toLowerCase())

            }else{

                return true

            }

        })

        generateUserCards(filteredArray)

    }

    const generateUserCards = (data) => {

        const temporaryCards = []

        for (const element in data) {

            const {userId, name, email, cellPhone, registrationDate, student, sales, manager} = data[element]

            temporaryCards.push(
                <UserCard id={userId} name={name} email={email} cellPhone={cellPhone}
                registrationDate={registrationDate} student={student} sales={sales} manager={manager}
                key={email + cellPhone}/>
            )

        }

        setCards(temporaryCards)

    }

    return{
        cards,
        filters,
        handleFilter,
        handleSearch
    }

}

export default useUserCardsFrame