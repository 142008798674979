import styled from "styled-components"
import wave from '../../../assets/images/home/wave.svg'
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const MethodMargin = styled. div `

    width: 100%;
    height: 90px;
    background-image: url(${wave});
    background-repeat: repeat-x;
    background-size: 40% 100%;
    background-position: top right;
    transform: ${props => props.$isTop ? 'rotate(180deg)': 'none'};

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        background-size: 80% 100%;
    }

`