import {Container} from "./Container"
import {PrincipalLink} from "./PrincipalLink"
import {SecondaryLink} from "./SecondaryLink"
import {Logo} from "./Logo"
import {Links} from "./Links"
import {Menu} from "./Menu"
import {AppName} from "./AppName"
import {Notification} from "./Notifications"
import {NotificationContainer} from "./NotificationContainer"
import {NotificationAlert} from "./NotificationAlert"

export const NavBarStyles = {
    Container: Container,
    PrincipalLink: PrincipalLink,
    SecondaryLink: SecondaryLink,
    Logo: Logo,
    Links: Links,
    Menu: Menu,
    AppName: AppName,
    Notification: Notification,
    NotificationContainer: NotificationContainer,
    NotificationAlert: NotificationAlert
}