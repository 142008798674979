import styled from "styled-components"

export const OffersImageBack = styled.div `

    width: 100%;
    max-width: 280px;
    aspect-ratio: 1 / 1;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: ${({theme}) => theme.colors.HOME_IMAGE_BACK};

`