import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const Separator = styled.p `

    margin: 0;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.TEXT_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};
    border: none;

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){

    display: none;

  }
    
`