import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const StudentActiveContainer = styled.div `

  width: 100%;
  height: calc(100vh - 80px);
  display: grid;
  grid-template-columns: 320px calc(100% - 320px);

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    grid-template-columns: 100%;
  }

`