import styled from "styled-components"

export const Container = styled.div `

  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(7px);
  z-index: 100;

`