import styled from "styled-components"

export const Container = styled.div `

  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: ${({theme}) => theme.colors.LIGHT_BACKGROUND};

`