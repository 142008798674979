import {styled} from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const Container = styled.div `

    width: fit-content;
    max-width: 350px;
    height: 70px;
    display: ${(props) => props.$isActive ? 'grid' : 'none'};
    grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
    grid-auto-flow: column;
    position: absolute;
    right: 25px;
    bottom: 10px;
    background-color: ${({theme}) => theme.colors.DARK_BACKGROUND};
    border-radius: 2px;
    z-index: 20;
    overflow: hidden;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        bottom: 10px;
        right: 10px;
        
    }

`