import {Fragment} from "react"

import LoginForm from "../../components/loginForm/LoginForm"
import {SignUpStyles} from "../../styles/pages/signUp"

const Login = () => {

    return(

        <Fragment>

            <SignUpStyles.Container>

                <LoginForm/>

            </SignUpStyles.Container>

        </Fragment>

    )

}

export default Login