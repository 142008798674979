import {Fragment} from "react"

import GenerateCode from "../../components/generateCode/GenerateCode"
import {SalesPanelStyles} from "../../styles/pages/salesPanel"

const SalesPanel = () => {

    return(

        <Fragment>

            <SalesPanelStyles.Container>

                <GenerateCode/>

            </SalesPanelStyles.Container>

        </Fragment>

    )

}

export default SalesPanel