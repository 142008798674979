import {Fragment} from "react"
import routes from "../../utils/constants/shared/routes.json"
import loginFormStrings from "../../assets/strings/loginFormStrings.json"
import fieldNames from "../../utils/constants/components/loginForm/fieldNames.json"
import errorStates from "../../utils/constants/shared/errorStates.json"
import useLoginForm from "./useLoginForm"

import {Text} from "../../styles/shared/Text"
import {LoginFormStyles} from "../../styles/components/loginForm"
import {Button} from "../../styles/shared/Button"

const LoginForm = () => {

    const{
        errors,
        handleChange,
        handleLogin
    } = useLoginForm()

    return(

        <Fragment>

            <LoginFormStyles.Container>

                <LoginFormStyles.FieldContainer>

                    <Text>{loginFormStrings.EMAIL}</Text>
                    <LoginFormStyles.Input onChange={handleChange} name={fieldNames.EMAIL}
                    $error={errors[fieldNames.EMAIL] === errorStates.WITH_ERROR}/>

                </LoginFormStyles.FieldContainer>

                <LoginFormStyles.FieldContainer>

                    <Text>{loginFormStrings.PASSWORD}</Text>
                    <LoginFormStyles.Input type="password" onChange={handleChange} name={fieldNames.PASSWORD}
                    $error={errors[fieldNames.PASSWORD] === errorStates.WITH_ERROR}/>

                </LoginFormStyles.FieldContainer>

                <LoginFormStyles.FieldContainer>

                    <Button onClick={handleLogin}>{loginFormStrings.BUTTON_TEXT}</Button>

                </LoginFormStyles.FieldContainer>

                <LoginFormStyles.FieldContainer>

                    <LoginFormStyles.ForgotPass to={routes.FORGOT_PASS}>
                        {loginFormStrings.FORGOT_PASSWORD}
                    </LoginFormStyles.ForgotPass>

                </LoginFormStyles.FieldContainer>


            </LoginFormStyles.Container>

        </Fragment>

    )

}

export default LoginForm