import styled from "styled-components"

export const NameContainer = styled.div`

    height: auto;
    width: auto;
    max-width: 60%;
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 15px 10px 0 0;
    justify-self: right;

`