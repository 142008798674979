import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'

import Router from "./pages/Router"
import './assets/fonts/articulat.otf'
import './assets/fonts/belgiano.ttf'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(

    <React.StrictMode>

        <Router/>

    </React.StrictMode>

)

reportWebVitals()