import styled from "styled-components"

export const Notification = styled.div `

    width: 90%;
    height: fit-content;
    justify-self: center;
    min-height: 35px;
    display: grid;
    grid-template-columns: 90% 10%;
    justify-items: center;
    align-items: center;
    border: 2px solid ${({theme}) => theme.colors.LOGIN_FORM_BORDER};
    padding: 10px 0;

`