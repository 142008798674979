import styled from "styled-components"

export const Container = styled.div `

    width: 100%;
    height: 90%;
    display: grid;
    grid-template-rows: 10% 90%;
    align-items: center;
    align-self: center;

`