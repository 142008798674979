import {createContext, useState} from "react"
import {createPortal} from "react-dom"
import NotificationsModal from "../../modals/notificationsModal/NotificationsModal"
import LoadingModal from "../../modals/loadingModal/LoadingModal"

export const ModalProviderContext = createContext(null)

const ModalProvider = ({children}) => {

    const [modal, setModal] = useState(null)

    /**
     * @param {string} text - main text of modal
     * @param {string} buttonText - button text of modal
     * @param {Array<String>} redirectUrl - if button click has to redirect array must contain a URL,
     * otherwise it should be empty
     * */

    const buildModal = (text, buttonText, redirectUrl) => {

        setModal(<NotificationsModal text={text} buttonText={buttonText} redirectUrl={redirectUrl}/>)

    }

    const buildLoadingModal = () => {

        setModal(<LoadingModal/>)

    }

    const value = {
        buildModal,
        buildLoadingModal,
        setModal
    }

    return(
        <ModalProviderContext.Provider value={value}>
            {children}
            {createPortal(modal, document.body)}
        </ModalProviderContext.Provider>
    )

}

export default ModalProvider