import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const QuizOptionsContainer = styled.div `

    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    justify-items: center;
    align-items: center;

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    justify-items: center;
  }

`