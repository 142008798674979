import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const CreateButton = styled.button `

    width: 120px;
    height: 40px;
    justify-self: center;
    background-color: ${({theme}) => theme.colors.PRIMARY};
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    color: ${({theme}) => theme.colors.LIGHT_TEXT};
    border: none;
    border-radius: 2px;
  
    &:hover{
      background-color: ${({theme}) => theme.colors.BUTTON_HOVER};
    }

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        width: 80px;
        height: 35px;
        border: none;
    }

`