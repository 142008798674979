import {Container} from "./Container"
import {Body} from "./Body"
import {Title} from "./Title"
import {Option} from "./Option"
import {OptionText} from "./OptionText"
import {OptionPrice} from "./OptionPrice"
import {Button} from "./Button"
import {Discount} from "./Discount"
import {PricesTable} from "./PricesTable"
import {PricesTableText} from "./PricesTableText"
import {PricesTableTotal} from "./PricesTableTotal"

export const PricingStyles = {
    Container: Container,
    Body: Body,
    Title: Title,
    Option: Option,
    OptionText: OptionText,
    OptionPrice: OptionPrice,
    Button: Button,
    Discount: Discount,
    PricesTable: PricesTable,
    PricesTableText: PricesTableText,
    PricesTableTotal: PricesTableTotal
}