import {Fragment, useRef} from "react"
import useAnimateObserver from "./useAnimateObserver"
import {AnimateObserverStyles} from "../../styles/components/animateObserver"

const AnimateObserver = ({children}) => {

    const ref = useRef()
    const hasBeenVisible = useAnimateObserver(ref, { threshold: 0.01 })

    return(

        <Fragment>

            <AnimateObserverStyles.Container ref={ref} $isVisible={hasBeenVisible}>

                {children}

            </AnimateObserverStyles.Container>

        </Fragment>

    )

}

export default  AnimateObserver