import {Fragment} from "react"
import errorStates from "../../utils/constants/shared/errorStates.json"
import useClaimCode from "./useClaimCode"
import claimCodeStrings from "../../assets/strings/claimCodeStrings.json"

import {ClaimCodeStyles} from "../../styles/components/claimCode"
import {Button} from "../../styles/shared/Button"
import {Text} from "../../styles/shared/Text"

const ClaimCode = ({setStudentActive}) => {

    const {
        codeError,
        handleChange,
        handleRedeem
    } = useClaimCode(setStudentActive)

    return(

        <Fragment>

            <ClaimCodeStyles.Container>

                <ClaimCodeStyles.FieldContainer>

                    <Text>{claimCodeStrings.FIELD_LABEL}</Text>

                    <ClaimCodeStyles.Input onChange={handleChange} $error={codeError === errorStates.WITH_ERROR}/>

                </ClaimCodeStyles.FieldContainer>

                <Button onClick={handleRedeem}>{claimCodeStrings.REDEEM_BUTTON}</Button>

            </ClaimCodeStyles.Container>

        </Fragment>

    )

}

export default ClaimCode