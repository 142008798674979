import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const QuizResultsContainer = styled.div `
    
    width: 100%;
    height: 50px;
    display: ${props => props.$isActive ? 'flex' : 'none'};
    flex-flow: row;
    gap: 30px;
    justify-items: center;
    justify-content: center;
    align-items: center;
    background-color: ${({theme}) => theme.colors.SECONDARY};

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    height: 45px;
  }

`