import styled, {keyframes} from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

const crescendo = keyframes `
  
    from{
        translate: -320px;
    }

    to{
        translate: 0;
    }
    
`

export const PricesTable = styled.div `

  width: 430px;
  display: ${props => props.$isOpen ? 'grid' : 'none'};
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
  align-items: center;
  justify-content: center;
  animation: ${crescendo} .3s alternate;

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    width: 90%;
  }

`