import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const FieldLabel = styled.p `

    justify-self: left;
    margin: 0;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.TEXT_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};

`