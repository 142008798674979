import styled from "styled-components"

export const TestimonialsFrame = styled.div `

    width: 310px;
    height: max-content;
    display: flex;
    flex-flow: column;
    align-items: center;

`