import {Fragment} from "react"
import useManagerMenu from "./useManagerMenu"
import userCards from '../../assets/images/managerMenu/userCards.svg'
import notifications from '../../assets/images/managerMenu/notifications.svg'
import reports from '../../assets/images/managerMenu/reports.svg'
import salesCodes from '../../assets/images/managerMenu/salesCodes.svg'
import interaction from '../../assets/images/managerMenu/interaction.svg'
import routes from '../../utils/constants/shared/routes.json'
import {ManagerMenuStyles} from "../../styles/components/managerMenu"

const ManagerMenu = () => {

    const {
        menuOpen,
        manager,
        sales,
        handleMenuOpenClose
    } = useManagerMenu()

    return(

        <Fragment>

            <ManagerMenuStyles.Container $isActive={manager || sales}>

                <ManagerMenuStyles.LinkContainer to={routes.MANAGER} $isActive={manager && menuOpen}>

                    <ManagerMenuStyles.Icon src={userCards}/>

                </ManagerMenuStyles.LinkContainer>

                <ManagerMenuStyles.LinkContainer to={routes.NOTIFICATIONS} $isActive={manager && menuOpen}>

                    <ManagerMenuStyles.Icon src={notifications}/>

                </ManagerMenuStyles.LinkContainer>

                <ManagerMenuStyles.LinkContainer to={routes.REPORTS} $isActive={manager && menuOpen}>

                    <ManagerMenuStyles.Icon src={reports}/>

                </ManagerMenuStyles.LinkContainer>

                <ManagerMenuStyles.LinkContainer to={routes.SALES_PANEL} $isActive={sales && menuOpen}>

                    <ManagerMenuStyles.Icon src={salesCodes}/>

                </ManagerMenuStyles.LinkContainer>

                <ManagerMenuStyles.InteractionIcon src={interaction} $isOpen={menuOpen}
                onClick={handleMenuOpenClose}/>

            </ManagerMenuStyles.Container>

        </Fragment>

    )

}

export default ManagerMenu