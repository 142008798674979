import styled from "styled-components"
import method from '../../../assets/images/home/method.webp'
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const MethodImage = styled.div `

    width: 75%;
    height: 100%;
    max-height: 473px;
    background-image: url(${method});
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 18px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.53);
    justify-self: right;
    align-self: center;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        width: 90%;
        max-width: 350px;
        max-height: 400px;
        height: calc(100vh - 260px);
        justify-self: center;
        align-self: center;
        margin-top: 50px;
        
    }

`