import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const MissionContainer = styled.div `
    
    max-width: 1100px;
    height: max-content;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 170px;
    align-self: center;
    justify-self: center;
    justify-items: center;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        width: 100%;
        max-width: 100%;
        grid-template-columns: unset;
        grid-row-gap: 50px;
        
    }

`