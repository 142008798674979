import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const LoadingAudio = styled.div `

    height: 40px;
    width: 300px;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: ${({theme}) => theme.colors.SECONDARY};
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    color: ${({theme}) => theme.colors.LIGHT_TEXT};
    border: none;
    border-radius: 2px;
    padding: 9px;
    
`