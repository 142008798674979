import styled from "styled-components"

export const HeaderContainer = styled.div `

    width: 100%;
    height: calc(100% - 2px);
    display: grid;
    grid-template-columns: 70% 30%;
    align-items: center;
    justify-items: center;
    border-bottom: 2px solid ${({theme}) => theme.colors.LOGIN_FORM_BORDER};

`