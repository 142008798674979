import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const Discount = styled.p `

  width: 100px;
  height: 26px;
  position: absolute;
  top: -13px;
  left: 0;
  margin: 0;
  align-self: center;
  text-align: center;
  font-family: ${fontValues.TEXT_FONT_FAMILY};
  font-size: ${fontValues.SUBTITLE_SIZE};
  color: ${({theme}) => theme.colors.LIGHT_TEXT};
  background-color: ${({theme}) => theme.colors.WHATS_APP_BUTTON};

`