import {useContext, useEffect, useState} from "react"
import {ModalProviderContext} from "../../providers/router/ModalProvider"
import {useLocation} from "react-router-dom"
import jwt_decode from "jwt-decode"
import cookieNames from "../../utils/constants/shared/cookieNames.json"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import NotificationsViewer from "../notificationsViewer/NotificationsViewer"
import navBarStrings from "../../assets/strings/navBarStrings.json"

const useNavBar = () => {

    const [currentPage, setCurrentPage] = useState(window.location.pathname)
    const [isOpen, setOpen] = useState(false)
    const [isLogged, setLogged] = useState(false)
    const [profileName, setProfileName] = useState('PROFILE')
    const [notifications, setNotifications] = useState([])
    const [hiddenNotifications, setHiddenNotifications] = useState([])
    const [notificationsViewer, setNotificationsViewer] = useState(null)
    const [notificationAlert, setNotificationAlert] = useState(false)
    const { buildModal } = useContext(ModalProviderContext)
    const location = useLocation()

    useEffect(() => {

        if(isLogged){

            getNotifications()
            getHiddenNotifications()

        }

    //eslint-disable-next-line
    }, [isLogged])

    useEffect(() => {

        manageNavigation()

    //eslint-disable-next-line
    }, [location])

    useEffect(() => {

        if(isLogged && notifications.length > 0) setAlert()

    //eslint-disable-next-line
    }, [hiddenNotifications, isLogged, notifications])

    const manageNavigation = () => {

        setCurrentPage(location.pathname)
        const token = localStorage.getItem(cookieNames.JWT_COOKIE)

        if(token !== null){

            if(!isLogged){

                const jwtDecoded = jwt_decode(token)
                setLogged(true)
                setProfileName((jwtDecoded.name).toUpperCase())

            }

        }else{

            setLogged(false)

        }

    }

    const handleClick = (e) => {

        setOpen(!isOpen)
        setCurrentPage(e.target.id)

    }

    const handleLogOut = () => {

        localStorage.removeItem(cookieNames.JWT_COOKIE)

    }

    const handleOpen = () => {

        setOpen(!isOpen)

    }

    const getNotifications = () => {

        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 5000)

        fetch(process.env.REACT_APP_API_URL + apiUrls.GET_NOTIFICATIONS, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            signal: controller.signal

        }).then(response => {

            response.json().then(data => {

                setNotifications(data)

            }).catch(e => {

                console.log(e)

            })

        }).catch(e => {

            console.log(e)

        })

    }

    const openNotificationsViewer = () => {

        if(isLogged){

            setNotificationAlert(false)
            setNotificationsViewer(<NotificationsViewer closeNotificationsViewer={closeNotificationsViewer}
            notifications={notifications} hiddenNotifications={hiddenNotifications}/>)

        }else{

            buildModal(navBarStrings.MODAL_UNAUTHORIZED, navBarStrings.MODAL_CLOSE, [])

        }

    }

    const closeNotificationsViewer = () => {

        setNotificationsViewer(null)

    }

    const getHiddenNotifications = () => {

        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 5000)

        fetch(process.env.REACT_APP_API_URL + apiUrls.GET_HIDDEN_NOTIFICATIONS, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            signal: controller.signal

        }).then(response => {

            response.json().then(data => {

                setHiddenNotifications(data)

            }).catch(e => {

                console.log(e)

            })

        }).catch(e => {

            console.log(e)

        })

    }

    const setAlert = () => {

        const temporaryFilteredNotifications = notifications.filter(obj1 => {

            return !hiddenNotifications.some(obj2 => {

                return parseInt(obj2.hiddenNotificationId) === parseInt(obj1.notificationId)

            })

        })

        if(temporaryFilteredNotifications.length > 0){

            setNotificationAlert(true)

        }

    }

    return{
        profileName,
        isLogged,
        currentPage,
        isOpen,
        notificationsViewer,
        notificationAlert,
        handleLogOut,
        handleClick,
        handleOpen,
        openNotificationsViewer
    }

}

export default useNavBar