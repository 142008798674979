import {Fragment} from "react"
import {UserProfileStyles} from "../../styles/pages/userProfile"

import ProfileViewer from "../../components/profileViewer/ProfileViewer"

const UserProfile = () => {

    return(

        <Fragment>

            <UserProfileStyles.Container>

                <ProfileViewer/>

            </UserProfileStyles.Container>

        </Fragment>

    )

}

export default UserProfile