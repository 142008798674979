import styled, {keyframes} from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(-100px); 
    }
    
    to {
        opacity: 1;
        transform: translateY(0);
    }
`

export const NotificationContainer = styled.button `

    width: 40px;
    height: 25px;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 0;
    background-color: transparent;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){

        animation: ${slideUp} 0.7s ease-out;

    }

`