import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const Input = styled.input `

    width: calc(100% - 20px);
    height: 45px;
    margin: 10px 0 0;
    padding: 0 10px;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.TEXT_SIZE};
    color: ${({theme}) => theme.colors.MEDIUM_TEXT};
    border: ${props => props.$hasError ? `2px solid red` : 'none'};
    background-color: white;
    border-radius: 2px;
    caret-color: ${({theme}) => theme.colors.DARK_TEXT};
  
    &:focus{
      outline: none;
    }
  
    &:-webkit-autofill{
      -webkit-text-fill-color: ${({theme}) => theme.colors.DARK_TEXT};
      transition: background-color 0s 50000s;
    }

`