import {Fragment} from "react"
import {HashRouter, Route, Routes} from "react-router-dom"
import {ThemeProvider} from "styled-components"
import {colorThemes} from "../assets/values/colorThemes/colorThemes.js"
import routes from "../utils/constants/shared/routes.json"
import paramNames from '../utils/constants/pages/router/paramNames.json'
import {RouterStyles} from "../styles/pages/router"
import ModalProvider from "../providers/router/ModalProvider"

import NavBar from "../components/navBar/NavBar"
import NotFound from "./notFound/NotFound"
import Home from "./home/Home"
import Login from "./login/Login"
import SignUp from "./signUp/SignUp"
import SalesPanel from "./salesPanel/SalesPanel"
import Manager from "./manager/Manager"
import MyLearningCenter from "./myLearningCenter/MyLearningCenter"
import Pricing from "./pricing/Pricing"
import Privacy from "./privacy/Privacy"
import Terms from "./terms/Terms"
import Cookies from "./cookies/Cookies"
import Courses from "./courses/Courses"
import UserProfile from "./userProfile/UserProfile"
import Notifications from "./notifications/Notifications"
import Reports from "./reports/Reports"
import ManagerMenu from "../components/managerMenu/ManagerMenu"
import RestorePass from "./restorePass/RestorePass"
import ForgotPass from "./forgotPass/ForgotPass"
import About from "./about/About"

const Router = () => {

    return(

        <Fragment>

            <ThemeProvider theme={colorThemes.mainTheme}>

                <HashRouter>

                    <RouterStyles.Container>

                        <ModalProvider>

                            <NavBar/>

                            <Routes>

                                <Route path={routes.HOME} element={<Home/>}/>

                                <Route path={routes.LOGIN} element={<Login/>}/>

                                <Route path={routes.SIGN_UP} element={<SignUp/>}/>

                                <Route path={routes.SALES_PANEL} element={<SalesPanel/>}/>

                                <Route path={routes.MANAGER} element={<Manager/>}/>

                                <Route path={routes.MY_LEARNING_CENTER} element={<MyLearningCenter/>}/>

                                <Route path={routes.PRICING} element={<Pricing/>}/>

                                <Route path={routes.PRIVACY} element={<Privacy/>}/>

                                <Route path={routes.TERMS} element={<Terms/>}/>

                                <Route path={routes.COOKIES} element={<Cookies/>}/>

                                <Route path={routes.COURSES_LIST} element={<Courses/>}/>

                                <Route path={routes.PROFILE} element={<UserProfile/>}/>

                                <Route path={routes.NOTIFICATIONS} element={<Notifications/>}/>

                                <Route path={routes.REPORTS} element={<Reports/>}/>

                                <Route path={routes.FORGOT_PASS} element={<ForgotPass/>}/>

                                <Route path={routes.ABOUT} element={<About/>}/>

                                <Route path={routes.RESTORE_PASS}>
                                    <Route path={`:${paramNames.TOKEN}`} element={<RestorePass/>}/>
                                </Route>

                                <Route path="*" element={<NotFound/>}/>

                            </Routes>

                            <ManagerMenu/>

                        </ModalProvider>

                    </RouterStyles.Container>

                </HashRouter>

            </ThemeProvider>

        </Fragment>

    )

}

export default Router