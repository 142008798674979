import styled from "styled-components"

export const Body = styled.div `

    width: 100%;
    height: 100%;
    display: grid;
    align-self: center;
    justify-items: center;
    align-items: center;
    background-color: ${props => props.theme.colors.SECONDARY};

`