import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const Container = styled.section `

    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100px calc(100% - 100px);
    align-items: center;
    justify-items: center;
    background-color: ${({theme}) => theme.colors.LIGHT_BACKGROUND};
    z-index: 4;
  
    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        grid-template-columns: 80px calc(100% - 160px) 80px;
    }
    
`