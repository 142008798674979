import {Container} from "./Container"
import {Card} from "./Card"
import {NotificationsContainer} from "./NotificationsContainer"
import {Notification} from "./Notification"
import {CloseButton} from "./CloseButton"
import {Text} from "./Text"

export const NotificationsViewerStyles= {
    Container,
    Card,
    NotificationsContainer,
    Notification,
    CloseButton,
    Text
}