import styled from "styled-components"

export const TextContainer = styled.div `

    width: 90%;
    height: calc(100% - 10px);
    display: grid;
    grid-template-rows: calc(100% - 20px) 20px;
    row-gap: 10px;
    padding: 0 10px;

`