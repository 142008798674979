import styled from "styled-components"

export const Container = styled.div `

    width: fit-content;
    height: max-content;
    display: grid;
    transform: ${props => props.$isVisible ? 'translateY(0px)' : 'translateY(200px)'};
    opacity: ${props => props.$isVisible ? '1' : '0'};
    transition: opacity 0.5s ease-out, transform 1s ease-out;

`