import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const LinksContainer = styled.div `
  
    height: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 25px;
    justify-content: center;
    align-items: center;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    
        display: flex;
        width: max-content;
        flex-flow: column;
        gap: 10px;
    
    }

`