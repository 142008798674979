import {Fragment} from "react"
import useMyLearningCenter from "./useMyLearningCenter"
import myLearningCenterStrings from "../../assets/strings/myLearningCenterStrings.json"
import viewStates from "../../utils/constants/pages/myLearningCenter/viewStates.json"

import {MyLearningCenterStyles} from "../../styles/pages/myLearningCenter"
import {Text} from "../../styles/shared/Text"
import ClaimCode from "../../components/claimCode/ClaimCode"
import ProgressView from "../../components/progressView/ProgressView"
import CourseView from "../../components/courseView/CourseView"

const MyLearningCenter = () => {

    const {
        view,
        setCourseView,
        studentStatus,
        setStudentActive
    } = useMyLearningCenter()

    const getCourseView = () => {

        if(view === viewStates.PROGRESS_VIEW) return <ProgressView setCourse={setCourseView}/>
        else return <CourseView/>

    }

    const studentInactiveView = (

        <Text>{myLearningCenterStrings.INACTIVE_USER}</Text>

    )

    const noCodeStudentView = (

        <ClaimCode setStudentActive={setStudentActive}/>

    )

    const noLoggedView = (

        <Text>{myLearningCenterStrings.NO_LOGGED_VIEW}</Text>

    )

    const views = {
        [viewStates.ACTIVE_STUDENT]: getCourseView(),
        [viewStates.INACTIVE_STUDENT]: studentInactiveView,
        [viewStates.NO_CODE_STUDENT]: noCodeStudentView,
        [viewStates.NO_LOGGED]: noLoggedView
    }

    return(

        <Fragment>

            <MyLearningCenterStyles.Container>

                {views[studentStatus]}

            </MyLearningCenterStyles.Container>

        </Fragment>

    )

}

export default MyLearningCenter