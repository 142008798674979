import {createContext, useState} from "react"

export const SelectorProviderContext = createContext(null)

const SelectorProvider = ({children}) => {

    const initialPosition = {
        activity: 1,
        level: 0,
        module: 0,
        class: 0
    }

    const[isOpen, setOpen] = useState(false)
    const[position, setPosition] = useState(initialPosition)
    const[completed, setCompleted] = useState(false)

    const value = {
        isOpen,
        position,
        completed,
        setOpen,
        setPosition,
        setCompleted
    }

    return(
        <SelectorProviderContext.Provider value={value}>
            {children}
        </SelectorProviderContext.Provider>
    )

}

export default SelectorProvider