import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const QuizOption = styled.button `

    width: 80%;
    height: 80%;
    background-color: ${({theme}) => theme.colors.DARK_BACKGROUND};
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    color: ${({theme}) => theme.colors.DARK_TEXT};
    border: none;
    border-radius: 2px;
    
    &:hover{
      background-color: ${({theme}) => theme.colors.BUTTON_SECONDARY_HOVER};
    }

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    width: 90%;
    height: 80%;
  }
    
`