import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const NotificationText = styled.p `

    width: 100%;
    margin: 0;
    text-align: justify;
    white-space: break-spaces;
    word-wrap: break-word;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.TEXT_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        max-width: 230px;
        
    }

`