import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const OptionPrice = styled.p `

  margin: 0;
  align-self: center;
  text-align: center;
  font-family: ${fontValues.TEXT_FONT_FAMILY};
  font-size: ${fontValues.TITLE_SIZE};
  color: ${({theme}) => theme.colors.DARK_TEXT};
    
`