import styled from "styled-components"

export const PrivilegesContainer = styled.div`

    width: 95%;
    height: 30px;
    display: grid;
    grid-auto-flow: column;
    align-self: end;
    justify-self: center;
    justify-items: center;
    align-items: center;
    margin-bottom: 4px;
    border-top: 2px solid ${({theme}) => theme.colors.LOGIN_FORM_BORDER};

`