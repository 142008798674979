export const colorThemes = {

    mainTheme: {

        colors: {

            LIGHT_BACKGROUND: '#FFFFFF',
            DARK_BACKGROUND: '#EFEEEC',
            PRIMARY: '#004976',
            SECONDARY: '#8A4FFF',
            TERTIARY: '#B1C0BC',
            ALTER: '#DA291C',
            LIGHT_TEXT: '#ffffff',
            DARK_TEXT: '#2e2e2e',
            MEDIUM_TEXT: '#525252',
            NAV_LINK_HOVER: '#003656',
            NAV_LINK_ACTIVE: '#004976',
            LOGIN_FORM_BORDER: '#b5b5b5',
            BUTTON_HOVER: '#003656',
            BUTTON_SECONDARY_HOVER: '#9562ff',
            LIST_EMPHASIS: '#D9D9D9',
            WHATS_APP_BUTTON: '#00cd1f',
            QUIZ_INCORRECT_OPTION: '#DA291C',
            QUIZ_CORRECT_OPTION: '#00cd1f',
            LEVEL_COMPLETED: '#dadada',
            HOME_PRIMARY: '#5E17EB',
            HOME_HOVER: '#FEC91A',
            HOME_IMAGE_BACK: '#EBEDFC',
            HOME_METHOD: '#5e17eb40',
            MINDSET_FRAME_COLOR: '#EBEDFC'

        }

    }

}