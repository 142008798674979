import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const ResponseInput = styled.textarea `
  
    width: calc(100% - 14px);
    height: calc(100% - 14px);
    margin: 0;
    padding: 5px;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.TEXT_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};
    background-color: transparent;
    border: 2px solid ${({theme}) => theme.colors.LOGIN_FORM_BORDER};
    caret-color: ${({theme}) => theme.colors.DARK_TEXT};
    display: ${props => props.$isActive ? 'block' : 'none'};
  
    &:focus{
      outline: none;
    }
  
    &:-webkit-autofill{
      -webkit-text-fill-color: ${({theme}) => theme.colors.DARK_TEXT};
      transition: background-color 0s 50000s;
    }

`