import styled from "styled-components"

export const PrivilegeField = styled.div`

    width: auto;
    height: 100%;
    display: grid;
    grid-auto-flow: column;
    column-gap: 5px;
    align-items: center;
    
`