import styled from "styled-components"
import darkLogo from '../../../assets/images/shared/darkLogo.svg'

export const Logo = styled.div `

    width: 168px;
    height: 84px;
    display: grid;
    background-image: url(${darkLogo});
    background-size: contain;
    background-repeat: no-repeat;

`