import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const InfoContainer = styled.div `

    width: 90%;
    height: fit-content;
    display: grid;
    row-gap: 10px;
    align-items: center;
    justify-items: center;

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){

    grid-auto-flow: row;
    grid-row-gap: 40px;

  }

`