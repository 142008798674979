import {Fragment} from "react"
import {GenerateCodeStyles} from "../../styles/components/generateCode"
import {Button} from "../../styles/shared/Button"
import useGenerateCode from "./useGenerateCode"
import generateCodeStrings from "../../assets/strings/generateCodeStrings.json"

import {Text} from "../../styles/shared/Text"

const GenerateCode = () => {

    const {
        code,
        handleGenerateCode
    } = useGenerateCode()

    return(

        <Fragment>

            <GenerateCodeStyles.Container>

                <GenerateCodeStyles.CodeBox>

                    <Text>{code}</Text>

                </GenerateCodeStyles.CodeBox>

                <Button onClick={handleGenerateCode}>{generateCodeStrings.GENERATE_BUTTON}</Button>

            </GenerateCodeStyles.Container>

        </Fragment>

    )

}

export default GenerateCode