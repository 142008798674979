import styled from "styled-components"

export const CodeBox = styled.div `

    width: 80%;
    height: 70px;
    display: grid;
    justify-items: center;
    align-items: center;
    border: 2px solid ${({theme}) => theme.colors.LOGIN_FORM_BORDER};
    border-radius: 2px;

`