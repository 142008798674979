import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const Container = styled.div `

    width: 450px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid ${({theme}) => theme.colors.LOGIN_FORM_BORDER};
    border-radius: 2px;
  
    @media(max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
      width: 100%;
      height: 100%;
      border: none;
      overflow-y: scroll;
      overflow-x: scroll;
    }

`