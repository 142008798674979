import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const NavigationButton = styled.button `

  width: 150px;
  height: 50px;
  justify-self: center;
  background-color: ${({theme}) => theme.colors.SECONDARY};
  font-family: ${fontValues.TEXT_FONT_FAMILY};
  color: ${({theme}) => theme.colors.LIGHT_TEXT};
  border: none;
  border-radius: 2px;
  display: ${props => props.$isActive ? 'block' : 'none'};

  &:hover{
    background-color: ${({theme}) => theme.colors.BUTTON_SECONDARY_HOVER};
  }

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    width: 100px;
    height: 45px;
  }
    
`