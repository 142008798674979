import {Fragment} from "react"
import {PrivacyStyles} from "../../styles/pages/privacy"
import termsStrings from "../../assets/strings/termsStrings.json"

const Terms = () => {

    return(

        <Fragment>

            <PrivacyStyles.Container>

                <PrivacyStyles.TextContainer>

                    <PrivacyStyles.Title>
                        {termsStrings.TITLE}
                    </PrivacyStyles.Title>

                    <PrivacyStyles.Text>
                        {termsStrings.P1}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>
                        {termsStrings.SUBTITLE1}
                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {termsStrings.P2}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>
                        {termsStrings.SUBTITLE2}
                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {termsStrings.P3}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>
                        {termsStrings.SUBTITLE3}
                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {termsStrings.P4}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>
                        {termsStrings.SUBTITLE4}
                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {termsStrings.P5}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>
                        {termsStrings.SUBTITLE5}
                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {termsStrings.P6}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>
                        {termsStrings.SUBTITLE6}
                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {termsStrings.P7}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>
                        {termsStrings.SUBTITLE7}
                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {termsStrings.P8}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>
                        {termsStrings.SUBTITLE8}
                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {termsStrings.P9}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>
                        {termsStrings.SUBTITLE9}
                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {termsStrings.P10}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>
                        {termsStrings.SUBTITLE10}
                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {termsStrings.P11}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>
                        {termsStrings.SUBTITLE11}
                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {termsStrings.P12}
                    </PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>

                    </PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>
                        {termsStrings.P13}
                    </PrivacyStyles.Text>

                </PrivacyStyles.TextContainer>

            </PrivacyStyles.Container>

        </Fragment>

    )

}

export default Terms