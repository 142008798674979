import styled from "styled-components"

export const ResultField = styled.div `

    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;

`