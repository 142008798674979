import styled from "styled-components"

export const SocialLinks = styled.div `

    width: fit-content;
    max-width: 300px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
    column-gap: 15px;
    align-items: center;
    justify-items: center;
    align-self: center;
    
`