import styled from "styled-components"

export const TopicsContainer = styled.div `

    width: 100%;
    height: max-content;
    display: grid;
    grid-auto-flow: row;
    background-color: ${({theme}) => theme.colors.HOME_METHOD};

`