import styled, {keyframes} from "styled-components"
import {NavLink} from "react-router-dom"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(-100px); 
    }
    
    to {
        opacity: 1;
        transform: translateY(0);
    }
`

export const PrincipalLink = styled(NavLink) `

    margin: 0;
    font-size: ${fontValues.PRINCIPAL_LINK_SIZE};
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    color: ${props => props.$isActive ?
            ({theme}) => theme.colors.NAV_LINK_ACTIVE : ({theme}) => theme.colors.DARK_TEXT};
    text-decoration: none;
  
    @media (hover: hover) and (pointer: fine) {
        
      &:hover{
        color: ${({theme}) => theme.colors.NAV_LINK_HOVER};
      }
        
    }

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){

        animation: ${slideUp} 0.7s ease-out;
        
    }

`