import {Fragment} from "react"
import privacyStrings from "../../assets/strings/privacyStrings.json"
import {PrivacyStyles} from "../../styles/pages/privacy"

const Privacy = () => {

    return(

        <Fragment>

            <PrivacyStyles.Container>

                <PrivacyStyles.TextContainer>

                    <PrivacyStyles.Title>{privacyStrings.TITLE}</PrivacyStyles.Title>

                    <PrivacyStyles.Text>{privacyStrings.P1}</PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>{privacyStrings.SUBTITLE1}</PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>{privacyStrings.P2}</PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>{privacyStrings.SUBTITLE2}</PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>{privacyStrings.P3}</PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>{privacyStrings.SUBTITLE3}</PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>{privacyStrings.P4}</PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>{privacyStrings.SUBTITLE4}</PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>{privacyStrings.P5}</PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>{privacyStrings.SUBTITLE5}</PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>{privacyStrings.P6}</PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>{privacyStrings.SUBTITLE6}</PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>{privacyStrings.P7}</PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>{privacyStrings.SUBTITLE7}</PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>{privacyStrings.P8}</PrivacyStyles.Text>

                    <PrivacyStyles.Subtitle>{privacyStrings.SUBTITLE8}</PrivacyStyles.Subtitle>

                    <PrivacyStyles.Text>{privacyStrings.P9}</PrivacyStyles.Text>

                    <PrivacyStyles.Text>{privacyStrings.P10}</PrivacyStyles.Text>

                </PrivacyStyles.TextContainer>

            </PrivacyStyles.Container>

        </Fragment>

    )

}

export default Privacy