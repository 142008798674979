import styled, {keyframes} from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

const crescendo = keyframes `
  
    from{
        translate: -320px;
    }

    to{
        translate: 0;
    }
    
`

export const Container = styled.div `

    width: 100%;
    height: 100%;
    display: grid;
    overflow: auto;
    background-color: ${({theme}) => theme.colors.DARK_BACKGROUND};
    z-index: 3;

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    height: calc(100vh - 80px);
    width: 320px;
    display: ${props => props.$isOpen ? 'grid' : 'none'};
    position: absolute;
    animation: ${crescendo} .3s alternate;
  }
    
`