import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const IntroContainer =  styled.div `

    width: 1100px;
    height: max-content;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-items: center;
    justify-self: center;
    padding: 100px 0 50px 0;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        width: 100%;
        grid-template-columns: unset;
        padding: 50px 0 50px 0;
        
    }

`