import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const Option = styled.button `

    width: 430px;
    height: 100px;
    display: grid;
    position: relative;
    grid-template-columns: 60% 40%;
    align-items: center;
    justify-content: center;
    background-color: ${({theme}) => theme.colors.DARK_BACKGROUND};
    border: none;

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    width: 90%;
  }

`