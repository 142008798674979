import styled from "styled-components"
import {Link} from "react-router-dom"

export const LinkContainer = styled(Link) `

    width: 35px;
    height: 35px;
    justify-self: center;
    align-self: center;
    display: ${(props) => props.$isActive ? 'grid' : 'none'};

`