import {Container} from "./Container"
import {ContentTitle} from "./ContentTitle"
import {TranscriptionContainer} from "./TanscriptionContainer"
import {InstructionsContainer} from "./InstructionsContainer"
import {NavigationButton} from "./NavigationButton"
import {ResponseInput} from "./ResponseInput"
import {InstructionsTitle} from "./InstructionsTitle"
import {InstructionsTitleActivity} from "./InstructionsTitleActivity"
import {LevelOpenButton} from "./LevelOpenButton"

import {Audio} from "./Audio"
import {ButtonsContainer} from "./ButtonsContainer"
import {AudioContainer} from "./AudioContainer"
import {LoadingAudio} from "./LoadingAudio"
import {LoadingAudioText} from "./LoadingAudioText"

import {QuizContainer} from "./QuizContainer"
import {QuizField} from "./QuizField"
import {QuizOptionsContainer} from "./QuizOptionsContainer"
import {QuizResultsContainer} from "./QuizResultsContainer"
import {QuizOption} from "./QuizOption"
import {QuizResult} from "./QuizResult"
import {InstructionsTitlesContainer} from "./InstructionsTitlesContainer"

export const ActivityViewerStyles = {
    Container: Container,
    ContentTitle:ContentTitle,
    TranscriptionContainer: TranscriptionContainer,
    InstructionsContainer: InstructionsContainer,
    NavigationButton: NavigationButton,
    ResponseInput: ResponseInput,
    InstructionsTitle: InstructionsTitle,
    InstructionsTitleActivity: InstructionsTitleActivity,
    InstructionsTitlesContainer: InstructionsTitlesContainer,
    LevelOpenButton: LevelOpenButton,

    Audio: Audio,
    LoadingAudio: LoadingAudio,
    ButtonsContainer: ButtonsContainer,
    AudioContainer: AudioContainer,
    LoadingAudioText: LoadingAudioText,

    QuizContainer: QuizContainer,
    QuizField: QuizField,
    QuizOptionsContainer: QuizOptionsContainer,
    QuizResultsContainer: QuizResultsContainer,
    QuizResult: QuizResult,
    QuizOption: QuizOption
}