import styled from "styled-components"

export const ButtonsContainer = styled.div `

    width: 100%;
    height: 100%;
    display: flex;
    grid-auto-flow: column;
    gap: 20px;
    justify-content: end;
    
`