import styled from "styled-components"
import wave from '../../../assets/images/about/margin.svg'
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const TopicsMargin = styled. div `

    width: 100%;
    height: 90px;
    background-image: url(${wave});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top right;
    transform: ${props => props.$isTop ? 'rotate(180deg)': 'none'};

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){

        background-size: 200%;
        background-position: center;
        
    }

`