import {Fragment} from "react"
import {ManagerStyles} from "../../styles/pages/manager"

import UserCardsFrame from "../../components/userCardsFrame/UserCardsFrame"

const Manager = () => {

    return(

        <Fragment>

            <ManagerStyles.Container>

                <UserCardsFrame/>

            </ManagerStyles.Container>

        </Fragment>

    )

}

export default Manager