import {useEffect, useState} from "react"
import {NotificationsViewerStyles} from "../../styles/components/notificationsViewer"
import containerName from "../../utils/constants/components/notificationsViewer/containerName.json"
import jwtDecode from "jwt-decode"
import cookieNames from "../../utils/constants/shared/cookieNames.json"
import apiUrls from "../../utils/constants/api/apiUrls.json"

const useNotificationsViewer = (closeNotificationsViewer, notifications, hiddenNotifications) => {

    const [notificationCards, setNotificationCards] = useState(null)
    const [filteredNotifications, setFilteredNotifications] = useState(null)

    useEffect(() => {

        filterNotifications()

    //eslint-disable-next-line
    }, [notifications])

    useEffect(() => {

        if(filteredNotifications) generateCards()

    //eslint-disable-next-line
    }, [filteredNotifications])

    const filterNotifications = () => {

        const savedHiddenNotifications = [...hiddenNotifications]

        if(savedHiddenNotifications.length > 0){

            const temporaryFilteredNotifications = notifications.filter(obj1 => {

                return !savedHiddenNotifications.some(obj2 => {

                    return parseInt(obj2.hiddenNotificationId) === parseInt(obj1.notificationId)

                })

            })

            setFilteredNotifications(temporaryFilteredNotifications)

        }else{

            setFilteredNotifications(notifications)

        }

    }

    const generateCards = () => {

        const temporaryCards = []

        for (const element in filteredNotifications) {

            temporaryCards.push(

                <NotificationsViewerStyles.Notification key={filteredNotifications[element].notificationId}>

                    <NotificationsViewerStyles.Text>
                        {filteredNotifications[element].text}
                    </NotificationsViewerStyles.Text>

                    <NotificationsViewerStyles.CloseButton id={filteredNotifications[element].notificationId}
                    onClick={hiddeNotification}/>

                </NotificationsViewerStyles.Notification>

            )

        }

        setNotificationCards(temporaryCards)

    }

    const hiddeNotification = (e) => {

        const {id} = e.currentTarget
        const temporaryHiddenNotifications = [...hiddenNotifications]
        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const userId = jwtDecode(jwt).userId

        const newHiddenNotification = {
            hiddenNotificationId: id,
            userId: userId
        }

        temporaryHiddenNotifications.push(newHiddenNotification)

        const temporaryFilteredNotifications = filteredNotifications.filter(obj => {
            return obj.notificationId !== parseInt(id)
        })

        setFilteredNotifications(temporaryFilteredNotifications)
        saveHiddenNotification(id)

    }

    const saveHiddenNotification = (notificationId) => {

        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 5000)

        fetch(process.env.REACT_APP_API_URL + apiUrls.SAVE_HIDDEN_NOTIFICATIONS, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            body: JSON.stringify({
                notificationId: notificationId
            }),
            signal: controller.signal

        }).catch(e => {

            console.log(e)

        })

    }

    const closeViewer = (e) => {

        if(e.target.id === containerName.CONTAINER_NAME){

            closeNotificationsViewer()

        }

    }

    return{
        notificationCards,
        closeViewer
    }

}

export default useNotificationsViewer