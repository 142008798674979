import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"
import {NavLink} from "react-router-dom"

export const AppName = styled(NavLink) `
  
    font-family: ${fontValues.TITLE_FONT_FAMILY};
    font-size: ${fontValues.TITLE_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};
    text-decoration: none;
    justify-self: left;
  
    @media(min-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
      display: none;
    }

`