import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const OptionsContainer = styled.div `

    width: max-content;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){

    width: 100%;
    grid-template: none;
    grid-row-gap: 30px;
    
  }

`