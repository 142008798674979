import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const MethodText = styled.div `
    
    max-width: 450px;
    margin: 30px 0 0 0;
    color: ${({theme}) => theme.colors.DARK_TEXT};
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.HOME_TEXT3_SIZE};
    text-align: justify;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        width: 100%;
        max-width: 330px;
    }


`