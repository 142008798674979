import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const OffersFrame = styled.div `

    width: 100%;
    max-width: 300px;
    height: fit-content;
    display: grid;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        justify-content: center;
        align-items: center;
    }

`