import {Container} from "./Container"
import {HeaderContainer} from "./HeaderContainer"
import {CreateButton} from "./CreateButton"
import {NotificationContainer} from "./NotificationContainer"
import {NotificationText} from "./NotificationText"
import {NotificationsContainer} from "./NotificationsContainer"
import {TextContainer} from "./TextContainer"
import {CreationText} from "./CreationText"
import {TextArea} from "./TextArea"
import {DeleteButton} from "./DeleteButton"

export const ManageNotificationsStyles = {

    Container,
    HeaderContainer,
    CreateButton,
    NotificationContainer,
    NotificationText,
    NotificationsContainer,
    TextContainer,
    CreationText,
    TextArea,
    DeleteButton

}