import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const Title = styled.h1 `

    padding: 0;
    cursor: pointer;
    text-align: center;
    border: none;
    margin: 0 0 20px 0;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.MEDIUM_TEXT_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};

`