import styled from "styled-components"

export const NotificationsContainer = styled.div `

    width: 100%;
    height: max-content;
    max-height: calc(100% - 40px);
    display: grid;
    grid-template-rows: auto;
    row-gap: 20px;
    align-self: baseline;
    overflow: auto;
    padding: 20px 0;

`