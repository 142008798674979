import styled from "styled-components"
import closeButton from '../../../assets/images/shared/close.svg'

export const CloseButton = styled.button `

    height: 20px;
    width: 20px;
    padding: 0;
    border: none;
    background-image: url(${closeButton});
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    align-self: baseline;
    margin-top: 8px;

`