import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const LoadingAudioText = styled.p `

    margin: 0;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    color: ${({theme}) => theme.colors.LIGHT_TEXT};
    border: none;
    
`