import {useContext, useState} from "react"
import errorStates from "../../utils/constants/shared/errorStates.json"
import {ModalProviderContext} from "../../providers/router/ModalProvider"
import forgotRestorePassStrings from "../../assets/strings/forgotRestorePassStrings.json"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import routes from "../../utils/constants/shared/routes.json"

const useForgotPass = () => {

    const[email, setEmail] = useState('')
    const[error, setError] = useState(errorStates.INITIALIZED)
    const { buildModal, buildLoadingModal } = useContext(ModalProviderContext)

    const handleRecover = () => {

        if(validate()){

            buildLoadingModal()
            const controller = new AbortController()
            setTimeout(() => controller.abort(), 5000)

            fetch(process.env.REACT_APP_API_URL + apiUrls.FORGOT_PASS, {

                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    userEmail: email
                }),
                signal: controller.signal

            }).then(response => {

                switch (response.status) {

                    case 500:
                        buildModal(forgotRestorePassStrings.MODAL_SERVER_ERROR,
                            forgotRestorePassStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 404:
                        buildModal(forgotRestorePassStrings.MODAL_NOT_FOUND,
                            forgotRestorePassStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 503:
                        buildModal(forgotRestorePassStrings.MODAL_SERVICE_UNAVAILABLE,
                            forgotRestorePassStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 200:

                        buildModal(forgotRestorePassStrings.MODAL_EMAIL_SENT,
                            forgotRestorePassStrings.MODAL_CLOSE_BUTTON, [routes.LOGIN])
                        break

                    default:
                        buildModal(forgotRestorePassStrings.MODAL_UNKNOWN_ERROR,
                            forgotRestorePassStrings.MODAL_CLOSE_BUTTON, [])
                        break

                }

            }).catch(e => {

                console.log(e)
                buildModal(forgotRestorePassStrings.MODAL_FETCH_ERROR, forgotRestorePassStrings.MODAL_CLOSE_BUTTON, [])

            })

        }else{

            buildModal(forgotRestorePassStrings.MODAL_FIELD_ERROR, forgotRestorePassStrings.MODAL_CLOSE_BUTTON, [])

        }

    }

    const validate = () => {

        if(error !== errorStates.NO_ERROR){

            setError(errorStates.WITH_ERROR)
            return false

        }else{

            return true

        }

    }

    const handleBlur = (e) => {

        const {value} = e.target

        if(value.trim()){

            setError(errorStates.NO_ERROR)

        }else{

            setError(errorStates.WITH_ERROR)

        }

    }

    const handleChange = (e) => {

        const {value} = e.target

        setEmail(value.trim())

        handleBlur(e)

    }

    return{
        error,
        handleChange,
        handleBlur,
        handleRecover
    }

}

export default useForgotPass