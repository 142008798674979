import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const TestimonialsSectionContainer = styled.div `

    width: 100%;
    max-width: 1300px;
    height: fit-content;
    padding: 20px 0 30px 0;
    display: grid;
    grid-auto-flow: column;
    column-gap: 100px;
    justify-self: center;
    align-items: center;
    justify-content: center;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        grid-auto-flow: row;
        row-gap: 50px;
    }

`