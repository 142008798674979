import {useContext, useState} from "react"
import errorStates from "../../utils/constants/shared/errorStates.json"
import {ModalProviderContext} from "../../providers/router/ModalProvider"
import claimCodeStrings from "../../assets/strings/claimCodeStrings.json"
import cookieNames from "../../utils/constants/shared/cookieNames.json"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import routes from "../../utils/constants/shared/routes.json"

const useClaimCode = (setStudentActive) => {

    const[code, setCode] = useState('')
    const[codeError, setCodeError] = useState(errorStates.INITIALIZED)
    const { buildModal, buildLoadingModal } = useContext(ModalProviderContext)

    const handleRedeem = () => {

        if(validate()){

            buildLoadingModal()
            const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
            const controller = new AbortController()
            setTimeout(() => controller.abort(), 5000)

            fetch(process.env.REACT_APP_API_URL + apiUrls.CLAIM_CODE, {

                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwt
                },
                body: JSON.stringify({code: code}),
                signal: controller.signal

            }).then(response => {

                switch (response.status) {

                    case 400:
                        buildModal(claimCodeStrings.MODAL_BAD_REQUEST, claimCodeStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 401:
                        buildModal(claimCodeStrings.MODAL_UNAUTHORIZED, claimCodeStrings.MODAL_CLOSE_BUTTON,
                            [routes.LOGIN])
                        break

                    case 406:
                        buildModal(claimCodeStrings.MODAL_NOT_ACCEPTABLE, claimCodeStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 409:
                        buildModal(claimCodeStrings.MODAL_CONFLICT, claimCodeStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 500:
                        buildModal(claimCodeStrings.MODAL_SERVER_ERROR, claimCodeStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 200:
                        setStudentActive()
                        buildModal(claimCodeStrings.MODAL_OK, claimCodeStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    default:
                        buildModal(claimCodeStrings.MODAL_UNKNOWN_ERROR, claimCodeStrings.MODAL_CLOSE_BUTTON, [])
                        break

                }

            }).catch(() => {

                buildModal(claimCodeStrings.MODAL_FETCH_ERROR, claimCodeStrings.MODAL_CLOSE_BUTTON, [])

            })

        }else{

            buildModal(claimCodeStrings.MODAL_FIELD_ERROR, claimCodeStrings.MODAL_CLOSE_BUTTON, [])

        }

    }

    const validate = () => {

        if(codeError !== errorStates.NO_ERROR){

            setCodeError(errorStates.WITH_ERROR)
            return false

        }

        return true

    }

    const handleBlur = (e) => {

        const {value} = e.target

        if(value.trim().length !== 8){

            setCodeError(errorStates.WITH_ERROR)

        }else{

            setCodeError(errorStates.NO_ERROR)

        }

    }

    const handleChange = (e) => {

        const {value} = e.target
        setCode(value.trim())

        handleBlur(e)

    }

    return{
        codeError,
        handleChange,
        handleRedeem
    }

}

export default useClaimCode