import {Container} from "./Container"
import {Input} from "./Input"
import {FieldContainer} from "./FieldContainer"
import {ForgotPass} from "./ForgotPass"

export const LoginFormStyles = {
    Container: Container,
    Input: Input,
    FieldContainer: FieldContainer,
    ForgotPass: ForgotPass
}