import {Container} from "./Container"
import {Title} from "./Title"
import {Text} from "./Text"
import {Subtitle} from "./Subtitle"
import {TextContainer} from "./TextContainer"

export const PrivacyStyles = {
    Container: Container,
    Title: Title,
    Text: Text,
    Subtitle: Subtitle,
    TextContainer: TextContainer
}