import {useContext} from "react"
import {ModalProviderContext} from "../../providers/router/ModalProvider"
import routes from '../../utils/constants/shared/routes.json'

const useNotificationsModal = (redirectUrl) => {

    const {setModal} =useContext(ModalProviderContext)

    const handleClick = () => {

        if(redirectUrl.length !== 0){

            if(redirectUrl[0] === routes.RELOAD){

                window.location.reload()

            }else{

                setModal(null)
                const host = window.location.protocol + "//" + window.location.host
                window.location.replace(host + '/#' + redirectUrl)

            }

        }else{

            setModal(null)

        }

    }

    return{
        handleClick
    }

}

export default useNotificationsModal