import {Fragment} from "react"
import ForgotPassForm from "../../components/forgotPassForm/ForgotPassForm"

const ForgotPass = () => {

    return(

        <Fragment>

            <ForgotPassForm/>

        </Fragment>

    )

}

export default ForgotPass