import styled from "styled-components"
import {NavLink} from "react-router-dom"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const Privacy = styled(NavLink) `

    margin: 20px 0 0 0;
    justify-self: center;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.FORGOT_PASSWORD};
    color: ${({theme}) => theme.colors.LOGIN_FORM_BORDER};

`