import styled from "styled-components"

export const FieldContainer = styled.div `

    display: grid;
    row-gap: 20px;
    align-items: center;
    justify-items: center;
    width: 200px;
    margin: 20px 0;

`