import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const CarrouselText = styled.div `
    
    text-align: left;
    margin: 0;
    color: ${({theme}) => theme.colors.LIGHT_TEXT};
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.TEXT_SIZE};
    font-weight: lighter;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        width: 100%;
        min-width: 100%;
        font-size: ${fontValues.TEXT_SIZE};
    }

`