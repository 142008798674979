import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const Card = styled.div`

    width: 900px;
    height: 500px;
    display: grid;
    grid-template-rows: 20% 80%;
    border-radius: 2px;
    background-color: ${({theme}) => theme.colors.SECONDARY};

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        width: 100%;
        height: 90%;
        border: none;
        overflow: auto;
    }

`