import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const Input = styled.input `

    width: 100%;
    margin-top: 15px;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.TEXT_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};
    border: none;
    background-color: transparent;
    border-bottom: 2px solid ${props => props.$error ? 
            ({theme}) => theme.colors.ALTER : ({theme}) => theme.colors.LOGIN_FORM_BORDER};
    caret-color: ${({theme}) => theme.colors.DARK_TEXT};
  
    &:focus{
      outline: none;
    }
  
    &:-webkit-autofill{
      -webkit-text-fill-color: ${({theme}) => theme.colors.DARK_TEXT};
      transition: background-color 0s 50000s;
    }

`