import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const IntroTextContainer = styled.div`

    width: 440px;
    height: fit-content;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 30px;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        width: 100%;
        
    }

`