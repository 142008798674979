import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const ContentTitle = styled.h1 `

    width: 85%;
    justify-self: left;
    margin: 0;
    align-self: center;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.TITLE_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};

`