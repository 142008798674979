import {Fragment} from "react"
import forgotRestorePassStrings from "../../assets/strings/forgotRestorePassStrings.json"
import fieldNames from "../../utils/constants/components/restorePassForm/fieldNames.json"
import errorStates from "../../utils/constants/shared/errorStates.json"
import useRestorePassForm from "./useRestorePassForm"

import {LoginFormStyles} from "../../styles/components/loginForm"
import {Text} from "../../styles/shared/Text"
import {Button} from "../../styles/shared/Button"
import {RestorePassFormStyles} from "../../styles/components/restorePassForm"

const RestorePassForm = ({token}) => {

    const {
        errors,
        handleChange,
        handleBlur,
        handleRestore
    } = useRestorePassForm(token)

    return(

        <Fragment>

            <RestorePassFormStyles.Container>

                <LoginFormStyles.FieldContainer>

                    <Text>{forgotRestorePassStrings.RESTORE_LABEL}</Text>

                    <LoginFormStyles.Input type="password" onChange={handleChange} onBlur={handleBlur}
                   $error={errors[fieldNames.PWD] === errorStates.WITH_ERROR} name={fieldNames.PWD}/>

                </LoginFormStyles.FieldContainer>

                <LoginFormStyles.FieldContainer>

                    <Text>{forgotRestorePassStrings.RESTORE_CONFIRM_LABEL}</Text>

                    <LoginFormStyles.Input type="password" onChange={handleChange} onBlur={handleBlur}
                    $error={errors[fieldNames.CONFIRM_PWD] === errorStates.WITH_ERROR} name={fieldNames.CONFIRM_PWD}/>

                </LoginFormStyles.FieldContainer>

                <Button onClick={handleRestore}>{forgotRestorePassStrings.RESTORE_BUTTON}</Button>

            </RestorePassFormStyles.Container>

        </Fragment>

    )

}

export default RestorePassForm