import styled from "styled-components"

export const SmallSectionContainer = styled.div `

    width: 100%;
    height: 350px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: ${({theme}) => theme.colors.HOME_PRIMARY};

`