import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const IntroText = styled.p `
    
    max-width: 980px;
    margin: 0;
    text-align: justify;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.FOOTER_TEXT};
    color: ${({theme}) => theme.colors.DARK_TEXT};
    line-height: 1.8;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        max-width: 85%;
    }

`