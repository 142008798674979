import styled, {keyframes} from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

const crescendo = keyframes `
  
    from{
        transform: scale(.1);
    }

    to{
        transform: scale(1);
    }
    
`

export const Card = styled.div `

  width: 80%;
  max-width: 400px;
  height: 75%;
  display: grid;
  grid-template-rows: 85% 15%;
  align-items: center;
  justify-items: center;
  border-radius: 10px;
  padding: 20px 10px;
  animation: ${crescendo} .2s alternate;

  background-color: ${({theme}) => theme.colors.LIGHT_BACKGROUND};
  border: 1px solid ${({theme}) => theme.colors.LOGIN_FORM_BORDER};

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    
      width: 80%;

  }

`