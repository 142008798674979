import {Fragment} from "react"
import {CourseViewStyles} from "../../styles/components/courseView"
import LevelSelector from "../levelSelector/LevelSelector"
import ActivityViewer from "../activityViewer/ActivityViewer"
import SelectorProvider from "../../providers/CourseView/SelectorProvider"

const CourseView = () => {

    return(

        <Fragment>

            <SelectorProvider>

                <CourseViewStyles.StudentActiveContainer>

                    <LevelSelector/>

                    <ActivityViewer/>

                </CourseViewStyles.StudentActiveContainer>

            </SelectorProvider>

        </Fragment>

    )

}

export default CourseView