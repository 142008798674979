import styled from "styled-components"
import notification from '../../../assets/images/navBar/notification.svg'

export const Notification = styled.span `

    height: 20px;
    width: 20px;
    right: 20px;
    border: none;
    background-image: url(${notification});
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    
`