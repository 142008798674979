import styled from "styled-components"
import {NavLink} from "react-router-dom"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const Link = styled(NavLink) `

    width: fit-content;
    margin: 0;
    font-size: ${fontValues.FOOTER_TEXT};
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    color: ${({theme}) => theme.colors.DARK_TEXT};
    text-decoration: none;

`