import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"
import closeBtn from "../../../assets/images/shared/close.svg"
import menu from "../../../assets/images/navBar/menu.svg"

export const Menu = styled.img `

    height: 35px;
    width: auto;
    content: ${props => props.$isOpen ? `url(${closeBtn})` : `url(${menu})`};
    z-index: 1;
    
    @media (min-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
      display: none;
    }

`