import styled from "styled-components"

export const SecondarySectionContainer = styled.div `

    width: 100%;
    height: max-content;
    min-height: 550px;
    display: grid;
    align-items: center;

`