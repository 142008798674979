import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const OffersContainer = styled.div `

    width: 90%;
    max-width: 1050px;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, minmax(300px, 1fr));
    column-gap: 50px;
    row-gap: 30px;
    padding: 5% 0;
    justify-self: center;
    justify-items: center;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){

        grid-template-columns: none;
        grid-template-rows: repeat(6, minmax(300px, 1fr));
        padding: 15% 0;
        align-items: center;
        
    }

`