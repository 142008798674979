import styled from "styled-components"

export const FieldsContainer = styled.div`

    width: auto;
    height: auto;
    display: grid;
    row-gap: 7px;
    justify-self: right;
    align-items: center;
    justify-items: right;
    margin: 10px 10px 0 0;
    overflow: auto;

`