import {Fragment} from "react"
import {WhatsAppButtonStyles} from "../../styles/components/whatsAppButton"
import socialLinks from "../../utils/constants/shared/socialLinks.json"

const WhatsAppButton = () => {

    return(

        <Fragment>

            <WhatsAppButtonStyles.Button href={socialLinks.TELEGRAM_APP_SUPPORT} target="_blank"/>

        </Fragment>

    )

}

export default WhatsAppButton