import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const AudioContainer = styled.div `
  
  width: fit-content;
  height: fit-content;
  display: ${props => props.$isActive ? 'flex' : 'none'};

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    width: 100%;
    justify-content: center;
  }

`