import {useContext, useEffect, useRef, useState} from "react"
import { useTheme } from 'styled-components'
import {SelectorProviderContext} from "../../providers/CourseView/SelectorProvider"
import {LevelSelectorStyles} from "../../styles/components/levelSelector"
import {ModalProviderContext} from "../../providers/router/ModalProvider"
import cookieNames from "../../utils/constants/shared/cookieNames.json"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import levelSelectorString from "../../assets/strings/levelSelectorStrings.json"

const useLevelSelector = () => {

    const[cards, setCards] = useState(null)
    const[completedClasses, setCompletedClasses] = useState(null)
    const { setPosition, position, isOpen, setOpen, completed, setCompleted } = useContext(SelectorProviderContext)
    const { buildModal } = useContext(ModalProviderContext)
    const lastElement = useRef(null)
    const theme = useTheme()

    useEffect(() => {

        getLevelsInfo()

    //eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(cards && completedClasses) markCompleted()

    //eslint-disable-next-line
    }, [cards, completedClasses])

    useEffect(() => {

        if(cards) markActive()

    //eslint-disable-next-line
    }, [position.level, position.module, position.class, cards])

    useEffect(() => {

        if(completed){

            setCompletedClasses(
                completedClasses.concat(`${position.level}.${position.module}.${position.class}`)
            )

            setCompleted(false)

        }

    //eslint-disable-next-line
    }, [completed])

    const getLevelsInfo = () => {

        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 15000)

        fetch(process.env.REACT_APP_API_URL + apiUrls.GET_LEVELS_INFO, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            signal: controller.signal

        }).then(response => {

            switch (response.status) {

                case 200:
                    generateLevels(response)
                    break

                case 400:
                    buildModal(levelSelectorString.MODAL_CLIENT_ERROR, levelSelectorString.MODAL_CLOSE_BUTTON, [])
                    break

                case 401:
                    buildModal(levelSelectorString.MODAL_UNAUTHORIZED, levelSelectorString.MODAL_CLOSE_BUTTON, [])
                    break

                case 500:
                    buildModal(levelSelectorString.MODAL_SERVER_ERROR, levelSelectorString.MODAL_CLOSE_BUTTON, [])
                    break

                default:
                    buildModal(levelSelectorString.MODAL_UNKNOWN_ERROR, levelSelectorString.MODAL_CLOSE_BUTTON, [])
                    break

            }

        }).catch(() => {

            buildModal(levelSelectorString.MODAL_FETCH_ERROR, levelSelectorString.MODAL_CLOSE_BUTTON, [])

        })

    }

    const generateLevels = (response) => {

        response.json().then(data => {

            const manifest = data.manifest
            const levelFields = []
            let levelIndex = 1

            for (const level in manifest) {

                const modules = manifest[level].MODULES
                const moduleFields = []
                let moduleIndex = 1

                for (const module in modules) {

                    const classes = modules[module].CLASSES
                    const classesFields = []
                    let classIndex = 1

                    for (const element in classes) {

                        classesFields.push(
                            <div key={level + module + element}
                                 id={`classContainer_level${levelIndex}_module${moduleIndex}_class${classIndex}`}>

                                <LevelSelectorStyles.ClassTitle
                                    id={`level${levelIndex}_module${moduleIndex}_class${classIndex}`}
                                    onClick={selectPosition}>
                                    {classes[element].TITLE}
                                </LevelSelectorStyles.ClassTitle>

                            </div>
                        )

                        classIndex++

                    }

                    moduleFields.push(
                        <div key={level + module} id={`level${levelIndex}_module${moduleIndex}`}>
                            <LevelSelectorStyles.ModuleTitle>
                                {modules[module].TITLE}
                            </LevelSelectorStyles.ModuleTitle>
                            {classesFields}
                        </div>
                    )

                    moduleIndex++

                }

                levelFields.push(
                    <div key={level + levelIndex} id={`level${levelIndex}`}>

                        <LevelSelectorStyles.LevelTitle>
                            {`NIVEL ${levelIndex}: ${manifest[level].TITLE}`}
                        </LevelSelectorStyles.LevelTitle>

                        {moduleFields}
                    </div>
                )

                levelIndex++

            }

            setCards(levelFields)
            setCompletedClasses(data.completedClasses)

        }).catch(() => {

            buildModal(levelSelectorString.MODAL_LOADING_DATA_ERROR, levelSelectorString.MODAL_CLOSE_BUTTON, [])

        })

    }

    const markCompleted = () => {

        if(completedClasses.length === 0){

            setPosition({
                ...position,
                level: 1,
                module: 1,
                class: 1
            })

        }else{

            for (const completedClass in completedClasses) {

                const position = completedClasses[completedClass].split('.')
                const level = position[0]
                const module = position[1]
                const classNumber = position[2]

                const containerId = `classContainer_level${level}_module${module}_class${classNumber}`
                const containerElement = document.getElementById(containerId)
                containerElement.style.backgroundColor = theme.colors.LEVEL_COMPLETED

                const buttonId = `level${level}_module${module}_class${classNumber}`
                const buttonElement = document.getElementById(buttonId)
                buttonElement.style.backgroundColor = theme.colors.LEVEL_COMPLETED

                if(completedClasses[completedClass] === completedClasses[completedClasses.length - 1]){

                    setPosition({
                        activity: 1,
                        level: level,
                        module: module,
                        class: classNumber
                    })

                }

            }

        }

    }

    const markActive = () => {

        if(position.level !== 0){

            if(lastElement.current){

                const element = document.getElementById(lastElement.current)
                element.style.color = theme.colors.DARK_TEXT

            }

            const elementId = `level${position.level}_module${position.module}_class${position.class}`
            const element = document.getElementById(elementId)
            element.style.color = theme.colors.SECONDARY
            lastElement.current = elementId

        }

    }

    const selectPosition = (e) => {

        const {id} = e.target
        const levels = {}
        const regex = /(\d+)/g
        const matches = id.match(regex)

        const [level, module, classNumber] = matches
        levels.level = parseInt(level)
        levels.module = parseInt(module)
        levels.class = parseInt(classNumber)

        setPosition({
            ...position,
            level: levels.level,
            module: levels.module,
            class: levels.class,
            activity: 1
        })

        setOpen(false)

    }

    return{
        isOpen,
        cards
    }

}

export default useLevelSelector