import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const LearningContainer = styled.div `

    width: 100%;
    height: 100%;
    display: grid;
    background-color: ${({theme}) => theme.colors.SECONDARY};
    overflow: hidden;
    position: relative;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
      
    }

`