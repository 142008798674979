import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const ViewContainer = styled.div `

    width: 100%;
    min-width: 1100px;
    height: max-content;
    display: grid;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){

        min-width: 100%;
        height: max-content;
        
    }

`