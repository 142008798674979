import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const Carrousel = styled.img `

    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 40%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: opacity 0.5s ease-in-out;
    opacity: ${props => (props.$fade ? 0 : 1)};

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        width: 100%;
        height: 100%;
        object-position: ${props => props.$isEven ? 'calc(40% - 5%) 40%' : 'calc(40% + 25%) 40%'};
    }

`