import {Fragment} from "react"
import ManageNotifications from "../../components/manageNotifications/ManageNotifications"

const Notifications = () => {

    return(

        <Fragment>

            <ManageNotifications/>

        </Fragment>

    )

}

export default Notifications