import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const ValuesColumnContainer = styled.div `

    width: 100%;
    height: max-content;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-items: center;
    margin-top: 25px;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        width: 85%;
        row-gap: 30px;
        grid-template-columns: unset;   
        
    }

`