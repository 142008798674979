import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const Subtitle = styled.h2 `

    margin: 40px 0 20px 0;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.SUBTITLE_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};

`