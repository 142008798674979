import {Container} from "./Container"
import {FieldContainer} from "./FieldContainer"
import {Input} from "./Input"
import {NumberField} from "./NumberField"
import {CellPhoneField} from "./CellPhoneField"
import {Privacy} from "./Privacy"

export const SignUpFormStyles = {
    Container: Container,
    FieldContainer: FieldContainer,
    Input: Input,
    NumberField: NumberField,
    CellPhoneField: CellPhoneField,
    Privacy: Privacy
}