import styled, {keyframes} from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

const appear = keyframes `
  
    from{
        opacity: 0;
    }

    to{
        opacity: 1;
    }
    
`

export const Button = styled.button `

    display: ${props => props.$isActive ? 'block' : 'none'};
    width: 150px;
    height: 50px;
    justify-self: right;
    margin-top: 30px;
    background-color: white;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    color: ${({theme}) => theme.colors.MEDIUM_TEXT};
    border: none;
    border-radius: 2px;
    animation: ${appear} .5s alternate;
  
    &:hover{
      background-color: ${({theme}) => theme.colors.LOGIN_FORM_BORDER};
    }

`