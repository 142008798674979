import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const SearchContainer = styled.div `

    width: calc(100% - 40px);
    height: 100%;
    display: grid;
    grid-template-columns: 40% 20% 20% 20%;
    align-items: center;
    justify-items: center;
    padding: 0 20px;

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    grid-template-columns: 34% 22% 22% 22%;
    width: calc(100% - 20px);
    padding: 0 10px;
  }
  
`