import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const Text = styled.p `

    width: 95%;
    margin: 0;
    justify-self: center;
    white-space: break-spaces;
    text-align: justify;
    word-break: break-word;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.TEXT_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};

`