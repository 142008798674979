import {useEffect, useRef, useState} from "react"
import {useLocation} from "react-router-dom"
import cookieNames from '../../utils/constants/shared/cookieNames.json'
import jwtDecode from "jwt-decode"

const useManagerMenu = () => {

    const [manager, setManager] = useState(false)
    const [sales, setSales] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const location = useLocation()
    const checked = useRef(false)

    useEffect(() => {

        checkPrivileges()

    //eslint-disable-next-line
    }, [location])

    const checkPrivileges = () => {

        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)

        if(jwt){

            if(!checked.current){

                const managerPrivilege = jwtDecode(jwt).manager
                const salesPrivilege = jwtDecode(jwt).sales
                checked.current = true

                if(managerPrivilege || salesPrivilege){

                    setManager(managerPrivilege)
                    setSales(salesPrivilege)

                }

            }

        }else if(checked.current){

            setManager(false)
            setSales(false)
            checked.current = false

        }

    }

    const handleMenuOpenClose = () => {

        setMenuOpen(!menuOpen)

    }

    return{
        menuOpen,
        manager,
        sales,
        handleMenuOpenClose
    }

}

export default useManagerMenu