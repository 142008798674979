import styled from "styled-components"
import fontValues from '../../../assets/values/fontValues/fontValues.json'

export const Title = styled.h1 `

    margin: 0 0 0 5%;
    align-self: center;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.TITLE_SIZE};
    color: ${({theme}) => theme.colors.LIGHT_TEXT};

`