import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const CreationText = styled.p `

    width: 100%;
    margin: 0;
    text-align: justify;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.SMALL_TEXT_SIZE};
    color: ${({theme}) => theme.colors.MEDIUM_TEXT};

`