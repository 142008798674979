import styled from "styled-components"
import {NavLink} from "react-router-dom"

export const Link = styled(NavLink) `

    width: 90%;
    height: 90%;
    display: grid;
    justify-items: center;
    align-items: center;
    margin: 0;
    text-decoration: none;

`