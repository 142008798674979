import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"
import menu from "../../../assets/images/myLearningCenter/menu.svg"

export const LevelOpenButton = styled.button `

  width: 45px;
  height: 45px;
  position: absolute;
  left: 21px;
  background-color: ${({theme}) => theme.colors.SECONDARY};
  background-image: url(${menu});
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 50%;
  display: none;

  &:hover{
    background-color: ${({theme}) => theme.colors.BUTTON_SECONDARY_HOVER};
  }

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    display: ${props => props.$isOpen ? 'block' : 'none'};
  }
    
`