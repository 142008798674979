import {Container} from "./Container"
import {CourseCard} from "./CourseCard"
import {Link} from "./Link"

export const CoursesStyles = {

    Container: Container,
    CourseCard: CourseCard,
    Link: Link

}