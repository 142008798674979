import {Container} from "./Container"
import {NameContainer} from "./NameContainer"
import {Name} from "./Name"
import {FieldsContainer} from "./FieldsContainer"
import {PrivilegesContainer} from "./PrivilegesContainer"
import {Privilege} from "./Privilege"
import {PrivilegeState} from "./PrivilegeState"
import {PrivilegeField} from "./PrivilegeField"
import {IconContainer} from "./IconContainer"
import {UserIcon} from "./UserIcon"

export const UserCardStyles = {
    Container,
    NameContainer,
    Name,
    FieldsContainer,
    PrivilegesContainer,
    Privilege,
    PrivilegeState,
    PrivilegeField,
    IconContainer,
    UserIcon
}