import {useContext, useEffect, useState} from "react"
import cookieNames from "../../utils/constants/shared/cookieNames.json"
import jwtDecode from "jwt-decode"
import {ModalProviderContext} from "../../providers/router/ModalProvider"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import userDetailsStrings from "../../assets/strings/userDetailsStrings.json"
import buttonNames from '../../utils/constants/components/userDetails/buttonNames.json'
import routes from '../../utils/constants/shared/routes.json'

const useUserDetails = (userId) => {

    const initialDetails = {
        name: '',
        email: '',
        countryCode: '',
        cellPhone: '',
        birthDate: '',
        country: '',
        registrationDate: '',
        manager: false,
        sales: false,
        courseProgress: '',
        averageQuizResults: '',
        completedClasses: '',
        lastClass: '',
        latestActivity: '',
        salesCode: '',
        claimedDate: '',
        activeStudent: ''
    }

    const { buildModal, buildLoadingModal, setModal } = useContext(ModalProviderContext)
    const[details, setDetails] = useState(initialDetails)
    const[confirmation, setConfirmation] = useState(false)
    const[confirmationText, setConfirmationText] = useState('')
    const[manageAction, setManageAction] = useState(null)

    useEffect(() => {

        getUserDetails()

    //eslint-disable-next-line
    }, [])

    const getUserDetails = () => {

        buildLoadingModal()
        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 20000)

        fetch(process.env.REACT_APP_API_URL + apiUrls.GET_USER_DETAILS, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            body: JSON.stringify({userId: userId}),
            signal: controller.signal

        }).then(response => {

            switch (response.status){

                case 400:
                    buildModal(userDetailsStrings.MODAL_BAD_REQUEST, userDetailsStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 401:
                    buildModal(userDetailsStrings.MODAL_UNAUTHORIZED, userDetailsStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 500:
                    buildModal(userDetailsStrings.MODAL_SERVER_ERROR, userDetailsStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 200:
                    response.json().then(data => {

                        manageDetails(data)

                    }).catch(e => {

                        console.log(e)
                        buildModal(userDetailsStrings.MODAL_DATA_ERROR, userDetailsStrings.MODAL_CLOSE_BUTTON, [])

                    })

                    break

                default:
                    buildModal(userDetailsStrings.MODAL_UNKNOWN_ERROR, userDetailsStrings.MODAL_CLOSE_BUTTON, [])
                    break

            }

        }).catch(e => {

            console.log(e)
            buildModal(userDetailsStrings.MODAL_FETCH_ERROR, userDetailsStrings.MODAL_CLOSE_BUTTON, [])

        })

    }

    const manageDetails = (data) => {

        if(data.length === 1  && data[0].classPosition === null){

            setDetails({
                name: data[0].name,
                email: data[0].email,
                countryCode: data[0].countryCode,
                cellPhone: data[0].cellPhone,
                birthDate: data[0].birthDate.split('T')[0],
                country: data[0].country,
                registrationDate: data[0].registrationDate.split('T')[0],
                manager: data[0].manager === 1,
                sales: data[0].sales === 1,
                courseProgress: 0,
                averageQuizResults: 0,
                completedClasses: 0,
                lastClass: 'NA',
                latestActivity: 'NA',
                salesCode: data[0].salesCode === null ? "NA" : data[0].salesCode,
                claimedDate: data[0].claimedDate === null ? "NA" : data[0].claimedDate.split('T')[0],
                activeStudent: data[0].student
            })

            setModal(false)

        }else{

            let quizResults = 0

            for (const element in data) {

                quizResults = quizResults + data[element].quizResult

            }

            const averageQuizResults = (quizResults / data.length).toFixed(2)
            const courseProgress = Math.round(2.8571 * data.length) //HARDCODED PERCENTAGE
            const lastClass = `Level ${data[data.length - 1].classPosition.split('.')[0]} 
                        Module ${data[data.length - 1].classPosition.split('.')[1]} 
                        Class ${data[data.length - 1].classPosition.split('.')[2]}`

            setDetails({
                name: data[0].name,
                email: data[0].email,
                countryCode: data[0].countryCode,
                cellPhone: data[0].cellPhone,
                birthDate: data[0].birthDate.split('T')[0],
                country: data[0].country,
                registrationDate: data[0].registrationDate.split('T')[0],
                manager: data[0].manager === 1,
                sales: data[0].sales === 1,
                courseProgress: courseProgress,
                averageQuizResults: averageQuizResults,
                completedClasses: data.length,
                lastClass: lastClass,
                latestActivity: data[data.length - 1].lastChange.split('T')[0],
                salesCode: data[0].salesCode,
                claimedDate: data[0].claimedDate.split('T')[0],
                activeStudent: data[0].student
            })

            setModal(false)

        }

    }

    const handleManageAccount = (e) => {

        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const managerId = jwtDecode(jwt).userId
        const {name} = e.target

        if(name === buttonNames.MANAGER && managerId.toString() === userId){

            buildModal(userDetailsStrings.CONFIRMATION_YOUR_OWN_MANAGER_PERMISSIONS,
                userDetailsStrings.MODAL_CLOSE_BUTTON, [])

        }else{

            switch (name) {

                case buttonNames.MANAGER:
                    setConfirmationText(userDetailsStrings.CONFIRMATION_MANAGER)
                    setManageAction(buttonNames.MANAGER)
                    break

                case buttonNames.SALES:
                    setConfirmationText(userDetailsStrings.CONFIRMATION_SALES)
                    setManageAction(buttonNames.SALES)
                    break

                case buttonNames.DEACTIVATE:
                    setConfirmationText(userDetailsStrings.CONFIRMATION_DEACTIVATE)
                    setManageAction(buttonNames.DEACTIVATE)
                    break

                case buttonNames.DELETE_ACCOUNT:
                    setConfirmationText(userDetailsStrings.CONFIRMATION_DELETE)
                    setManageAction(buttonNames.DELETE_ACCOUNT)
                    break

            }

            setConfirmation(true)

        }


    }

    const closeConfirmation = () => {

        setConfirmation(false)

    }

    const acceptConfirmation = () => {

        buildLoadingModal()
        setTimeout(() => {
            manageAccount()
        }, 5000)

    }

    const manageAccount = () => {

        let privilegeState

        switch (manageAction) {

            case buttonNames.MANAGER:
                privilegeState = details.manager
                break

            case buttonNames.SALES:
                privilegeState = details.sales
                break

            default:
                privilegeState = null
                break

        }

        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 10000)

        fetch(process.env.REACT_APP_API_URL + apiUrls.MANAGE_ACCOUNT, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            body: JSON.stringify({
                userId: userId,
                action: manageAction,
                privilegeState: privilegeState
            }),
            signal: controller.signal

        }).then(response => {

            switch (response.status) {

                case 400:
                    buildModal(userDetailsStrings.MODAL_BAD_REQUEST, userDetailsStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 401:
                    buildModal(userDetailsStrings.MODAL_UNAUTHORIZED, userDetailsStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 409:
                    buildModal(userDetailsStrings.MODAL_CONFLICT, userDetailsStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 500:
                    buildModal(userDetailsStrings.MODAL_SERVER_ERROR, userDetailsStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 503:
                    buildModal(userDetailsStrings.MODAL_SERVICE_UNAVAILABLE, userDetailsStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 200:
                    buildModal(userDetailsStrings.MODAL_OK, userDetailsStrings.MODAL_CLOSE_BUTTON, [routes.RELOAD])
                    break

                default:
                    buildModal(userDetailsStrings.MODAL_UNKNOWN_ERROR, userDetailsStrings.MODAL_CLOSE_BUTTON, [])
                    break

            }

        }).catch(e => {

            console.log(e)
            buildModal(userDetailsStrings.MODAL_FETCH_ERROR, userDetailsStrings.MODAL_CLOSE_BUTTON, [])

        })

    }

    return{
        details,
        confirmation,
        confirmationText,
        handleManageAccount,
        closeConfirmation,
        acceptConfirmation
    }

}

export default useUserDetails