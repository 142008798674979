import {useContext, useState} from "react"
import {ModalProviderContext} from "../../providers/router/ModalProvider"
import fieldNames from "../../utils/constants/components/restorePassForm/fieldNames.json"
import errorStates from "../../utils/constants/shared/errorStates.json"
import jsSHA from "jssha"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import forgotRestorePassStrings from "../../assets/strings/forgotRestorePassStrings.json"
import routes from "../../utils/constants/shared/routes.json"

const useRestorePassForm = (token) => {

    const initialValues = {
        [fieldNames.PWD]: '',
        [fieldNames.CONFIRM_PWD]: ''
    }

    const initialErrors = {
        [fieldNames.PWD]: errorStates.INITIALIZED,
        [fieldNames.CONFIRM_PWD]: errorStates.INITIALIZED
    }

    const [values, setValues] = useState(initialValues)
    const [errors, setErrors] = useState(initialErrors)
    const { buildModal, buildLoadingModal } = useContext(ModalProviderContext)

    const handleBlur = (e) => {

        const {name, value} = e.target
        const otherValue = name === fieldNames.PWD ? values[fieldNames.CONFIRM_PWD] : values[fieldNames.PWD]

        if(value.trim()){

            if(value !== otherValue){

                setErrors({
                    ...errors,
                    [fieldNames.PWD]: errorStates.WITH_ERROR,
                    [fieldNames.CONFIRM_PWD]: errorStates.WITH_ERROR
                })

            }else{

                setErrors({
                    ...errors,
                    [fieldNames.PWD]: errorStates.NO_ERROR,
                    [fieldNames.CONFIRM_PWD]: errorStates.NO_ERROR
                })

            }

        }else{

            setErrors({
                ...errors,
                [name]: errorStates.WITH_ERROR
            })

        }

    }

    const handleChange = (e) => {

        const {name, value} = e.target

        setValues({
            ...values,
            [name]: value.trim()
        })

        handleBlur(e)

    }

    const validate = () => {

        const errorsCopy = {}
        let validated = true

        for (const element in errors) {

            if(errors[element] !== errorStates.NO_ERROR){

                errorsCopy[element] = errorStates.WITH_ERROR
                validated = false

            }

        }

        setErrors(errorsCopy)
        return validated

    }

    const handleRestore = () => {

        if(validate()){

            buildLoadingModal()

            const sha = new jsSHA("SHA-512", "TEXT", { encoding: "UTF8" })
            sha.update(values[fieldNames.PWD])
            const newPass = sha.getHash("HEX")

            const controller = new AbortController()
            setTimeout(() => controller.abort(), 5000)

            fetch(process.env.REACT_APP_API_URL + apiUrls.RESTORE_PASS, {

                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    newPass: newPass
                }),
                signal: controller.signal

            }).then(response => {

                switch (response.status) {

                    case 500:
                        buildModal(forgotRestorePassStrings.MODAL_SERVER_ERROR,
                            forgotRestorePassStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 401:
                        buildModal(forgotRestorePassStrings.MODAL_UNAUTHORIZED,
                            forgotRestorePassStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 403:
                        buildModal(forgotRestorePassStrings.MODAL_FORBIDDEN,
                            forgotRestorePassStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 200:

                        setTimeout(() => {

                            buildModal(forgotRestorePassStrings.MODAL_RESTORED,
                                forgotRestorePassStrings.MODAL_CLOSE_BUTTON, [routes.LOGIN])

                        }, 5000)

                        break

                    default:
                        buildModal(forgotRestorePassStrings.MODAL_UNKNOWN_ERROR,
                            forgotRestorePassStrings.MODAL_CLOSE_BUTTON, [])
                        break

                }

            }).catch(e => {

                console.log(e)
                buildModal(forgotRestorePassStrings.MODAL_FETCH_ERROR, forgotRestorePassStrings.MODAL_CLOSE_BUTTON, [])

            })

        }else{

            buildModal(forgotRestorePassStrings.MODAL_FIELD_ERROR, forgotRestorePassStrings.MODAL_CLOSE_BUTTON, [])

        }

    }

    return{
        errors,
        handleChange,
        handleBlur,
        handleRestore
    }

}

export default useRestorePassForm