import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const InstructionsContainer = styled.div `

  width: calc(100% - 20px);
  height: calc(100% - 10px);
  padding: 10px 10px 0 10px;
  overflow: auto;
  text-align: justify;
  align-self: center;
  font-family: ${fontValues.TEXT_FONT_FAMILY};
  font-size: ${fontValues.TEXT_SIZE};
  color: ${({theme}) => theme.colors.DARK_TEXT};
  margin: 0;

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    width: 100%;
    height: 100%;
    padding: 0;
  }
    
`