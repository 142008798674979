import {Container} from "./Container"
import {Icon} from "./Icon"
import {LinkContainer} from "./Link"
import {InteractionIcon} from "./InteractionIcon"

export const ManagerMenuStyles = {

    Container,
    Icon,
    LinkContainer,
    InteractionIcon

}