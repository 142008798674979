import {useContext, useState} from "react"
import fieldNames from '../../utils/constants/components/studentsReport/fieldNames.json'
import errorStates from "../../utils/constants/shared/errorStates.json"
import studentsReportStrings from '../../assets/strings/studentsReportStrings.json'
import validationStates from '../../utils/constants/components/studentsReport/validationStates.json'
import cookieNames from "../../utils/constants/shared/cookieNames.json"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import {ModalProviderContext} from "../../providers/router/ModalProvider"

const useStudentsReport = () => {

    const reportHeaders = [

        { label: "Id", key: "userId" },
        { label: "Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Country code", key: "countryCode" },
        { label: "Cellphone", key: "cellPhone" },
        { label: "BirthDate", key: "birthDate" },
        { label: "Country", key: "country" },
        { label: "Created account date", key: "createAccountDate" },
        { label: "Student active", key: "active" },
        { label: "Active student Since", key: "activeStudentSinceDate" },
        { label: "Student code", key: "salesCode" },
        { label: "Student code claimed date", key: "salesCodeClaimedDate" },
        { label: "Student code created by", key: "salesCodeCreatedBy" },
        { label: "Student code creation date", key: "salesCodeCreationDate" },
        { label: "Course progress", key: "courseProgress" },
        { label: "Average quiz results", key: "averageQuizResults" },
        { label: "Completed Classes", key: "completedClasses" },
        { label: "Last completed class", key: "lastCompletedClass" },
        { label: "Last student activity date", key: "lastStudentActivityDate" },
        { label: "Processing error", key: "processingError" }

    ]

    const initialValues = {
        [fieldNames.FROM_DATE]: "",
        [fieldNames.TO_DATE]: ""
    }

    const initialErrors = {
        [fieldNames.FROM_DATE]: errorStates.INITIALIZED,
        [fieldNames.TO_DATE]: errorStates.INITIALIZED
    }

    const[values, setValues] = useState(initialValues)
    const[errors, setErrors] = useState(initialErrors)
    const[data, setData] = useState([])
    const { buildModal, buildLoadingModal, setModal } = useContext(ModalProviderContext)

    /** @returns {String} return validate form result with 3 different states*/
    const validate = () => {

        const fromDate = new Date(values[fieldNames.FROM_DATE])
        const toDate = new Date(values[fieldNames.TO_DATE])

        if(fromDate < toDate){

            const errorsCopy = {}
            let validated = true

            for (const element in errors) {

                if(errors[element] !== errorStates.NO_ERROR){

                    errorsCopy[element] = errorStates.WITH_ERROR
                    validated = false

                }

            }

            if(validated){

                return validationStates.PASSED

            }else{

                setErrors(errorsCopy)
                return validationStates.MISSED

            }

        }else{

            const temporaryErrors = {

                [fieldNames.FROM_DATE]: errorStates.WITH_ERROR,
                [fieldNames.TO_DATE]: errorStates.WITH_ERROR

            }

            setErrors(temporaryErrors)
            return validationStates.WRONG_DATES

        }

    }

    const handleChange = (e) => {

        const {name, value} = e.target

        setValues({
            ...values,
            [name]: value.trim()
        })

        handleBlur(e)

    }

    const handleBlur = (e) => {

        const {name, value} = e.target

        if(value.trim()){

            setErrors({
                ...errors,
                [name]: errorStates.NO_ERROR
            })

        }else{

            setErrors({
                ...errors,
                [name]: errorStates.WITH_ERROR
            })

        }

    }

    const handleGetReport = () => {

        const validationState = validate()

        if(validationState === validationStates.PASSED){

            buildLoadingModal()
            const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
            const controller = new AbortController()
            setTimeout(() => controller.abort(), 30000)

            fetch(process.env.REACT_APP_API_URL + apiUrls.GET_REPORT_DATA, {

                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwt
                },
                body: JSON.stringify(values),
                signal: controller.signal

            }).then(response => {

                switch (response.status) {

                    case 400:
                        buildModal(studentsReportStrings.MODAL_BAD_REQUEST,
                            studentsReportStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 401:
                        buildModal(studentsReportStrings.MODAL_UNAUTHORIZED,
                            studentsReportStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 500:
                        buildModal(studentsReportStrings.MODAL_SERVER_ERROR,
                            studentsReportStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 200:

                        response.json().then(data => {

                            if(data.length === 0){

                                buildModal(studentsReportStrings.MODAL_EMPTY_DATA,
                                    studentsReportStrings.MODAL_CLOSE_BUTTON, [])

                            }else{

                                sanitizeData(data).then(sanitizedData => {

                                    setData(sanitizedData)
                                    setModal(false)

                                }).catch(e => {

                                    console.log(e)

                                    buildModal(studentsReportStrings.MODAL_DATA_ERROR,
                                        studentsReportStrings.MODAL_CLOSE_BUTTON, [])

                                })

                            }

                        }).catch(e => {

                            console.log(e)

                            buildModal(studentsReportStrings.MODAL_DATA_ERROR,
                                studentsReportStrings.MODAL_CLOSE_BUTTON, [])

                        })

                        break

                    default:
                        buildModal(studentsReportStrings.MODAL_UNKNOWN_ERROR,
                            studentsReportStrings.MODAL_CLOSE_BUTTON, [])
                        break

                }

            }).catch(e => {

                console.log(e)
                buildModal(studentsReportStrings.MODAL_FETCH_ERROR, studentsReportStrings.MODAL_CLOSE_BUTTON, [])

            })

        }else if(validationState === validationStates.WRONG_DATES){

            buildModal(studentsReportStrings.MODAL_WRONG_DATES, studentsReportStrings.MODAL_CLOSE_BUTTON, [])

        }else{

            buildModal(studentsReportStrings.MODAL_FIELD_ERROR, studentsReportStrings.MODAL_CLOSE_BUTTON, [])

        }

    }

    const sanitizeData = (data) => {

        return new Promise((resolve, reject) => {

            try{

                const temporaryData = []

                let lastStudentQuizResults = 0
                let lastStudentClassesNumber = 0

                for (const element in data) {

                    try{

                        const temporaryStudentRow = {

                            userId: data[element].userId,
                            name: data[element].name,
                            email: data[element].email,
                            countryCode: data[element].countryCode,
                            cellPhone: data[element].cellPhone,
                            birthDate: data[element].birthDate.split('T')[0],
                            country: data[element].country,
                            createAccountDate: data[element].registrationDate.split('T')[0],
                            active: "NO",
                            activeStudentSinceDate: "NA",
                            salesCode: "NA",
                            salesCodeClaimedDate: "NA",
                            salesCodeCreatedBy: "NA",
                            salesCodeCreationDate: "NA",
                            courseProgress: "NA",
                            averageQuizResults: "NA",
                            completedClasses: "NA",
                            lastCompletedClass: "NA",
                            lastStudentActivityDate: "NA",
                            processingError: "NO"

                        }

                        if(data[element].active !== null && data[element].active !== 0){

                            temporaryStudentRow.active = "YES"

                            temporaryStudentRow.activeStudentSinceDate =
                                data[element].activeStudentRegistrationDate.split('T')[0]

                            temporaryStudentRow.salesCode = `CODE: ${data[element].salesCode}`

                            temporaryStudentRow.salesCodeClaimedDate =
                                data[element].claimedDate.split('T')[0]

                            temporaryStudentRow.salesCodeCreationDate =
                                data[element].generationDate.split('T')[0]

                            temporaryStudentRow.salesCodeCreatedBy = data[element].salesCodeCreatedBy

                        }

                        if(data[element].classPosition && element < data.length - 1){

                            if(data[element].userId !== data[parseInt(element) + 1].userId){

                                lastStudentQuizResults = lastStudentQuizResults + data[element].quizResult
                                lastStudentClassesNumber ++

                                const studentProgress = Math.round(1.923 * lastStudentClassesNumber) + '%'

                                const lastActivityDate = data[element].lastChange.split('T')[0]
                                    + '|' + data[element].lastChange.split('T')[1].split('.')[0]

                                const averageQuizResults =
                                    (lastStudentQuizResults/lastStudentClassesNumber).toFixed(2)

                                const lastActivity = "Level " + data[element].classPosition.split('.')[0]
                                    + " Module " + data[element].classPosition.split('.')[1] + " Class " +
                                    data[element].classPosition.split('.')[2]

                                temporaryStudentRow.courseProgress = studentProgress
                                temporaryStudentRow.averageQuizResults = averageQuizResults
                                temporaryStudentRow.completedClasses = lastStudentClassesNumber
                                temporaryStudentRow.lastCompletedClass = lastActivity
                                temporaryStudentRow.lastStudentActivityDate = lastActivityDate

                                temporaryData.push(temporaryStudentRow)

                                lastStudentQuizResults = 0
                                lastStudentClassesNumber = 0

                            }else{

                                lastStudentQuizResults = lastStudentQuizResults + data[element].quizResult
                                lastStudentClassesNumber ++

                            }

                        }else if(data[element].classPosition){

                            lastStudentQuizResults = lastStudentQuizResults + data[element].quizResult
                            lastStudentClassesNumber ++

                            const studentProgress = Math.round(1.923 * lastStudentClassesNumber) + '%'

                            const lastActivityDate = data[element].lastChange.split('T')[0]
                                + '|' + data[element].lastChange.split('T')[1].split('.')[0]

                            const averageQuizResults =
                                (lastStudentQuizResults/lastStudentClassesNumber).toFixed(2)

                            const lastActivity = "Level " + data[element].classPosition.split('.')[0]
                                + " Module " + data[element].classPosition.split('.')[1] + " Class " +
                                data[element].classPosition.split('.')[2]

                            temporaryStudentRow.courseProgress = studentProgress
                            temporaryStudentRow.averageQuizResults = averageQuizResults
                            temporaryStudentRow.completedClasses = lastStudentClassesNumber
                            temporaryStudentRow.lastCompletedClass = lastActivity
                            temporaryStudentRow.lastStudentActivityDate = lastActivityDate

                            temporaryData.push(temporaryStudentRow)

                            lastStudentQuizResults = 0
                            lastStudentClassesNumber = 0

                        } else{

                            temporaryData.push(temporaryStudentRow)

                        }

                    }catch (e) {

                        const temporaryStudentRow = {

                            userId: data[element].userId,
                            name: data[element].name,
                            email: data[element].email,
                            countryCode: data[element].countryCode,
                            cellPhone: data[element].cellPhone,
                            birthDate: data[element].birthDate,
                            country: data[element].country,
                            createAccountDate: data[element].registrationDate,
                            active: "NO",
                            activeStudentSinceDate: "NA",
                            salesCode: "NA",
                            salesCodeClaimedDate: "NA",
                            salesCodeCreatedBy: "NA",
                            salesCodeCreationDate: "NA",
                            courseProgress: "NA",
                            averageQuizResults: "NA",
                            completedClasses: "NA",
                            lastCompletedClass: "NA",
                            lastStudentActivityDate: "NA",
                            processingError: "YES"

                        }

                        temporaryData.push(temporaryStudentRow)

                    }

                }

                resolve(temporaryData)

            }catch (e) {

                reject(e)

            }

        })

    }

    return{
        data,
        reportHeaders,
        errors,
        handleChange,
        handleBlur,
        handleGetReport
    }

}

export default useStudentsReport