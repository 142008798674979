import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const CarrouselIndicators = styled.div `

    width: fit-content;
    height: fit-content;
    display: grid;
    column-gap: 20px;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 5%;
    right: 50%;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        width: 84px;
        right: calc(50% - 42px);
        
    }

`