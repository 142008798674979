import {useState} from "react"

const usePricing = () => {

    const[isOpen, setOpen] = useState(false)

    const handleOfferClick = () => {

        setOpen(!isOpen)

    }

    return{
        isOpen,
        handleOfferClick
    }

}

export default usePricing