import {Fragment} from "react"
import {createPortal} from "react-dom"
import {UserCardStyles} from "../../styles/components/userCard"
import {Text} from "../../styles/shared/Text"
import user from "../../assets/images/userCard/user.svg"
import userCardStrings from "../../assets/strings/userCardStrings.json"
import useUserCard from "./useUserCard"

const UserCard = ({id, name, email, cellPhone, student, sales, manager, registrationDate}) => {

    const {
        details,
        handleClick
    } = useUserCard()

    return (

        <Fragment>

            {createPortal(details, document.body)}

            <UserCardStyles.Container id={id} onClick={handleClick}>

                <UserCardStyles.IconContainer>

                    <UserCardStyles.UserIcon src={user}/>

                </UserCardStyles.IconContainer>

                <UserCardStyles.NameContainer>

                    <UserCardStyles.Name>
                        {name}
                    </UserCardStyles.Name>

                </UserCardStyles.NameContainer>

                <UserCardStyles.FieldsContainer>

                    <Text>
                        {userCardStrings.EMAIL} {email}
                    </Text>

                    <Text>
                        {userCardStrings.PHONE} {cellPhone}
                    </Text>

                    <Text>
                        {userCardStrings.REGISTRATION_DATE} {registrationDate?.split('T')[0]}
                    </Text>

                </UserCardStyles.FieldsContainer>

                <UserCardStyles.PrivilegesContainer>

                    <UserCardStyles.PrivilegeField>

                        <UserCardStyles.Privilege>
                            {userCardStrings.STUDENT}
                        </UserCardStyles.Privilege>

                        <UserCardStyles.PrivilegeState $isActive={student}>
                            {student ? 'YES' : 'NO'}
                        </UserCardStyles.PrivilegeState>

                    </UserCardStyles.PrivilegeField>

                    <UserCardStyles.PrivilegeField>

                        <UserCardStyles.Privilege>
                            {userCardStrings.SALES}
                        </UserCardStyles.Privilege>

                        <UserCardStyles.PrivilegeState $isActive={sales}>
                            {sales ? 'YES' : 'NO'}
                        </UserCardStyles.PrivilegeState>

                    </UserCardStyles.PrivilegeField>

                    <UserCardStyles.PrivilegeField>

                        <UserCardStyles.Privilege>
                            {userCardStrings.MANAGER}
                        </UserCardStyles.Privilege>

                        <UserCardStyles.PrivilegeState $isActive={manager}>
                            {manager ? 'YES' : 'NO'}
                        </UserCardStyles.PrivilegeState>

                    </UserCardStyles.PrivilegeField>

                </UserCardStyles.PrivilegesContainer>

            </UserCardStyles.Container>

        </Fragment>

    )

}

export default UserCard