import styled from "styled-components"

export const MethodContainer = styled.div `

    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 90px calc(100% - 180px) 90px;
    background-color: ${({theme}) => theme.colors.HOME_METHOD};

`