import styled from "styled-components"

export const IconContainer = styled.div `

    width: 115px;
    height: 75px;
    display: grid;
    justify-items: center;
    align-items: center;
    position: absolute;
    margin-left: 10px;
    margin-top: -30px;
    background-color: ${({theme}) => theme.colors.PRIMARY};
    border-radius: 2px;

`