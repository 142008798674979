import {Fragment} from "react"
import studentsReportStrings from '../../assets/strings/studentsReportStrings.json'
import errorStates from "../../utils/constants/shared/errorStates.json"
import fieldNames from '../../utils/constants/components/studentsReport/fieldNames.json'
import useStudentsReport from "./useStudentsReport"

import {StudentsReportStyles} from "../../styles/components/studentsReport"
import {LoginFormStyles} from "../../styles/components/loginForm"
import {Button} from "../../styles/shared/Button"
import {Text} from "../../styles/shared/Text"

const StudentsReport = () => {

    const {
        data,
        reportHeaders,
        errors,
        handleChange,
        handleBlur,
        handleGetReport
    } = useStudentsReport()

    const generatedReportView = (

        <StudentsReportStyles.Container>

            <StudentsReportStyles.Title>{studentsReportStrings.TITLE}</StudentsReportStyles.Title>

            <StudentsReportStyles.DownloadButton data={data} headers={reportHeaders}
            filename={`${studentsReportStrings.TITLE}_${new Date(Date.now()).toISOString().split('T')[0]}`}>

                DOWNLOAD

            </StudentsReportStyles.DownloadButton>

        </StudentsReportStyles.Container>

    )

    const inputDatesView = (

        <StudentsReportStyles.Container>

            <StudentsReportStyles.Title>{studentsReportStrings.TITLE}</StudentsReportStyles.Title>

            <LoginFormStyles.FieldContainer>

                <Text>{studentsReportStrings.FROM_DATE}</Text>

                <LoginFormStyles.Input type='date' name={fieldNames.FROM_DATE} onChange={handleChange}
                onBlur={handleBlur} $error={errors[fieldNames.FROM_DATE] === errorStates.WITH_ERROR}/>

            </LoginFormStyles.FieldContainer>

            <LoginFormStyles.FieldContainer>

                <Text>{studentsReportStrings.TO_DATE}</Text>

                <LoginFormStyles.Input type='date' name={fieldNames.TO_DATE} onChange={handleChange}
                onBlur={handleBlur} $error={errors[fieldNames.TO_DATE] === errorStates.WITH_ERROR}/>

            </LoginFormStyles.FieldContainer>

            <Button onClick={handleGetReport}>{studentsReportStrings.BUTTON}</Button>

        </StudentsReportStyles.Container>

    )

    return(

        <Fragment>

            {data.length > 0 ? generatedReportView : inputDatesView}

        </Fragment>

    )

}

export default StudentsReport