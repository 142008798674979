import {Fragment} from "react"
import useHome from "./useHome"
import homeStrings from "../../assets/strings/homeStrings.json"

import {HomeStyles} from "../../styles/pages/home"
import {HomeButton} from "../../styles/shared/HomeButton"
import Footer from "../../components/footer/Footer"
import AnimateObserver from "../../components/animateObserver/AimateObserver"
import lessonsImage from "../../assets/images/home/lessons.svg"
import columnImage from "../../assets/images/home/column.svg"
import advicesImage from "../../assets/images/home/advices.svg"
import notesImage from "../../assets/images/home/notes.svg"

const Home = () => {

    const {
        index,
        image,
        fade,
        goForward,
        goBackward,
        handleClick
    } = useHome()

    return(

        <Fragment>

            <HomeStyles.Container>

                <HomeStyles.SectionContainer>

                    <HomeStyles.LearningContainer>

                        <HomeStyles.Carrousel src={image} $fade={fade} $isEven={(index % 2) === 0}/>

                        <HomeStyles.LeftButton onClick={goBackward}/>

                        <HomeStyles.RightButton onClick={goForward}/>

                        <HomeStyles.CarrouselIndicators>

                            <HomeStyles.CarrouselIndicator $isActive={index === 0}/>

                            <HomeStyles.CarrouselIndicator $isActive={index === 1}/>

                            <HomeStyles.CarrouselIndicator $isActive={index === 2}/>

                            <HomeStyles.CarrouselIndicator $isActive={index === 3}/>

                        </HomeStyles.CarrouselIndicators>

                        <HomeStyles.CarrouselTitleContainer $isActive={index === 0 && !fade} $right={true}>

                            <HomeStyles.CarrouselTitle>
                                {homeStrings.CARROUSEL_TITLE1}
                            </HomeStyles.CarrouselTitle>

                            <HomeStyles.CarrouselText>
                                {homeStrings.CARROUSEL_TEXT}
                            </HomeStyles.CarrouselText>

                        </HomeStyles.CarrouselTitleContainer>

                        <HomeStyles.CarrouselTitleContainer $isActive={index === 1 && !fade} $right={false}>

                            <HomeStyles.CarrouselTitle>
                                {homeStrings.CARROUSEL_TITLE2}
                            </HomeStyles.CarrouselTitle>

                            <HomeStyles.CarrouselText>
                                {homeStrings.CARROUSEL_TEXT}
                            </HomeStyles.CarrouselText>

                        </HomeStyles.CarrouselTitleContainer>

                        <HomeStyles.CarrouselTitleContainer $isActive={index === 2 && !fade} $right={true}>

                            <HomeStyles.CarrouselTitle>
                                {homeStrings.CARROUSEL_TITLE3}
                            </HomeStyles.CarrouselTitle>

                            <HomeStyles.CarrouselText>
                                {homeStrings.CARROUSEL_TEXT}
                            </HomeStyles.CarrouselText>

                        </HomeStyles.CarrouselTitleContainer>

                        <HomeStyles.CarrouselTitleContainer $isActive={index === 3 && !fade} $right={false}>

                            <HomeStyles.CarrouselTitle>
                                {homeStrings.CARROUSEL_TITLE1}
                            </HomeStyles.CarrouselTitle>

                            <HomeStyles.CarrouselText>
                                {homeStrings.CARROUSEL_TEXT}
                            </HomeStyles.CarrouselText>

                        </HomeStyles.CarrouselTitleContainer>

                    </HomeStyles.LearningContainer>

                </HomeStyles.SectionContainer>

                <HomeStyles.SecondarySectionContainer>

                    <HomeStyles.OffersContainer>

                        <AnimateObserver>

                            <HomeStyles.OffersFrame>

                                <HomeStyles.OffersTitle1>
                                    {homeStrings.COURSES_TITLE}
                                </HomeStyles.OffersTitle1>

                                <HomeStyles.OffersText1>
                                    {homeStrings.COURSES_TEXT1}
                                </HomeStyles.OffersText1>

                                <HomeButton onClick={handleClick}>
                                    {homeStrings.COURSES_BUTTON}
                                </HomeButton>

                            </HomeStyles.OffersFrame>

                        </AnimateObserver>

                        <HomeStyles.OffersFrame>

                            <HomeStyles.OffersTitle2>
                                {homeStrings.COURSES_TITLE2_2}
                            </HomeStyles.OffersTitle2>

                            <HomeStyles.OffersImageBack>

                                <AnimateObserver>

                                    <HomeStyles.OffersImage src={lessonsImage}/>

                                </AnimateObserver>

                            </HomeStyles.OffersImageBack>

                            <HomeStyles.OffersText2>
                                {homeStrings.COURSES_TEXT2_2}
                            </HomeStyles.OffersText2>

                        </HomeStyles.OffersFrame>

                        <HomeStyles.OffersFrame>

                            <HomeStyles.OffersTitle2>
                                {homeStrings.COURSES_TITLE2_1}
                            </HomeStyles.OffersTitle2>

                            <HomeStyles.OffersImageBack>

                                <AnimateObserver>

                                    <HomeStyles.OffersImage src={notesImage}/>

                                </AnimateObserver>

                            </HomeStyles.OffersImageBack>

                            <HomeStyles.OffersText2>
                                {homeStrings.COURSES_TEXT2_1}
                            </HomeStyles.OffersText2>

                        </HomeStyles.OffersFrame>

                        <HomeStyles.OffersFrame>

                            <HomeStyles.OffersTitle2>
                                {homeStrings.COURSES_TITLE2_3}
                            </HomeStyles.OffersTitle2>

                            <HomeStyles.OffersImageBack>

                                <AnimateObserver>

                                    <HomeStyles.OffersImage src={columnImage}/>

                                </AnimateObserver>

                            </HomeStyles.OffersImageBack>

                            <HomeStyles.OffersText2>
                                {homeStrings.COURSES_TEXT2_3}
                            </HomeStyles.OffersText2>

                        </HomeStyles.OffersFrame>

                        <HomeStyles.OffersFrame>

                            <HomeStyles.OffersTitle2>
                                {homeStrings.COURSES_TITLE2_4}
                            </HomeStyles.OffersTitle2>

                            <HomeStyles.OffersImageBack>

                                <AnimateObserver>

                                    <HomeStyles.OffersImage src={columnImage}/>

                                </AnimateObserver>

                            </HomeStyles.OffersImageBack>

                            <HomeStyles.OffersText2>
                                {homeStrings.COURSES_TEXT2_4}
                            </HomeStyles.OffersText2>

                        </HomeStyles.OffersFrame>

                        <HomeStyles.OffersFrame>

                            <HomeStyles.OffersTitle2>
                                {homeStrings.COURSES_TITLE2_5}
                            </HomeStyles.OffersTitle2>

                            <HomeStyles.OffersImageBack>

                                <AnimateObserver>

                                    <HomeStyles.OffersImage src={advicesImage}/>

                                </AnimateObserver>

                            </HomeStyles.OffersImageBack>

                            <HomeStyles.OffersText2>
                                {homeStrings.COURSES_TEXT2_5}
                            </HomeStyles.OffersText2>

                        </HomeStyles.OffersFrame>

                    </HomeStyles.OffersContainer>

                </HomeStyles.SecondarySectionContainer>

                <HomeStyles.SecondarySectionContainer>

                    <HomeStyles.MethodContainer>

                        <HomeStyles.MethodMargin $isTop={true}/>

                        <HomeStyles.MethodContentContainer>

                            <HomeStyles.MethodImage/>

                            <div/>

                            <HomeStyles.MethodTextContainer>


                                <AnimateObserver>

                                    <HomeStyles.MethodTitle>
                                        {homeStrings.METHOD_TITLE}
                                    </HomeStyles.MethodTitle>

                                </AnimateObserver>

                                <AnimateObserver>

                                    <HomeStyles.MethodText>

                                        {homeStrings.METHOD_TEXT1}

                                    </HomeStyles.MethodText>

                                </AnimateObserver>

                                <AnimateObserver>

                                    <HomeStyles.MethodText>

                                        {homeStrings.METHOD_TEXT2}

                                    </HomeStyles.MethodText>

                                </AnimateObserver>

                                <AnimateObserver>

                                    <HomeButton onClick={handleClick}>
                                        {homeStrings.COURSES_BUTTON}
                                    </HomeButton>

                                </AnimateObserver>

                            </HomeStyles.MethodTextContainer>

                        </HomeStyles.MethodContentContainer>

                        <HomeStyles.MethodMargin $isTop={false}/>

                    </HomeStyles.MethodContainer>

                </HomeStyles.SecondarySectionContainer>

                <HomeStyles.TestimonialsSectionContainer>

                    <HomeStyles.TestimonialsFrame>

                        <HomeStyles.TestimonialsImage/>

                        <HomeStyles.TestimonialsTitle>
                            {homeStrings.TESTIMONIALS_TITLE1}
                        </HomeStyles.TestimonialsTitle>

                        <HomeStyles.TestimonialsText>
                            {homeStrings.TESTIMONIALS_TEXT1}
                        </HomeStyles.TestimonialsText>

                    </HomeStyles.TestimonialsFrame>

                    <HomeStyles.TestimonialsFrame>

                        <HomeStyles.TestimonialsImage/>

                        <HomeStyles.TestimonialsTitle>
                            {homeStrings.TESTIMONIALS_TITLE2}
                        </HomeStyles.TestimonialsTitle>

                        <HomeStyles.TestimonialsText>
                            {homeStrings.TESTIMONIALS_TEXT2}
                        </HomeStyles.TestimonialsText>

                    </HomeStyles.TestimonialsFrame>

                    <HomeStyles.TestimonialsFrame>

                        <HomeStyles.TestimonialsImage/>

                        <HomeStyles.TestimonialsTitle>
                            {homeStrings.TESTIMONIALS_TITLE3}
                        </HomeStyles.TestimonialsTitle>

                        <HomeStyles.TestimonialsText>
                            {homeStrings.TESTIMONIALS_TEXT3}
                        </HomeStyles.TestimonialsText>

                    </HomeStyles.TestimonialsFrame>

                </HomeStyles.TestimonialsSectionContainer>

                <HomeStyles.SmallSectionContainer>

                    <HomeStyles.PromoTitle>
                        {homeStrings.PROMO_TITLE}
                    </HomeStyles.PromoTitle>

                    <HomeStyles.PromoText>
                        {homeStrings.PROMO_TEXT1}
                    </HomeStyles.PromoText>

                    <HomeStyles.PromoButton onClick={handleClick}>
                        {homeStrings.PROMO_BUTTON}
                    </HomeStyles.PromoButton>

                </HomeStyles.SmallSectionContainer>

                <Footer/>

            </HomeStyles.Container>

        </Fragment>

    )

}

export default Home