import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const ClassTitle = styled.button `

  padding: 0;
  cursor: pointer;
  text-align: left;
  border: none;
  margin: 5px 0 5px 20px;
  font-family: ${fontValues.TEXT_FONT_FAMILY};
  font-size: ${fontValues.TEXT_SIZE};
  color: ${({theme}) => theme.colors.DARK_TEXT};

`