import {Fragment} from "react"

import {Text} from "../../styles/shared/Text"
import {NotFoundStyles} from "../../styles/pages/notFound"

const NotFound = () => {

    return(

        <Fragment>

            <NotFoundStyles.Container>

                <Text>Not Found</Text>

            </NotFoundStyles.Container>

        </Fragment>

    )

}

export default NotFound