import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const Container = styled.div `

    width: 90%;
    max-width: 1300px;
    height: 90%;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    overflow: auto;

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){

      width: 100%;
      grid-template: none;
      grid-row-gap: 20px;

  }

`