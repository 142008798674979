import styled, { keyframes } from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const Links = styled.div `

    width: 85%;
    max-width: 700px;
    height: 65%;
    display: grid;
    grid-template-columns: 50% 25% 25%;
    grid-template-rows: 50% 50%;
    justify-items: left;
    justify-self: right;
    align-items: center;
  
    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        width: 100vw;
        height: 100%;
        position: fixed;
        top: 0;
        display: ${props => props.$isOpen ? 'grid' : 'none'};
        row-gap: 40px;
        grid-template: none;
        justify-items: center;
        align-content: center;
        justify-self: center;
        background-color: ${({theme}) => theme.colors.HOME_HOVER};
        animation: ${fadeIn} 0.6s ease-in-out;
        
    }

`