import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const MissionTitle = styled.p `
    
    margin: 20px 0 10px 0;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.HOME_TITLE1_SIZE};
    color: ${({theme}) => theme.colors.HOME_PRIMARY};
    font-weight: bolder;

`