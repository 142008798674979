import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const MindsetSubtitle = styled.p `
    
    margin: 0;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.HOME_TEXT1_SIZE};
    color: ${({theme}) => theme.colors.HOME_PRIMARY};
    font-weight: bolder;

`