import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const Container = styled.button `
    
    width: 90%;
    min-width: 400px;
    max-width: 600px;
    height: 170px;
    display: grid;
    justify-self: center;
    margin-top: 30px;
    border: 2px solid ${({theme}) => theme.colors.DARK_BACKGROUND};
    border-radius: 2px;
    position: relative;

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    width: 90%;
    min-width: 90%;
  }
    
`