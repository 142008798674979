import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const TranscriptionContainer = styled.div `

    width: calc(100% - 20px);
    height: calc(100% - 10px);
    padding: 10px 10px 0 10px;
    background-color: ${({theme}) => theme.colors.DARK_BACKGROUND};
    overflow: auto;
    text-align: justify;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.TEXT_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};
    margin: 0;
    display: ${props => props.$isActive ? 'block' : 'none'};
    
`