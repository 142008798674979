import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const SectionContainer = styled.div `

    width: 100%;
    min-width: 1100px;
    height: calc(100vh - 80px);
    min-height: 550px;
    display: grid;
    align-items: center;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        min-width: 100%;
    }

`