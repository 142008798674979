import {Fragment} from "react"
import useForgotPass from "./useForgotPass"
import forgotRestorePassStrings from "../../assets/strings/forgotRestorePassStrings.json"
import errorStates from "../../utils/constants/shared/errorStates.json"

import {ForgotPassFormStyles} from "../../styles/components/forgotPassForm"
import {Text} from "../../styles/shared/Text"
import {LoginFormStyles} from "../../styles/components/loginForm"
import {Button} from "../../styles/shared/Button"

const ForgotPassForm = () => {

    const {
        error,
        handleChange,
        handleBlur,
        handleRecover
    } = useForgotPass()

    return(

        <Fragment>

            <ForgotPassFormStyles.Container>

                <LoginFormStyles.FieldContainer>

                    <Text>{forgotRestorePassStrings.FORGOT_LABEL}</Text>

                    <LoginFormStyles.Input type="email" onChange={handleChange} onBlur={handleBlur}
                    $error={error === errorStates.WITH_ERROR}/>

                </LoginFormStyles.FieldContainer>

                <Button onClick={handleRecover}>{forgotRestorePassStrings.FORGOT_BUTTON}</Button>

            </ForgotPassFormStyles.Container>

        </Fragment>

    )

}

export default ForgotPassForm