import styled from "styled-components"

export const NotificationsContainer = styled.div `

    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(40px, 1fr));
    row-gap: 20px;
    overflow: auto;
    align-self: baseline;
    padding: 20px 0;

`