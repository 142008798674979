import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const FieldsContainer = styled.div `

    width: 75%;
    height: 90%;
    padding: 0 5%;
    display: grid;
    row-gap: 10px;
    justify-items: center;
    align-items: center;
    overflow: auto;

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){

    padding: 0 20px;
    width: 85%;

  }

`