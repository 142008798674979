import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const Container = styled.div `

  width: calc(95% - 40px);
  height: 90%;
  padding: 0 20px;
  display: grid;
  grid-auto-flow: row;
  gap: 20px;
  overflow: auto;
  justify-self: center;
  align-items: center;
  align-self: center;

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    height: 95%;
    width: 90%;
    padding: 0;
    justify-items: center;
  }

`