import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const CourseCard = styled.img `

      height: 70vh;

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){

    height: auto;
    width: 90%;

  }

`