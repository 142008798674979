import {Fragment} from "react"
import {ActivityViewerStyles} from "../../styles/components/activityViewer"
import activityNames from "../../utils/constants/components/activityViewer/activityNames.json"
import elementNames from "../../utils/constants/components/activityViewer/elementNames.json"
import activityViewerStrings from "../../assets/strings/activityViewerStrings.json"
import useActivityViewer from "./useActivityViewer"

import WhatsAppButton from "../whatsAppButton/WhatsAppButton"

const ActivityViewer = () => {

    const {
        loadingData,
        quizQuestions,
        quizResult,
        position,
        content,
        title,
        transcription,
        lessonAudio,
        lectureAudio,
        answered,
        text,
        isOpen,
        handleOpenMenu,
        handleCloseMenu,
        changeActivity,
        handleTextChange,
        handleSubmit,
        handleRetryQuiz
    } = useActivityViewer()

    const loadingAudio = (

        <ActivityViewerStyles.LoadingAudio>

            <ActivityViewerStyles.LoadingAudioText>
                {activityViewerStrings.LOADING_AUDIO}
            </ActivityViewerStyles.LoadingAudioText>

        </ActivityViewerStyles.LoadingAudio>

    )

    return(

        <Fragment>

            <ActivityViewerStyles.Container onClick={handleCloseMenu}>

                <ActivityViewerStyles.ContentTitle>
                    {title} <WhatsAppButton/>
                </ActivityViewerStyles.ContentTitle>

                <ActivityViewerStyles.InstructionsContainer>

                    <ActivityViewerStyles.InstructionsTitlesContainer>

                        <ActivityViewerStyles.InstructionsTitleActivity>
                            {position.activity < 4 ? activityNames.LESSON :
                                position.activity < 5 ? activityNames.LECTURE : activityNames.QUIZ}
                        </ActivityViewerStyles.InstructionsTitleActivity>

                        <ActivityViewerStyles.InstructionsTitle>

                            {position.activity !== 5 ? content.title : ""}

                        </ActivityViewerStyles.InstructionsTitle>

                    </ActivityViewerStyles.InstructionsTitlesContainer>

                    {content.instructions}

                </ActivityViewerStyles.InstructionsContainer>

                <ActivityViewerStyles.QuizContainer
                $isActive={position.activity === 5} $isResult={quizResult !== null}>
                    {quizQuestions}
                </ActivityViewerStyles.QuizContainer>

                <ActivityViewerStyles.AudioContainer $isActive={position.activity < 4}>
                    {loadingData ? loadingAudio : lessonAudio}
                </ActivityViewerStyles.AudioContainer>

                <ActivityViewerStyles.AudioContainer $isActive={position.activity === 4}>
                    {loadingData ? loadingAudio : lectureAudio}
                </ActivityViewerStyles.AudioContainer>

                <ActivityViewerStyles.TranscriptionContainer
                    $isActive={position.activity > 1 && position.activity < 5}>
                    {transcription}
                </ActivityViewerStyles.TranscriptionContainer>

                <ActivityViewerStyles.InstructionsContainer>
                    {content.question}
                </ActivityViewerStyles.InstructionsContainer>

                <ActivityViewerStyles.ResponseInput rows='2' $isActive={position.activity === 1}
                id={elementNames.FIELD1} onChange={handleTextChange} value={text.field1}/>

                <ActivityViewerStyles.ResponseInput rows='2' $isActive={position.activity === 2}
                id={elementNames.FIELD2} onChange={handleTextChange} value={text.field2}/>

                <ActivityViewerStyles.QuizResultsContainer
                    $isActive={quizResult !== null && position.activity === 5}>

                    <ActivityViewerStyles.QuizResult>
                        {activityViewerStrings.QUIZ_RESULT}
                    </ActivityViewerStyles.QuizResult>

                    <ActivityViewerStyles.QuizResult>
                        {quizResult}/100
                    </ActivityViewerStyles.QuizResult>

                </ActivityViewerStyles.QuizResultsContainer>

                <ActivityViewerStyles.ButtonsContainer>

                    <ActivityViewerStyles.LevelOpenButton $isOpen={!isOpen} onClick={handleOpenMenu}/>

                    <ActivityViewerStyles.NavigationButton onClick={changeActivity}
                    name={elementNames.PREVIOUS} $isActive={true}>
                        {elementNames.PREVIOUS}
                    </ActivityViewerStyles.NavigationButton>

                    <ActivityViewerStyles.NavigationButton onClick={changeActivity}
                    name={elementNames.NEXT} $isActive={position.activity !== 5}>
                        {elementNames.NEXT}
                    </ActivityViewerStyles.NavigationButton>

                    <ActivityViewerStyles.NavigationButton onClick={handleSubmit}
                    $isActive={position.activity === 5 && quizResult === null && !answered}>
                        {activityViewerStrings.SUBMIT}
                    </ActivityViewerStyles.NavigationButton>

                    <ActivityViewerStyles.NavigationButton onClick={handleRetryQuiz}
                    $isActive={position.activity === 5 && answered}>
                        {activityViewerStrings.RETRY_QUIZ}
                    </ActivityViewerStyles.NavigationButton>

                </ActivityViewerStyles.ButtonsContainer>

            </ActivityViewerStyles.Container>

        </Fragment>

    )

}

export default ActivityViewer