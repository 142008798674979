import {Fragment} from "react"

import {ReportsStyles} from "../../styles/pages/reports"
import StudentsReport from "../../components/studentsReport/StudentsReport"
import SalesReport from "../../components/salesReport/SalesReport"

const Reports = () => {

    return(

        <Fragment>

            <ReportsStyles.Container>

                <StudentsReport/>

                <SalesReport/>

            </ReportsStyles.Container>

        </Fragment>

    )

}

export default Reports