import {useState} from "react"
import UserDetails from "../userDetails/UserDetails"

const useUserCard = () => {

    const[details, setDetails] = useState(null)

    const handleClick = (e) => {

        const {id} = e.currentTarget
        setDetails(<UserDetails userId={id} closeDetails={closeDetails}/>)

    }

    const closeDetails = () => {

        setDetails(null)

    }

    return{
        details,
        handleClick
    }

}

export default useUserCard