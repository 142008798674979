import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const Container = styled.div `

    width: 380px;
    height: 410px;
    display: grid;
    justify-items: center;
    align-items: center;
    border: 2px solid ${({theme}) => theme.colors.LOGIN_FORM_BORDER};
    border-radius: 2px;

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    width: 100%;
    border: none;
  }

`