import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const PromoTitle = styled.div `
    
    width: fit-content;
    margin: 0;
    color: ${({theme}) => theme.colors.LIGHT_TEXT};
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.HOME_TITLE_SIZE};
    font-weight: bolder;
    text-align: justify;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        font-size: ${fontValues.HOME_TITLE1_SIZE}
    }

`