import {Fragment} from "react"
import routes from '../../utils/constants/shared/routes.json'
import socialLinks from '../../utils/constants/shared/socialLinks.json'
import footerStrings from '../../assets/strings/footerStrings.json'
import facebookLogo from '../../assets/images/footer/facebookLogo.svg'
import instagramLogo from '../../assets/images/footer/instagramLogo.svg'
import tiktokLogo from '../../assets/images/footer/tiktokLogo.svg'

import {FooterStyles} from "../../styles/components/footer"

const Footer = () => {

    return(

        <Fragment>

            <FooterStyles.Container>

                <FooterStyles.OptionsContainer>

                    <FooterStyles.OptionContainer>

                        <FooterStyles.Logo/>

                    </FooterStyles.OptionContainer>

                    <FooterStyles.OptionContainer>

                        <FooterStyles.OptionTitle>
                            {footerStrings.O2_TITLE}
                        </FooterStyles.OptionTitle>

                        <FooterStyles.Link to={routes.ABOUT}>
                            {footerStrings.O2_LINK1}
                        </FooterStyles.Link>

                        <FooterStyles.Link>
                            {footerStrings.O2_LINK2}
                        </FooterStyles.Link>

                        <FooterStyles.Link>
                            {footerStrings.O2_LINK3}
                        </FooterStyles.Link>

                        <FooterStyles.Link>
                            {footerStrings.O2_LINK4}
                        </FooterStyles.Link>

                    </FooterStyles.OptionContainer>

                    <FooterStyles.OptionContainer>

                        <FooterStyles.OptionTitle>
                            {footerStrings.O3_TITLE}
                        </FooterStyles.OptionTitle>

                        <FooterStyles.SocialLinks>

                            <FooterStyles.Link to={socialLinks.FACEBOOK} target='_blank'>
                                <FooterStyles.SocialLogo src={facebookLogo}/>
                            </FooterStyles.Link>

                            <FooterStyles.Link to={socialLinks.INSTAGRAM} target='_blank'>
                                <FooterStyles.SocialLogo src={instagramLogo}/>
                            </FooterStyles.Link>

                            <FooterStyles.Link to={socialLinks.TIKTOK} target='_blank'>
                                <FooterStyles.SocialLogo src={tiktokLogo}/>
                            </FooterStyles.Link>

                        </FooterStyles.SocialLinks>

                    </FooterStyles.OptionContainer>

                </FooterStyles.OptionsContainer>

                <FooterStyles.InfoContainer>

                    <FooterStyles.LinksContainer>

                        <FooterStyles.Link to={socialLinks.WHATS_APP_SALES} target='_blank'>
                            {footerStrings.LINK1}
                        </FooterStyles.Link>

                        <FooterStyles.Separator>
                            {footerStrings.SEPARATOR}
                        </FooterStyles.Separator>

                        <FooterStyles.Link to={routes.PRIVACY}>
                            {footerStrings.LINK2}
                        </FooterStyles.Link>

                        <FooterStyles.Separator>
                            {footerStrings.SEPARATOR}
                        </FooterStyles.Separator>

                        <FooterStyles.Link to={routes.TERMS}>
                            {footerStrings.LINK3}
                        </FooterStyles.Link>

                        <FooterStyles.Separator>
                            {footerStrings.SEPARATOR}
                        </FooterStyles.Separator>

                        <FooterStyles.Link to={routes.COOKIES}>
                            {footerStrings.LINK4}
                        </FooterStyles.Link>

                    </FooterStyles.LinksContainer>

                    <FooterStyles.Text>
                        {footerStrings.END}
                    </FooterStyles.Text>

                </FooterStyles.InfoContainer>

            </FooterStyles.Container>

        </Fragment>

    )

}

export default Footer