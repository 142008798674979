import {Container} from "./Container"
import {SectionContainer} from "./SectionContainer"
import {SmallSectionContainer} from "./SmallSectionContainer"
import {SecondarySectionContainer} from "./SecondarySectionontainer"
import {TestimonialsSectionContainer} from "./TestimonialsSectionContainer"

import {LearningContainer} from "./LearningContainer"
import {Carrousel} from "./Carrousel"
import {LeftButton} from "./LeftButton"
import {RightButton} from "./RightButton"
import {CarrouselIndicator} from "./CarrouselIndicator"
import {CarrouselIndicators} from "./CarrouselIndicators"
import {CarrouselTitle} from "./CarrouselTitle"

import {CarrouselText} from "./CarrouselText"
import {CarrouselTitleContainer} from "./CarrouselTitleContainer"
import {OffersContainer} from "./OffersContainer"
import {OffersFrame} from "./OffersFrame"
import {OffersTitle1} from "./OffersTitle1"
import {OffersText1} from "./OffersText1"
import {OffersTitle2} from "./OffersTitle2"
import {OffersImageBack} from "./OffersImageBack"


import {OffersImage} from "./OffersImage"
import {OffersText2} from "./OffersText2"
import {MethodContainer} from "./MethodContainer"
import {MethodMargin} from "./MethodMargin"
import {MethodContentContainer} from "./MethodContentContainer"
import {MethodImage} from "./MethodImage"
import {MethodTextContainer} from "./MethodTextContainer"
import {MethodTitle} from "./MethodTitle"

import {MethodText} from "./MethodText"
import {PromoTitle} from "./PromoTitle"
import {PromoText} from "./PromoText"
import {PromoButton} from "./PromoButton"

import {TestimonialsImage} from "./Testimonialsimage"
import {TestimonialsFrame} from "./TestimonialsFrame"
import {TestimonialsText} from "./TestimonialsText"
import {TestimonialsTitle} from "./TestimonialsTitle"

export const HomeStyles = {
    Container: Container,
    SectionContainer: SectionContainer,
    SecondarySectionContainer: SecondarySectionContainer,
    SmallSectionContainer: SmallSectionContainer,
    TestimonialsSectionContainer: TestimonialsSectionContainer,

    LearningContainer: LearningContainer,
    Carrousel: Carrousel,
    CarrouselIndicator: CarrouselIndicator,
    CarrouselIndicators: CarrouselIndicators,
    LeftButton: LeftButton,
    RightButton: RightButton,
    CarrouselTitle: CarrouselTitle,
    CarrouselText: CarrouselText,
    CarrouselTitleContainer: CarrouselTitleContainer,

    OffersContainer: OffersContainer,
    OffersFrame:OffersFrame,
    OffersTitle1: OffersTitle1,
    OffersText1: OffersText1,
    OffersTitle2: OffersTitle2,
    OffersImageBack: OffersImageBack,
    OffersImage: OffersImage,
    OffersText2: OffersText2,

    MethodContainer: MethodContainer,
    MethodMargin: MethodMargin,
    MethodContentContainer: MethodContentContainer,
    MethodImage: MethodImage,
    MethodTextContainer: MethodTextContainer,
    MethodTitle: MethodTitle,
    MethodText: MethodText,

    PromoTitle: PromoTitle,
    PromoText: PromoText,
    PromoButton: PromoButton,

    TestimonialsImage: TestimonialsImage,
    TestimonialsFrame: TestimonialsFrame,
    TestimonialsText: TestimonialsText,
    TestimonialsTitle: TestimonialsTitle
}