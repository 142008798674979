import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const MissionImage = styled.img `

    width: 363px;
    height: 243px;
    justify-self: center;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        width: 100%;
        max-width: 85vw;
        height: auto;
        
    }

`