import {styled, keyframes} from "styled-components"

const rotateAnimation = keyframes`
    
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-90deg);
    }
    
`

export const InteractionIcon = styled.img `
    
    width: 35px;
    height: 35px;
    border: none;
    justify-self: center;
    align-self: center;
    transform: rotate(${(props) => props.$isOpen ? '-90deg' : 'none'});
    transition: 0.2s;

    &.rotate {
        animation: ${rotateAnimation} 1s forwards;
    }

`