import {Fragment} from "react"
import {ProfileViewerStyles} from "../../styles/components/profileViewer"
import profileViewerStrings from '../../assets/strings/profileViewerStrings.json'
import fieldNames from '../../utils/constants/components/registrationForm/fieldNames.json'
import errorStates from '../../utils/constants/shared/errorStates.json'
import useProfileViewer from "./useProfileViewer"

const ProfileViewer = () => {

    const {
        data,
        errors,
        hasChanges,
        handleChange,
        handleBlur,
        handleSaveChanges
    } = useProfileViewer()

    return(

        <Fragment>

            <ProfileViewerStyles.Card>

                <ProfileViewerStyles.Title>
                    {profileViewerStrings.TITLE}
                </ProfileViewerStyles.Title>

                <ProfileViewerStyles.ColumnContainer>

                    <ProfileViewerStyles.Field>

                        <ProfileViewerStyles.Text>
                            {profileViewerStrings.NAME}
                        </ProfileViewerStyles.Text>

                        <ProfileViewerStyles.Input type="text" value={data[fieldNames.NAME]}
                       name={fieldNames.NAME} onChange={handleChange} onBlur={handleBlur}
                       $hasError={errors[fieldNames.NAME] === errorStates.WITH_ERROR}/>

                    </ProfileViewerStyles.Field>

                    <ProfileViewerStyles.CellPhoneField>

                        <ProfileViewerStyles.Field>

                            <ProfileViewerStyles.Text>
                                {profileViewerStrings.COUNTRY_CODE}
                            </ProfileViewerStyles.Text>

                            <ProfileViewerStyles.Input type="text" value={data[fieldNames.COUNTRY_CODE]}
                           name={fieldNames.COUNTRY_CODE} onChange={handleChange} onBlur={handleBlur}
                           $hasError={errors[fieldNames.COUNTRY_CODE] === errorStates.WITH_ERROR}/>

                        </ProfileViewerStyles.Field>

                        <ProfileViewerStyles.Field>

                            <ProfileViewerStyles.Text>
                                {profileViewerStrings.CELL_PHONE}
                            </ProfileViewerStyles.Text>

                            <ProfileViewerStyles.Input type="number" value={data[fieldNames.CELL_PHONE]}
                           name={fieldNames.CELL_PHONE} onChange={handleChange} onBlur={handleBlur}
                           $hasError={errors[fieldNames.CELL_PHONE] === errorStates.WITH_ERROR}/>

                        </ProfileViewerStyles.Field>

                    </ProfileViewerStyles.CellPhoneField>

                    <ProfileViewerStyles.Field>

                        <ProfileViewerStyles.Text>
                            {profileViewerStrings.COUNTRY}
                        </ProfileViewerStyles.Text>

                        <ProfileViewerStyles.Input type="text" value={data[fieldNames.COUNTRY]}
                       name={fieldNames.COUNTRY} onChange={handleChange} onBlur={handleBlur}
                       $hasError={errors[fieldNames.COUNTRY] === errorStates.WITH_ERROR}/>

                    </ProfileViewerStyles.Field>

                    <ProfileViewerStyles.Field>

                        <ProfileViewerStyles.Text>
                            {profileViewerStrings.BIRTH_DATE}
                        </ProfileViewerStyles.Text>

                        <ProfileViewerStyles.Input type="date" value={data[fieldNames.BIRTH_DATE]}
                       name={fieldNames.BIRTH_DATE} onChange={handleChange} onBlur={handleBlur}
                       $hasError={errors[fieldNames.BIRTH_DATE] === errorStates.WITH_ERROR}/>

                    </ProfileViewerStyles.Field>

                    <ProfileViewerStyles.Field>

                        <ProfileViewerStyles.Text>
                            {profileViewerStrings.EMAIL}
                        </ProfileViewerStyles.Text>

                        <ProfileViewerStyles.Input type="email" value={data[fieldNames.EMAIL]}
                       name={fieldNames.EMAIL} onChange={handleChange} onBlur={handleBlur}
                       $hasError={errors[fieldNames.EMAIL] === errorStates.WITH_ERROR}/>

                    </ProfileViewerStyles.Field>

                    <ProfileViewerStyles.Button $isActive={hasChanges} onClick={handleSaveChanges}>
                        {profileViewerStrings.SAVE_BUTTON}
                    </ProfileViewerStyles.Button>

                </ProfileViewerStyles.ColumnContainer>

            </ProfileViewerStyles.Card>

        </Fragment>

    )

}

export default ProfileViewer