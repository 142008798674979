import {Fragment} from "react"
import {ProgressViewStyles} from "../../styles/components/progressView"
import {CardTitle} from "../../styles/components/progressView/CardTitle"
import useProgressView from "./useProgressView"
import progressViewStrings from "../../assets/strings/progressViewStrings.json"

const ProgressView = ({setCourse}) => {

    const {
        data
    } = useProgressView()

    return(

        <Fragment>

            <ProgressViewStyles.Container>

                <ProgressViewStyles.CardsContainer>

                    <ProgressViewStyles.CourseCard>

                        <CardTitle>{progressViewStrings.COURSE_ENGLISH}</CardTitle>

                        <ProgressViewStyles.CardFields>

                            <ProgressViewStyles.CardLabels>

                                <ProgressViewStyles.LabelField>

                                    <ProgressViewStyles.CardLabel>
                                        {progressViewStrings.COURSE_PROGRESS}
                                    </ProgressViewStyles.CardLabel>

                                </ProgressViewStyles.LabelField>

                                <ProgressViewStyles.LabelField>

                                    <ProgressViewStyles.CardLabel>
                                        {progressViewStrings.AVERAGE}
                                    </ProgressViewStyles.CardLabel>

                                </ProgressViewStyles.LabelField>

                                <ProgressViewStyles.LabelField>

                                    <ProgressViewStyles.CardLabel>
                                        {progressViewStrings.COMPLETED_CLASSES}
                                    </ProgressViewStyles.CardLabel>

                                </ProgressViewStyles.LabelField>

                                <ProgressViewStyles.LabelField>

                                    <ProgressViewStyles.CardLabel>
                                        {progressViewStrings.LAST_CLASS}
                                    </ProgressViewStyles.CardLabel>

                                </ProgressViewStyles.LabelField>

                                <ProgressViewStyles.LabelField>

                                    <ProgressViewStyles.CardLabel>
                                        {progressViewStrings.LATEST_ACTIVITY}
                                    </ProgressViewStyles.CardLabel>

                                </ProgressViewStyles.LabelField>

                            </ProgressViewStyles.CardLabels>

                            <ProgressViewStyles.CardResults>

                                <ProgressViewStyles.ResultField>

                                    <ProgressViewStyles.CardLabel>
                                        {data.progress}%
                                    </ProgressViewStyles.CardLabel>

                                </ProgressViewStyles.ResultField>

                                <ProgressViewStyles.ResultField>

                                    <ProgressViewStyles.CardLabel>
                                        {data.average} / 100
                                    </ProgressViewStyles.CardLabel>

                                </ProgressViewStyles.ResultField>

                                <ProgressViewStyles.ResultField>

                                    <ProgressViewStyles.CardLabel>
                                        {data.completedClasses}
                                    </ProgressViewStyles.CardLabel>

                                </ProgressViewStyles.ResultField>

                                <ProgressViewStyles.ResultField>

                                    <ProgressViewStyles.CardLabel>
                                        {data.lastClass}
                                    </ProgressViewStyles.CardLabel>

                                </ProgressViewStyles.ResultField>

                                <ProgressViewStyles.ResultField>

                                    <ProgressViewStyles.CardLabel>
                                        {data.latestDate}
                                    </ProgressViewStyles.CardLabel>

                                </ProgressViewStyles.ResultField>

                            </ProgressViewStyles.CardResults>

                        </ProgressViewStyles.CardFields>

                        <ProgressViewStyles.CardButton onClick={setCourse}>

                            {progressViewStrings.ENTER_BUTTON}

                        </ProgressViewStyles.CardButton>

                    </ProgressViewStyles.CourseCard>

                </ProgressViewStyles.CardsContainer>

            </ProgressViewStyles.Container>

        </Fragment>

    )

}

export default ProgressView