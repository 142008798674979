import styled from "styled-components"

export const NotificationContainer = styled.div `

    width: 100%;
    height: max-content;
    min-height: 50px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 70% 30%;
    background-color: ${({theme}) => theme.colors.DARK_BACKGROUND};
    padding: 10px 0;

`