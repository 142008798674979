import styled from "styled-components"
import fontValues from "../../assets/values/fontValues/fontValues.json"

export const HomeButton = styled.button `
    
    width: 170px;
    height: 55px;
    margin-top: 30px;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.HOME_TEXT1_SIZE};
    background-color: ${({ theme }) => theme.colors.HOME_PRIMARY};
    color: ${({ theme }) => theme.colors.LIGHT_TEXT};
    border: none;
    border-radius: 10px;
    transition: background-color 0.5s ease, color 0.5s ease;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.HOME_HOVER};
        color: ${({ theme }) => theme.colors.HOME_PRIMARY};
    }
    
`