import {Container} from "./Container"
import {OptionsContainer} from "./OptionsContainer"
import {InfoContainer} from "./InfoContainer"
import {LinksContainer} from "./LinksContainer"
import {Link} from "./Link"
import {Text} from "./Text"
import {OptionContainer} from "./OptionContainer"
import {OptionTitle} from "./OptionTitle"
import {Separator} from "./Separator"
import {SocialLinks} from "./SocialLinks"
import {SocialLogo} from "./SocialLogo"
import {Logo} from "./Logo"

export const FooterStyles = {
    Container: Container,
    OptionsContainer: OptionsContainer,
    InfoContainer: InfoContainer,
    LinksContainer: LinksContainer,
    Link: Link,
    Text: Text,
    OptionContainer: OptionContainer,
    OptionTitle: OptionTitle,
    Separator: Separator,
    SocialLinks: SocialLinks,
    SocialLogo: SocialLogo,
    Logo: Logo
}