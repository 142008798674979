import {ColumnContainer} from "./ColumnContainer"
import {Card} from "./Card"
import {Title} from "./Title"
import {Text} from "./Text"
import {Field} from "./Field"
import {CellPhoneField} from "./CellPhoneField"
import {Input} from "./Input"
import {Button} from "./Button"

export const ProfileViewerStyles = {
    ColumnContainer: ColumnContainer,
    Card: Card,
    Title: Title,
    Text: Text,
    Field: Field,
    CellPhoneField: CellPhoneField,
    Input: Input,
    Button: Button
}