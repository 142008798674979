import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const CourseCard = styled.div `

    height: 70%;
    width: 70%;
    max-width: 800px;
    max-height: 500px;
    display: grid;
    grid-template-rows: 20% 60% 20%;
    justify-self: center;
    align-items: center;
    border: 2px solid ${({theme}) => theme.colors.LOGIN_FORM_BORDER};
    border-radius: 2px;

    @media(max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    
      width: 90%;
      height: 80%;
    
    }

`