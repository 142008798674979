import styled from "styled-components"

export const NotificationAlert = styled.span `

    width: 13px;
    height: 18px;
    display: ${(props) => props.$isActive ? 'grid' : 'none'};
    align-items: center;
    background-color: red;
    color: white;
    border-radius: 50px;

`