import styled from "styled-components"
import arrow from '../../../assets/images/home/arrow.svg'
import arrowHover from '../../../assets/images/home/arrowHover.svg'
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const LeftButton = styled.div `

    height: 60px;
    width: 60px;
    position: absolute;
    top: 50%;
    left: 10px;
    background-image: url(${arrow});
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
    cursor: pointer;

    &:hover {
        background-image: url(${arrowHover});
    }

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){

        top: 60%;
        height: 40px;
        width: 40px;
        
    }

`