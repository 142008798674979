import {Fragment} from "react"
import {NotificationsModalStyles} from "../../styles/modals/notificationsModal"

import {Button} from "../../styles/shared/Button"
import useNotificationsModal from "./useNotificationsModal"

const NotificationsModal = ({text, buttonText, redirectUrl}) => {

    const {
        handleClick
    } = useNotificationsModal(redirectUrl)

    return(

        <Fragment>

            <NotificationsModalStyles.Container>

                <NotificationsModalStyles.Card>

                    <NotificationsModalStyles.Text>{text}</NotificationsModalStyles.Text>

                    <Button onClick={handleClick}>{buttonText}</Button>

                </NotificationsModalStyles.Card>

            </NotificationsModalStyles.Container>

        </Fragment>

    )

}

export default NotificationsModal