import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const ValuesText = styled.pre `

    width: 100%;
    margin: 0;
    text-align: justify;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.HOME_TEXT2_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};
    overflow: auto;
    white-space: pre-line;
    line-height: 1.8;

`