import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const PromoButton = styled.button `
    
    width: 160px;
    height: 42px;
    margin-top: 40px;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.HOME_TEXT2_SIZE};
    font-weight: bolder;
    background-color: ${({ theme }) => theme.colors.LIGHT_BACKGROUND};
    color: ${({ theme }) => theme.colors.HOME_PRIMARY};
    border: none;
    border-radius: 10px;
    transition: background-color 0.5s ease, color 0.5s ease;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.HOME_PRIMARY};
        color: ${({ theme }) => theme.colors.LIGHT_BACKGROUND};
        border: 1px solid ${({theme}) => theme.colors.LIGHT_BACKGROUND};
    }
    
`