import styled, {keyframes} from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(100%); 
    }
    
    to {
        opacity: 1;
        transform: translateY(0);
    }
`

export const CarrouselTitleContainer = styled.div `

    width: fit-content;
    height: fit-content;
    display: ${(props) => props.$isActive ? 'grid' : 'none'};
    row-gap: 20px;
    position: absolute;
    top: 25%;
    left: ${props => props.$right ? '60%' : 'unset'};
    right: ${props => props.$right ? 'unset' : '60%'};
    animation: ${slideUp} 0.7s ease-out;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        width: 70%;
        position: sticky;
        justify-self: center;
        top: 30%;
        left: ${props => props.$right ? '25%' : 'unset'};
        right: ${props => props.$right ? 'unset' : '25%'};
        
    }

`