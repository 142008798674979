import {ClassTitle} from "./ClassTitle"
import {Container} from "./Container"
import {ModuleTitle} from "./ModuleTitle"
import {LevelTitle} from "./LevelTitle"

export const LevelSelectorStyles = {

    ClassTitle: ClassTitle,
    Container: Container,
    ModuleTitle: ModuleTitle,
    LevelTitle: LevelTitle

}