import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const InstructionsTitleActivity = styled.h1 `

    margin: 0 0 20px 0;
    align-self: center;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.SUBTITLE_SIZE};
    color: ${({theme}) => theme.colors.ALTER};

`