import {useContext, useState} from "react"
import fieldNames from '../../utils/constants/components/studentsReport/fieldNames.json'
import errorStates from "../../utils/constants/shared/errorStates.json"
import studentsReportStrings from '../../assets/strings/studentsReportStrings.json'
import validationStates from '../../utils/constants/components/studentsReport/validationStates.json'
import cookieNames from "../../utils/constants/shared/cookieNames.json"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import {ModalProviderContext} from "../../providers/router/ModalProvider"

const useSalesReport = () => {

    const reportHeaders = [

        { label: "Sales code", key: "salesCode" },
        { label: "Generation date", key: "generationDate" },
        { label: "Claimed date", key: "claimedDate" },
        { label: "Seller id", key: "sellerId" },
        { label: "Seller name", key: "sellerName" },
        { label: "Seller email", key: "sellerEmail" },
        { label: "Seller phone", key: "sellerPhone" },
        { label: "Claimed by id", key: "claimedById" },
        { label: "Claimed by name", key: "claimedByName" },
        { label: "Claimed by email", key: "claimedByEmail" },
        { label: "Claimed by phone", key: "claimedByPhone" }

    ]

    const initialValues = {
        [fieldNames.FROM_DATE]: "",
        [fieldNames.TO_DATE]: ""
    }

    const initialErrors = {
        [fieldNames.FROM_DATE]: errorStates.INITIALIZED,
        [fieldNames.TO_DATE]: errorStates.INITIALIZED
    }

    const[values, setValues] = useState(initialValues)
    const[errors, setErrors] = useState(initialErrors)
    const[data, setData] = useState([])
    const { buildModal, buildLoadingModal, setModal } = useContext(ModalProviderContext)

    /** @returns {String} return validate form result with 3 different states*/
    const validate = () => {

        const fromDate = new Date(values[fieldNames.FROM_DATE])
        const toDate = new Date(values[fieldNames.TO_DATE])

        if(fromDate < toDate){

            const errorsCopy = {}
            let validated = true

            for (const element in errors) {

                if(errors[element] !== errorStates.NO_ERROR){

                    errorsCopy[element] = errorStates.WITH_ERROR
                    validated = false

                }

            }

            if(validated){

                return validationStates.PASSED

            }else{

                setErrors(errorsCopy)
                return validationStates.MISSED

            }

        }else{

            const temporaryErrors = {

                [fieldNames.FROM_DATE]: errorStates.WITH_ERROR,
                [fieldNames.TO_DATE]: errorStates.WITH_ERROR

            }

            setErrors(temporaryErrors)
            return validationStates.WRONG_DATES

        }

    }

    const handleChange = (e) => {

        const {name, value} = e.target

        setValues({
            ...values,
            [name]: value.trim()
        })

        handleBlur(e)

    }

    const handleBlur = (e) => {

        const {name, value} = e.target

        if(value.trim()){

            setErrors({
                ...errors,
                [name]: errorStates.NO_ERROR
            })

        }else{

            setErrors({
                ...errors,
                [name]: errorStates.WITH_ERROR
            })

        }

    }

    const handleGetReport = () => {

        const validationState = validate()

        if(validationState === validationStates.PASSED){

            buildLoadingModal()
            const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
            const controller = new AbortController()
            setTimeout(() => controller.abort(), 30000)

            fetch(process.env.REACT_APP_API_URL + apiUrls.GET_SALES_REPORT, {

                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwt
                },
                body: JSON.stringify(values),
                signal: controller.signal

            }).then(response => {

                switch (response.status) {

                    case 400:
                        buildModal(studentsReportStrings.MODAL_BAD_REQUEST,
                            studentsReportStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 401:
                        buildModal(studentsReportStrings.MODAL_UNAUTHORIZED,
                            studentsReportStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 500:
                        buildModal(studentsReportStrings.MODAL_SERVER_ERROR,
                            studentsReportStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 200:

                        response.json().then(data => {

                            if(data.length === 0){

                                buildModal(studentsReportStrings.MODAL_EMPTY_DATA,
                                    studentsReportStrings.MODAL_CLOSE_BUTTON, [])

                            }else{

                                sanitizeData(data).then(sanitizedData => {

                                    setData(sanitizedData)
                                    setModal(false)

                                }).catch(e => {

                                    console.log(e)

                                    buildModal(studentsReportStrings.MODAL_DATA_ERROR,
                                        studentsReportStrings.MODAL_CLOSE_BUTTON, [])

                                })

                            }

                        }).catch(e => {

                            console.log(e)

                            buildModal(studentsReportStrings.MODAL_DATA_ERROR,
                                studentsReportStrings.MODAL_CLOSE_BUTTON, [])

                        })

                        break

                    default:
                        buildModal(studentsReportStrings.MODAL_UNKNOWN_ERROR,
                            studentsReportStrings.MODAL_CLOSE_BUTTON, [])
                        break

                }

            }).catch(e => {

                console.log(e)
                buildModal(studentsReportStrings.MODAL_FETCH_ERROR, studentsReportStrings.MODAL_CLOSE_BUTTON, [])

            })

        }else if(validationState === validationStates.WRONG_DATES){

            buildModal(studentsReportStrings.MODAL_WRONG_DATES, studentsReportStrings.MODAL_CLOSE_BUTTON, [])

        }else{

            buildModal(studentsReportStrings.MODAL_FIELD_ERROR, studentsReportStrings.MODAL_CLOSE_BUTTON, [])

        }

    }

    const sanitizeData = (data) => {

        return new Promise((resolve, reject) => {

            try{

                const temporaryData = []

                for (const element in data) {

                    try{

                        const temporaryStudentRow = {

                            salesCode: data[element].salesCode,
                            generationDate: data[element].generationDate.split('T')[0],
                            claimedDate: data[element].claimedDate.split('T')[0],
                            sellerId: data[element].sellerId,
                            sellerName: data[element].sellerName,
                            sellerEmail: data[element].sellerEmail,
                            sellerPhone: data[element].sellerPhone,
                            claimedById: data[element].claimedById,
                            claimedByName: data[element].claimedByName,
                            claimedByEmail: data[element].claimedByEmail,
                            claimedByPhone: data[element].claimedByPhone,
                            processingError: "NO"

                        }

                        temporaryData.push(temporaryStudentRow)

                    }catch (e) {

                        const temporaryStudentRow = {

                            salesCode: data[element].salesCode,
                            generationDate: data[element].generationDate.split('T')[0],
                            claimedDate: data[element].claimedDate.split('T')[0],
                            sellerId: data[element].sellerId,
                            sellerName: data[element].sellerName,
                            sellerEmail: data[element].sellerEmail,
                            sellerPhone: data[element].sellerPhone,
                            claimedById: data[element].claimedById,
                            claimedByName: data[element].claimedByName,
                            claimedByEmail: data[element].claimedByEmail,
                            claimedByPhone: data[element].claimedByPhone,
                            processingError: "YES"

                        }

                        temporaryData.push(temporaryStudentRow)

                    }

                }

                resolve(temporaryData)

            }catch (e) {

                reject(e)

            }

        })

    }

    return{
        data,
        reportHeaders,
        errors,
        handleChange,
        handleBlur,
        handleGetReport
    }

}

export default useSalesReport