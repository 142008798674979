import {Fragment} from "react"
import {useParams} from "react-router-dom"
import paramNames from '../../utils/constants/pages/router/paramNames.json'

import RestorePassForm from "../../components/restorepassForm/RestorePassForm"

const RestorePass = () => {

    const {[paramNames.TOKEN] : token} = useParams()

    return(

        <Fragment>

            <RestorePassForm token={token}/>

        </Fragment>

    )

}

export default RestorePass