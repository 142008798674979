import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const CardsContainer = styled.div `

    height: 100%;
    width: 90%;
    max-width: 1300px;
    display: grid;
    justify-self: center;
    align-items: center;
    grid-auto-flow: column;
    column-gap: 20px;
    overflow: auto;

    @media(max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
      
      width: 100%;
      grid-auto-flow: row;
      
    }

`