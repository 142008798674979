import styled, {keyframes} from "styled-components"

const facebook = keyframes `
  
    from{
      top: 8px;
      height: 64px;
    }

    to{
      top: 24px;
      height: 32px;
    }
    
`

export const Spinner = styled.div `

    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  
    & div {
      display: inline-block;
      position: absolute;
      left: 8px;
      width: 16px;
      background: ${({theme}) => theme.colors.BUTTON_HOVER};
      animation: ${facebook} 0.6s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }
  
    & div:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
    }

    & div:nth-child(2) {
        background: ${({theme}) => theme.colors.PRIMARY};
        left: 32px;
        animation-delay: -0.12s;
    }

    & div:nth-child(3) {
        left: 56px;
        animation-delay: 0s;
    }

`