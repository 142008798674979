import styled from "styled-components"
import telegramLogo from '../../../assets/images/whatsAppButton/telegramLogo.svg'
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const Button = styled.a `

    width: 55px;
    height: 55px;
    background-color: transparent;
    border: none;
    position: absolute;
    right: 20px;
    top: calc(20px + 80px);
    background-image: url(${telegramLogo});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;

  @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    right: 20px;
  }

`