import {Fragment} from "react"
import {CoursesStyles} from "../../styles/pages/courses"
import routes from "../../utils/constants/shared/routes.json"

import englishCourseJPG from "../../assets/images/courses/englishCourse.jpg"
import englishCourseWEBP from "../../assets/images/courses/englishCourse.webp"
import frenchCourseJPG from "../../assets/images/courses/frenchCourse.jpg"
import frenchCourseWEBP from "../../assets/images/courses/frenchCourse.webp"

const Courses = () => {

    return(

        <Fragment>

            <CoursesStyles.Container>

                <CoursesStyles.Link to={routes.PRICING}>

                    <picture>

                        <source srcSet={englishCourseWEBP} type="image/webp"/>
                        <source srcSet={englishCourseJPG} type="image/jpeg"/>

                        <CoursesStyles.CourseCard src={englishCourseJPG}/>

                    </picture>

                </CoursesStyles.Link>

                <CoursesStyles.Link>

                    <picture>

                        <source srcSet={frenchCourseWEBP} type="image/webp"/>
                        <source srcSet={frenchCourseJPG} type="image/jpeg"/>

                        <CoursesStyles.CourseCard src={frenchCourseJPG}/>

                    </picture>

                </CoursesStyles.Link>

            </CoursesStyles.Container>

        </Fragment>

    )

}

export default Courses