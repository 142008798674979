import {useContext, useState} from "react"
import fieldNames from '../../utils/constants/components/registrationForm/fieldNames.json'
import errorStates from "../../utils/constants/shared/errorStates.json"
import jsSHA from "jssha"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import routes from '../../utils/constants/shared/routes.json'
import signUpFormStrings from '../../assets/strings/signUpFormStrings.json'
import {ModalProviderContext} from "../../providers/router/ModalProvider"

const useSignUpForm = () => {

    const initialValues = {
        [fieldNames.EMAIL]: '',
        [fieldNames.PASSWORD]: '',
        [fieldNames.COUNTRY]: '',
        [fieldNames.BIRTH_DATE]: '',
        [fieldNames.COUNTRY_CODE]: '',
        [fieldNames.CELL_PHONE]: '',
        [fieldNames.NAME]: ''
    }

    const initialErrors = {
        [fieldNames.EMAIL]: errorStates.INITIALIZED,
        [fieldNames.PASSWORD]: errorStates.INITIALIZED,
        [fieldNames.COUNTRY]: errorStates.INITIALIZED,
        [fieldNames.BIRTH_DATE]: errorStates.INITIALIZED,
        [fieldNames.COUNTRY_CODE]: errorStates.INITIALIZED,
        [fieldNames.CELL_PHONE]: errorStates.INITIALIZED,
        [fieldNames.NAME]: errorStates.INITIALIZED
    }

    const [values, setValues] = useState(initialValues)
    const[errors, setErrors] = useState(initialErrors)
    const { buildModal, buildLoadingModal } = useContext(ModalProviderContext)

    const handleSignUp = () => {

        if(validate()){

            const sha = new jsSHA("SHA-512", "TEXT", { encoding: "UTF8" })
            sha.update(values[fieldNames.PASSWORD])

            buildLoadingModal()

            const cpyValues = values
            cpyValues[fieldNames.PASSWORD] = sha.getHash("HEX")

            const controller = new AbortController()
            setTimeout(() => controller.abort(), 5000)

            fetch(process.env.REACT_APP_API_URL + apiUrls.SIGN_UP_ROUTE, {

                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(cpyValues),
                signal: controller.signal

            }).then(response => {

                switch (response.status) {

                    case 409:
                        buildModal(signUpFormStrings.MODAL_ACCOUNT_EXIST, signUpFormStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 500:
                        buildModal(signUpFormStrings.MODAL_SERVER_ERROR, signUpFormStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 201:
                        buildModal(signUpFormStrings.MODAL_CREATED, signUpFormStrings.MODAL_LOGIN_BUTTON,
                        [routes.LOGIN])
                        break

                    default:
                        buildModal(signUpFormStrings.MODAL_UNKNOWN_ERROR, signUpFormStrings.MODAL_CLOSE_BUTTON, [])
                        break

                }

            }).catch(() => {

                buildModal(signUpFormStrings.MODAL_FETCH_ERROR, signUpFormStrings.MODAL_CLOSE_BUTTON, [])

            })

        }else{

            buildModal(signUpFormStrings.MODAL_FIELD_ERROR, signUpFormStrings.MODAL_CLOSE_BUTTON, [])

        }

    }

    const validate = () => {

        const errorsCopy = {}
        let validated = true

        for (const element in errors) {

            if(errors[element] !== errorStates.NO_ERROR){

                errorsCopy[element] = errorStates.WITH_ERROR
                validated = false

            }

        }

        setErrors(errorsCopy)
        return validated

    }

    const handleBlur = (e) => {

        const {name, value} = e.target
        switch (name) {

            case fieldNames.PASSWORD:
                if(value.trim().length >= 8){

                    setErrors({
                        ...errors,
                        [name]: errorStates.NO_ERROR
                    })

                }else{

                    setErrors({
                        ...errors,
                        [name]: errorStates.WITH_ERROR
                    })

                }

                break

            case fieldNames.CELL_PHONE:
                if(value.trim().length === 10){

                    setErrors({
                        ...errors,
                        [name]: errorStates.NO_ERROR
                    })

                }else{

                    setErrors({
                        ...errors,
                        [name]: errorStates.WITH_ERROR
                    })

                }

                break

            default:
                if(value.trim()){

                    setErrors({
                        ...errors,
                        [name]: errorStates.NO_ERROR
                    })

                }else{

                    setErrors({
                        ...errors,
                        [name]: errorStates.WITH_ERROR
                    })

                }

                break

        }

    }

    const handleChange = (e) => {

        const {name, value} = e.target

        setValues({
            ...values,
            [name]: value.trim()
        })

        handleBlur(e)

    }

    return{
        errors,
        handleChange,
        handleSignUp
    }

}

export default useSignUpForm