import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const Container = styled.div `
    
    width: 90%;
    height: 90%;
    display: grid;
    grid-template-rows: 10% 90%;
    border: 2px solid ${({theme}) => theme.colors.LOGIN_FORM_BORDER};
    border-radius: 2px;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        width: 100%;
        height: 100%;
        border: none;
    }

`