import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const PromoText = styled.div `
    
    max-width: 880px;
    margin: 20px 0 0 0;
    color: ${({theme}) => theme.colors.LIGHT_TEXT};
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.HOME_TEXT1_SIZE};
    text-align: center;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        max-width: 90%;
        font-size: ${fontValues.HOME_TEXT2_SIZE};
    }

`