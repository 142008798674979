import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const LevelTitle = styled.button `

    padding: 0;
    margin: 30px 0 0 10px;
    align-self: center;
    font-weight: bold;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.SUBTITLE_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};
    border: none;

`