import {useContext, useEffect, useRef, useState} from "react"
import {ModalProviderContext} from "../../providers/router/ModalProvider"
import cookieNames from "../../utils/constants/shared/cookieNames.json"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import {ManageNotificationsStyles} from "../../styles/components/manageNotifications"
import manageNotificationsStrings from "../../assets/strings/manageNotificationsStrings.json"
import routes from "../../utils/constants/shared/routes.json"

const useManageNotifications = () => {

    const[notificationCards, setNotificationCards] = useState(null)
    const[notificationText, setNotificationText] = useState('')
    const notifications = useRef(null)
    const { buildModal, buildLoadingModal, setModal } = useContext(ModalProviderContext)

    useEffect(() => {

        getNotifications()

    //eslint-disable-next-line
    }, [])

    const getNotifications = () => {

        buildLoadingModal()
        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 5000)

        fetch(process.env.REACT_APP_API_URL + apiUrls.GET_NOTIFICATIONS, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            signal: controller.signal

        }).then(response => {

            switch (response.status) {

                case 400:
                    buildModal(manageNotificationsStrings.MODAL_BAD_REQUEST,
                        manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 401:
                    buildModal(manageNotificationsStrings.MODAL_UNAUTHORIZED,
                        manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 500:
                    buildModal(manageNotificationsStrings.MODAL_SERVER_ERROR,
                        manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 200:

                    response.json().then(data => {

                        notifications.current = data

                        buildNotificationCards(data).then(() => {

                            setModal(false)

                        }).catch(e => {

                            console.log(e)

                            buildModal(manageNotificationsStrings.MODAL_UNKNOWN_ERROR,
                                manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])

                        })

                    }).catch(e => {

                        console.log(e)

                        buildModal(manageNotificationsStrings.MODAL_DATA_ERROR,
                            manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])

                    })

                    break

                default:
                    buildModal(manageNotificationsStrings.MODAL_UNKNOWN_ERROR,
                        manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])
                    break

            }

        }).catch(e => {

            console.log(e)

            buildModal(manageNotificationsStrings.MODAL_FETCH_ERROR,
                manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])

        })

    }

    const buildNotificationCards = (data) => {

        return new Promise((resolve, reject) => {

            try{

                const temporaryCards = []

                for (const element in data) {

                    temporaryCards.push(

                        <ManageNotificationsStyles.NotificationContainer key={data[element].notificationId}>

                            <ManageNotificationsStyles.TextContainer>

                                <ManageNotificationsStyles.NotificationText>
                                    {data[element].text}
                                </ManageNotificationsStyles.NotificationText>

                                <ManageNotificationsStyles.CreationText>
                                    Creation date: {data[element].creationDate.split('T')[0]}
                                </ManageNotificationsStyles.CreationText>

                            </ManageNotificationsStyles.TextContainer>

                            <ManageNotificationsStyles.DeleteButton id={data[element].notificationId}
                            onClick={deleteNotification}>

                                {manageNotificationsStrings.DELETE_BUTTON}

                            </ManageNotificationsStyles.DeleteButton>

                        </ManageNotificationsStyles.NotificationContainer>

                    )

                }

                setNotificationCards(temporaryCards)
                resolve()

            }catch (e) {

                reject(e)

            }

        })

    }

    const deleteNotification = (e) => {

        buildLoadingModal()
        const {id} = e.target
        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 5000)

        fetch(process.env.REACT_APP_API_URL + apiUrls.DELETE_NOTIFICATION, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            body: JSON.stringify({
                'notificationId': id
            }),
            signal: controller.signal

        }).then(response => {

            switch (response.status) {

                case 400:
                    buildModal(manageNotificationsStrings.MODAL_BAD_REQUEST,
                        manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 401:
                    buildModal(manageNotificationsStrings.MODAL_UNAUTHORIZED,
                        manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 500:
                    buildModal(manageNotificationsStrings.MODAL_SERVER_ERROR,
                        manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 200:

                    actualizeNotifications(id).then(() => {

                        setTimeout(() => {

                            buildModal(manageNotificationsStrings.MODAL_DELETED,
                                manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])

                        }, 5000)

                    }).catch(e => {

                        console.log(e)

                        buildModal(manageNotificationsStrings.MODAL_UNKNOWN_ERROR,
                            manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])

                    })

                    break

                default:
                    buildModal(manageNotificationsStrings.MODAL_UNKNOWN_ERROR,
                        manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])
                    break

            }

        }).catch(e => {

            console.log(e)

            buildModal(manageNotificationsStrings.MODAL_FETCH_ERROR,
                manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])

        })

    }

    const actualizeNotifications = (id) => {

        return new Promise((resolve, reject) => {

            try{

                const temporaryNotifications = notifications.current

                for (const element in temporaryNotifications) {

                    if(temporaryNotifications[element].notificationId === parseInt(id)){

                        temporaryNotifications.splice(element, 1)

                    }

                }

                notifications.current = temporaryNotifications

                buildNotificationCards(temporaryNotifications).then(() => {

                    resolve()

                }).catch(e => {

                    reject(e)

                })

            }catch (e) {

                reject(e)

            }

        })

    }

    const createNotifications = () => {

        if(!notificationText.trim()){

            buildModal(manageNotificationsStrings.MODAL_EMPTY_TEXT,
                manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])

        }else{

            buildLoadingModal()
            const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
            const controller = new AbortController()
            setTimeout(() => controller.abort(), 5000)

            fetch(process.env.REACT_APP_API_URL + apiUrls.BUILD_NOTIFICATION, {

                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwt
                },
                body: JSON.stringify({
                    'notificationText': notificationText
                }),
                signal: controller.signal

            }).then(response => {

                switch (response.status) {

                    case 400:
                        buildModal(manageNotificationsStrings.MODAL_BAD_REQUEST,
                            manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 401:
                        buildModal(manageNotificationsStrings.MODAL_UNAUTHORIZED,
                            manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 500:
                        buildModal(manageNotificationsStrings.MODAL_SERVER_ERROR,
                            manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])
                        break

                    case 201:

                        setTimeout(() => {

                            buildModal(manageNotificationsStrings.MODAL_CREATED,
                                manageNotificationsStrings.MODAL_CLOSE_BUTTON, [routes.RELOAD])

                        }, 5000)

                        break

                    default:
                        buildModal(manageNotificationsStrings.MODAL_UNKNOWN_ERROR,
                            manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])
                        break

                }

            }).catch(e => {

                console.log(e)

                buildModal(manageNotificationsStrings.MODAL_FETCH_ERROR,
                    manageNotificationsStrings.MODAL_CLOSE_BUTTON, [])

            })

        }

    }

    const handleChange = (e) => {

        const {value} = e.target
        setNotificationText(value)

    }

    return{
        notificationCards,
        handleChange,
        createNotifications
    }

}

export default useManageNotifications