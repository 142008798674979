import {Fragment} from "react"
import {SignUpFormStyles} from "../../styles/components/signUpForm"
import signUpStrings from "../../assets/strings/signUpFormStrings.json"
import fieldNames from '../../utils/constants/components/registrationForm/fieldNames.json'
import errorStates from "../../utils/constants/shared/errorStates.json"
import routes from "../../utils/constants/shared/routes.json"
import useSignUpForm from "./useSignUpForm"

import {Text} from "../../styles/shared/Text"
import {Button} from "../../styles/shared/Button"

const SignUpForm = () => {

    const {
        errors,
        handleChange,
        handleSignUp
    } = useSignUpForm()

    return(

        <Fragment>

            <SignUpFormStyles.Container>

                <SignUpFormStyles.FieldContainer>

                    <Text>{signUpStrings.NAME}</Text>
                    <SignUpFormStyles.Input name={fieldNames.NAME} onChange={handleChange}
                    placeholder={signUpStrings.NAME_PLACEHOLDER}
                    $error={errors[fieldNames.NAME] === errorStates.WITH_ERROR} autoComplete="no"/>

                </SignUpFormStyles.FieldContainer>

                <SignUpFormStyles.CellPhoneField>

                    <SignUpFormStyles.NumberField>

                        <Text>{signUpStrings.COUNTRY_CODE}</Text>
                        <SignUpFormStyles.Input name={fieldNames.COUNTRY_CODE} onChange={handleChange}
                        placeholder={signUpStrings.COUNTRY_CODE_PLACEHOLDER}
                        $error={errors[fieldNames.COUNTRY_CODE] === errorStates.WITH_ERROR} autoComplete="no"/>

                    </SignUpFormStyles.NumberField>

                    <SignUpFormStyles.NumberField>

                        <Text>{signUpStrings.CELL_PHONE}</Text>
                        <SignUpFormStyles.Input type='number' name={fieldNames.CELL_PHONE}
                        onChange={handleChange} placeholder={signUpStrings.CELL_PHONE_PLACEHOLDER}
                        $error={errors[fieldNames.CELL_PHONE] === errorStates.WITH_ERROR} autoComplete="no"/>

                    </SignUpFormStyles.NumberField>

                </SignUpFormStyles.CellPhoneField>

                <SignUpFormStyles.FieldContainer>

                    <Text>{signUpStrings.COUNTRY}</Text>
                    <SignUpFormStyles.Input name={fieldNames.COUNTRY} onChange={handleChange}
                    placeholder={signUpStrings.COUNTRY_PLACEHOLDER}
                    $error={errors[fieldNames.COUNTRY] === errorStates.WITH_ERROR} autoComplete="no"/>

                </SignUpFormStyles.FieldContainer>

                <SignUpFormStyles.FieldContainer>

                    <Text>{signUpStrings.BIRTH_DATE}</Text>
                    <SignUpFormStyles.Input type='date' name={fieldNames.BIRTH_DATE} onChange={handleChange}
                    $error={errors[fieldNames.BIRTH_DATE] === errorStates.WITH_ERROR} autoComplete="no"/>

                </SignUpFormStyles.FieldContainer>

                <SignUpFormStyles.FieldContainer>

                    <Text>{signUpStrings.EMAIL}</Text>
                    <SignUpFormStyles.Input name={fieldNames.EMAIL} onChange={handleChange}
                    placeholder={signUpStrings.EMAIL_PLACEHOLDER}
                    $error={errors[fieldNames.EMAIL] === errorStates.WITH_ERROR} autoComplete="no"/>

                </SignUpFormStyles.FieldContainer>

                <SignUpFormStyles.FieldContainer>

                    <Text>{signUpStrings.PASSWORD}</Text>
                    <SignUpFormStyles.Input type="password" name={fieldNames.PASSWORD} onChange={handleChange}
                    placeholder={signUpStrings.PASSWORD_PLACEHOLDER}
                    $error={errors[fieldNames.PASSWORD] === errorStates.WITH_ERROR} autoComplete="no"/>

                </SignUpFormStyles.FieldContainer>

                <SignUpFormStyles.FieldContainer/>

                <SignUpFormStyles.FieldContainer>

                    <Button onClick={handleSignUp}>{signUpStrings.SIGN_UP_BUTTON}</Button>

                    <SignUpFormStyles.Privacy to={routes.PRIVACY}>
                        {signUpStrings.PRIVACY}
                    </SignUpFormStyles.Privacy>

                </SignUpFormStyles.FieldContainer>

            </SignUpFormStyles.Container>

        </Fragment>

    )

}

export default SignUpForm