import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const OffersText1 = styled.p `

    width: fit-content;
    height: fit-content;
    margin: 0 0 30px 0;
    color: ${({theme}) => theme.colors.DARK_TEXT};
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.HOME_TEXT1_SIZE};
    text-align: justify;
    
`