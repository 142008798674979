import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const Container = styled.div `

  width: 700px;
  height: 450px;
  display: grid;
  flex-direction: column;
  justify-content: center;
  row-gap: 30px;
  grid-template-columns: 50% 50%;
  justify-items: center;
  align-items: center;
  border: 2px solid ${({theme}) => theme.colors.LOGIN_FORM_BORDER};
  border-radius: 2px;
  padding: 40px 30px;

  @media(max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    width: 100%;
    min-height: 700px;
    display: flex;
    border: none;
    padding: 0;
    margin: 40px 0;
    overflow-y: scroll;
    overflow-x: scroll;
  }

`