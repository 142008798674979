import {Container} from "./Container"
import {SearchContainer} from "./SearchContainer"
import {CardsContainer} from "./CardsContainer"
import {Search} from "./Search"
import {FilterButton} from "./FilterButton"

export const UserCardsFrameStyles = {
    Container,
    SearchContainer,
    CardsContainer,
    Search,
    FilterButton
}