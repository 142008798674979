import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const CardFields = styled.div `

    width: 80%;
    height: 90%;
    display: grid;
    justify-self: center;
    grid-auto-flow: column;

    @media(max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    
        width: 90%;
    
    }

`