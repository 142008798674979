import {Fragment} from "react"
import useNotificationsViewer from "./useNotificationsViewer"
import containerName from "../../utils/constants/components/notificationsViewer/containerName.json"

import {NotificationsViewerStyles} from "../../styles/components/notificationsViewer"
import {Text} from "../../styles/shared/Text"

const NotificationsViewer = ({closeNotificationsViewer, notifications, hiddenNotifications}) => {

    const {
        notificationCards,
        closeViewer
    } = useNotificationsViewer(closeNotificationsViewer, notifications, hiddenNotifications)

    return(

        <Fragment>

            <NotificationsViewerStyles.Container onClick={closeViewer} id={containerName.CONTAINER_NAME}>

                <NotificationsViewerStyles.Card>

                    <Text>
                        NOTIFICATIONS
                    </Text>

                    <NotificationsViewerStyles.NotificationsContainer>

                        {notificationCards}

                    </NotificationsViewerStyles.NotificationsContainer>

                </NotificationsViewerStyles.Card>

            </NotificationsViewerStyles.Container>

        </Fragment>

    )

}

export default NotificationsViewer