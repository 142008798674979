import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const FieldData = styled.p `

    max-width: 200px;
    overflow: auto;
    word-wrap: break-word;
    white-space: pre-line;
    justify-self: right;
    text-align: right;
    margin: 0;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.TEXT_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};

`