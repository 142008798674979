import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const OffersText2 = styled.p `

    width: 280px;
    height: fit-content;
    margin: 20px 0 0 0;
    color: ${({theme}) => theme.colors.DARK_TEXT};
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.HOME_TEXT2_SIZE};
    text-align: left;
    line-height: 1.8;
    
`