import styled, {keyframes} from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

const crescendo = keyframes `

    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
    
`

const crescendoTop = keyframes `

    from {
        transform: scale(0);
        transform-origin: top right;
    }
    to {
        transform: scale(1);
        transform-origin: top right;
    }
    
`

export const Card = styled.div `

    width: 350px;
    height: 500px;
    display: grid;
    position: absolute;
    top: 100px;
    right: 4%;
    grid-template-rows: 15% 85%;
    align-items: center;
    justify-items: center;
    border-radius: 10px;
    animation: ${crescendoTop} .3s alternate;
    background-color: ${({theme}) => theme.colors.DARK_BACKGROUND};
    border: 2px solid ${({theme}) => theme.colors.LOGIN_FORM_BORDER};
    overflow: auto;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
    
        width: 90%;
        right: auto;
        left: auto;
        top: auto;
        bottom: auto;
        animation: ${crescendo} .3s alternate;
    
    }

`