import {useContext, useEffect, useState} from "react"
import apiUrls from "../../utils/constants/api/apiUrls.json"
import cookieNames from "../../utils/constants/shared/cookieNames.json"
import {ModalProviderContext} from "../../providers/router/ModalProvider"
import progressViewStrings from "../../assets/strings/progressViewStrings.json"

const useProgressView = () => {

    const initialData = {
        progress: '',
        average: '',
        completedClasses: '',
        lastClass: '',
        latestDate: ''
    }

    const [data, setData] = useState(initialData)
    const { buildModal, buildLoadingModal, setModal } = useContext(ModalProviderContext)

    useEffect(() => {

        getData()

    //eslint-disable-next-line
    }, [])

    const getData = () => {

        buildLoadingModal()
        const jwt = localStorage.getItem(cookieNames.JWT_COOKIE)
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 20000)

        fetch(process.env.REACT_APP_API_URL + apiUrls.GET_PROGRESS_DATA, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            signal: controller.signal

        }).then(response => {

            switch (response.status){

                case 400:
                    buildModal(progressViewStrings.MODAL_BAD_REQUEST, progressViewStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 401:
                    buildModal(progressViewStrings.MODAL_UNAUTHORIZED, progressViewStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 500:
                    buildModal(progressViewStrings.MODAL_SERVER_ERROR, progressViewStrings.MODAL_CLOSE_BUTTON, [])
                    break

                case 200:
                    handleResponse(response)
                    break

                default:
                    buildModal(progressViewStrings.MODAL_UNKNOWN_ERROR, progressViewStrings.MODAL_CLOSE_BUTTON, [])
                    break

            }

        }).catch(e => {

            console.log(e)
            buildModal(progressViewStrings.MODAL_FETCH_ERROR, progressViewStrings.MODAL_CLOSE_BUTTON, [])

        })

    }

    const handleResponse = (response) => {

        response.json().then(data => {

            const responseData = {
                progress: '',
                average: '',
                completedClasses: '',
                lastClass: '',
                latestDate: ''
            }

            let completedClassesCount = 0
            let quizResults = 0

            for (const element in data) {

                if(data[data.length - 1] === data[element]){

                    responseData.lastClass = `Level ${data[element].classPosition.split('.')[0]} 
                        Module ${data[element].classPosition.split('.')[1]} 
                        Class ${data[element].classPosition.split('.')[2]}`


                    responseData.latestDate = `${data[element].lastChange.split('T')[0]} | 
                        ${data[element].lastChange.split('T')[1].split('.')[0]}`

                }

                quizResults = quizResults + data[element].quizResult
                completedClassesCount++

            }

            responseData.progress = Math.round(1.923 * completedClassesCount) //HARDCODED PERCENTAGE
            responseData.average = (quizResults/completedClassesCount).toFixed(2)
            responseData.completedClasses = completedClassesCount

            setData(responseData)
            setModal(false)

        }).catch(e => {

            console.log(e)
            buildModal(progressViewStrings.MODAL_DATA_ERROR, progressViewStrings.MODAL_CLOSE_BUTTON, [])

        })

    }

    return{
        data
    }

}

export default useProgressView