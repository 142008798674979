import {Container} from "./Container"
import {ViewContainer} from "./ViewContainer"
import {IntroContainer} from "./IntroContainer"
import {IntroTextContainer} from "./IntroTextContainer"
import {IntroImage} from "./IntroImage"
import {IntroText} from "./IntroText"
import {IntroTitle} from "./IntroTitle"
import {MissionContainer} from "./MissionContainer"
import {MissionColumn} from "./MissionColumn"
import {MissionImage} from "./MissionImage"
import {MissionText} from "./MissionText"
import {ValuesContainer} from "./ValuesContainer"
import {ValuesColumn} from "./ValuesColumn"
import {ValuesColumnContainer} from "./ValuesColumnContainer"
import {MindsetContainer} from "./MindsetContainer"
import {MindsetColumns} from "./MindsetColumns"
import {TopicsContainer} from "./TopicsContainer"
import {TopicsMargin} from "./TopicsMargin"
import {MissionTitle} from "./MissionTitle"
import {ValuesSubtitle} from "./ValuesSubtitle"
import {ValuesText} from "./ValuesText"
import {MindsetFrame} from "./MindsetFrame"
import {MindsetSubtitle} from "./MindsetSubtitle"
import {MindsetTitle} from "./MindsetTitle"

export const AboutStyles = {

    Container,
    ViewContainer,
    IntroContainer,
    IntroTextContainer,
    IntroImage,
    IntroText,
    IntroTitle,
    MissionContainer,
    MissionColumn,
    MissionImage,
    MissionText,
    ValuesContainer,
    ValuesColumnContainer,
    ValuesColumn,
    MindsetContainer,
    MindsetColumns,
    TopicsContainer,
    TopicsMargin,
    MissionTitle,
    ValuesSubtitle,
    ValuesText,
    MindsetFrame,
    MindsetSubtitle,
    MindsetTitle

}