import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const ValuesSubtitle = styled.p `
    
    margin: 0;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.HOME_TITLE2_SIZE};
    color: ${({theme}) => theme.colors.HOME_PRIMARY};
    font-weight: bolder;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        width: 85vw;
        
    }

`