import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const MethodTextContainer = styled.div `

    width: 75%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-self: left;
    align-self: center;
    justify-content: center;
    align-items: center;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){

        width: 90%;
        max-width: 350px;
        height: max-content;
        justify-self: center;
        align-self: center;
        margin-bottom: 50px;

    }

`