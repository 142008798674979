import styled from "styled-components"
import mediaQueries from '../../../utils/constants/shared/mediaQueries.json'

export const MissionColumn = styled.div `

    width: 363px;
    height: fit-content;
    display: grid;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        width: 85%;
        justify-content: center;
        
    }

`