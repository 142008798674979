import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const Container = styled.div `

    width: 600px;
    height: 80%;
    display: grid;
    grid-template-rows: 120px calc(100% - 120px);
    border: 2px solid ${({theme}) => theme.colors.LOGIN_FORM_BORDER};
    border-radius: 2px;
    justify-self: center;
    align-self: center;

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        width: 100%;
        height: 100%;
        border: none;
    }

`