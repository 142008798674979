import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const CardsContainer = styled.div `

    width: 100%;
    height: calc(100% - 42px);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
    row-gap: 40px;
    overflow: auto;
    padding: 20px 0;
    border-top: 2px solid ${({theme}) => theme.colors.LOGIN_FORM_BORDER};

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
        
        grid-template-columns: none;
        row-gap: 20px;
        
    }

`