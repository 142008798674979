import styled from "styled-components"
import mediaQueries from "../../../utils/constants/shared/mediaQueries.json"

export const QuizContainer = styled.div `

    width: calc(100% - 24px);
    height: calc(100% - 14px);
    padding: 10px 10px 0 10px;
    border: 2px solid ${({theme}) => theme.colors.LOGIN_FORM_BORDER};
    display: ${props => props.$isActive ? 'grid' : 'none'};
    overflow: auto;
    row-gap: 25px;
  
    & button {
      pointer-events: ${props => props.$isResult ? 'none' : 'all'};
    }

    @media (max-width: ${mediaQueries.WIDTH_FOR_MOBILES}){
      width: 100%;
      height: 100%;
      padding: 0;
      border: none;
    }

`