import {Fragment} from "react"
import useUserCardsFrame from "./useUserCardsFrame"

import {UserCardsFrameStyles} from "../../styles/components/userCardsFrame"
import filtersName from "../../utils/constants/components/userCardsFrame/filtersName.json"

const UserCardsFrame = () => {

    const {
        cards,
        filters,
        handleFilter,
        handleSearch
    } = useUserCardsFrame()

    return(

        <Fragment>

            <UserCardsFrameStyles.Container>

                <UserCardsFrameStyles.SearchContainer>

                    <UserCardsFrameStyles.Search type="text" placeholder="Seach" autoCorrect="off"
                    onChange={handleSearch}/>

                    <UserCardsFrameStyles.FilterButton name={filtersName.STUDENT} onClick={handleFilter}
                    $isActive={filters[filtersName.STUDENT]}>
                        Student
                    </UserCardsFrameStyles.FilterButton>

                    <UserCardsFrameStyles.FilterButton name={filtersName.SALES} onClick={handleFilter}
                    $isActive={filters[filtersName.SALES]}>
                        Sales
                    </UserCardsFrameStyles.FilterButton>

                    <UserCardsFrameStyles.FilterButton name={filtersName.MANAGER} onClick={handleFilter}
                    $isActive={filters[filtersName.MANAGER]}>
                        Manager
                    </UserCardsFrameStyles.FilterButton>

                </UserCardsFrameStyles.SearchContainer>

                <UserCardsFrameStyles.CardsContainer>

                    {cards}

                </UserCardsFrameStyles.CardsContainer>

            </UserCardsFrameStyles.Container>

        </Fragment>

    )

}

export default UserCardsFrame