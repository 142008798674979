import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const PricesTableTotal = styled.p `

    width: 100%;
    height: 65px;
    padding-top: 10px;
    margin: 0;
    align-self: center;
    text-align: center;
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    font-size: ${fontValues.TEXT_SIZE};
    color: ${({theme}) => theme.colors.DARK_TEXT};
    background-color: ${({theme}) => theme.colors.DARK_BACKGROUND};

`