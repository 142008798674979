import {Fragment} from "react"
import useLevelSelector from "./useLevelSelector"
import {LevelSelectorStyles} from "../../styles/components/levelSelector"

const LevelSelector = () => {

    const {
        isOpen,
        cards
    } = useLevelSelector()

    return(

        <Fragment>

            <LevelSelectorStyles.Container $isOpen={isOpen}>
                {cards}
            </LevelSelectorStyles.Container>

        </Fragment>

    )

}

export default LevelSelector