import {Container} from "./Container"
import {CourseCard} from "./CourseCard"
import {CardsContainer} from "./CardsContainer"
import {CardTitle} from "./CardTitle"
import {CardFields} from "./CardFields"
import {CardLabel} from "./CardLabel"
import {CardLabels} from "./CardLabels"
import {CardResults} from "./CardResults"
import {LabelField} from "./LabelField"
import {ResultField} from "./ResultField"
import {CardButton} from "./CardButton"

export const ProgressViewStyles = {
    Container:Container,
    CardsContainer: CardsContainer,
    CourseCard: CourseCard,
    CardTitle: CardTitle,
    CardFields: CardFields,
    CardLabel: CardLabel,
    CardLabels: CardLabels,
    CardResults: CardResults,
    LabelField: LabelField,
    ResultField: ResultField,
    CardButton: CardButton
}