import styled from "styled-components"
import fontValues from "../../../assets/values/fontValues/fontValues.json"

export const FilterButton = styled.button `

    width: 80%;
    height: 37px;
    background-color: ${props => props.$isActive ? ({theme}) => theme.colors.PRIMARY :
    ({theme}) => theme.colors.DARK_BACKGROUND};
    font-family: ${fontValues.TEXT_FONT_FAMILY};
    color: ${props => props.$isActive ? ({theme}) => theme.colors.LIGHT_TEXT : 
    ({theme}) => theme.colors.DARK_TEXT};
    border: none;
    border-radius: 2px;
    
`